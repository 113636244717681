import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const KimSohHyun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "권오성 ♥ 김소현 결혼합니다.";
    const openDesc = "6월 14일 오후 2시 30분 아펠가모 공덕";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/183/thumb.jpg";

    const placeTelNum = "02-2197-0230";

    const lng = 126.951422502066;
    const lat = 37.542695620343 ;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };
    const forBus = " ·  간선 : 160, 260, 600\n ·  지선 : 7013A, 7013B, 7611\n ·  마을 : 마포01, 마포02, 마포10\n ·  일반 : 1002\n ·  공항 : 6015, 6021";
    const forCar = " ·  효성해링턴 스퀘어 B동 및 인근 건물 '아펠가모' 판넬 \n    표시 주차장 (2시간 무료)\n ·  주차공간이 협소하오니 가급적 대중교통을 이용해 \n    주시면 감사하겠습니다.";
    const forSubway = " ·  공덕역 (경의중앙선, 공항철도, 5호선, 6호선) 하차\n ·  공덕역 10번 출구 방향으로 나와 유턴 후 전방 우측\n ·  공덕역 7번 출구 방향으로 나와 우측 골목으로 직진";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EFD140
    // #EB9217
    // #ff8faf
    // #E36402
    // #88abd1
    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
  //  #E2396A
  // #F77BAD
      // #ffb0d2 쿨 연핑크
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다 ♥";

    const msg1 = "뜨거운 여름 햇살 속에서 태어난 두 사람이 각자의 여름을 지나, \n2025년 6월. \n두 사람만의 새로운 여름을 함께 맞이하려 합니다.";
    const msg2 = "서로의 마음을 담아, \n이제는 함께 이 뜨거운 여름을, \n그리고 삶의 모든 순간을 함께 보내기로 했습니다."
    const msg3 = "사랑하는 어른들과 친지들의 따뜻한 격려와 축복 속에 \n힘찬 첫 발을 내딛고자 하오니 \n함께 하시어 자리를 빛내 주시기 바랍니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "권오성",
            bank: "국민은행",
            accountNumber : "594201-04-038660",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FQNxoT8Q5"
        }
        ,{
            role: "신랑 부",
            name: "권영호",
            bank: "국민은행",
            accountNumber : "619210109599",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "주혜린",
            bank: "국민은행",
            accountNumber : "619210427031",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김소현",
            bank: "국민은행",
            accountNumber : "601501-01-257311",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FYlOz4E1f"
        }
        ,{
            role: "신부 부",
            name: "김재진",
            bank: "신한은행",
            accountNumber : "110407983960",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "나은숙",
            bank: "하나은행",
            accountNumber : "44191025134807",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "윤병익",
            call: " 010-7199-3936"
        }
        ,{
            role: "신랑 부",
            name: "윤명구",
            call: "010-3218-3936"
        }
        ,{
            role: "신랑 모",
            name: "박용신",
            call: "010-7235-3936"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이나현",
            call: "010-7597-0815"
        }
        ,{
            role: "신부 부",
            name: "이규호",
            call: "010-4254-4815"
        },
        {
            role: "신부 모",
            name: "문정선",
            call: "010-3333-4708"
        }
    ]

    const interviewContent = [
        {
            question : "언제 제일 예뻐?",
            groomAnswer: "자고있을 때",
            brideAnswer: "ㅋㅋㅋㅋㅋ"
        },
        {
            question : "언제 제일 멋져?",
            groomAnswer: "매일 멋있지",
            brideAnswer: "쉬잇! 조용히"
        },
        {
            question : "결혼을 결심한 계기가 뭐야?",
            groomAnswer: "계속 생각나",
            brideAnswer: "나도,그래서"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "좋아! 가보자",
            brideAnswer: "오케 레츠고"
        },
        {
            question : "10년 후 우리는?",
            groomAnswer: "아들,딸 함께",
            brideAnswer: "누구맘대로"
        }
    ]


    const informationList =[
        {
            title : "화환 안내",
            content : "예식장 사정상 화환반입이 불가하오니 양해하여 주시기 바랍니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} />
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimSohHyun;