import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring3 from '../../component/Spring/Info_Spring3';
import ParentInfo2 from '../../component/Info/ParentInfo2';


const ParkJooHye = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김재국 ❤️ 박주혜 결혼합니다. ";
    const openDesc = "5월 24일 오후 5시\n더링크호텔 2층 링크홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/174/thumb.jpg";

    const lng = 126.883956501896;
    const lat = 37.5054782085179;

    const placeTelNum = "02-852-5000";

    const forCar = "네비게이션에 '더링크호텔 서울' 또는 \n'서울 구로구 경인로 610' 입력"
    const forSubway = "1, 2호선 [신도림역] 1번 출구, 도보 12분 \n(1번 출구 건너편 홈플러스 앞 셔틀버스 상시운행) \n\n 1호선 [구로역] 3번 출구, 도보 7분"
    const forParking = "호텔 지하 주차장 및 외부 주차장 이용 * 90분 무료 \n(초과시 15분당 1,000원) "
    const forBus = "신도림동.구로역 정류장 하차"

    const parents = [
        {groom : "김민섭 · 승경애의 아들"},
        {bride : "박도순 · 신정재의 딸"}
    ]

    const parentInfo = {
        groom: {
            dad: { name: "김민섭", isDeceased: false },
            mom: { name: "승경애", isDeceased: false },
            role: "아들",
        },
        bride: {
            dad: { name: "박도순", isDeceased: false },
            mom: { name: "신정재", isDeceased: false },
            role: "딸",
        }
    };
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    // #847A9A
    const conceptStyle = {
        // summer 빈티지 연보라
        concept: "SUMMER",
        titleColor: "#817798",
        pointColor: "#817798",
        btnBgColor: "#817798",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "우리 결혼합니다.";

    const msg1 = "모든 것이 새로워지는 봄날, \n두 사람이 사랑으로 만나 \n부부라는 이름으로 마주보기 위해 \n소중한 분들을 모시고 \n사랑의 결실을 이루려 합니다. "
    const msg2 = "한 곳을 바라보며 \n첫 발을 내딛는 자리 \n그 시작에 함께해주시면 \n더 없는 기쁨으로 간직하겠습니다."


    const groomAccountList = [
        {
            role: "신랑",
            name: "김재국",
            bank: "신한은행",
            accountNumber : "110-578-332169",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FYm3sZn96"
        }
        ,{
            role: "신랑 부",
            name: "김민섭",
            bank: "우리은행",
            accountNumber : "054-044-060-02001",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "승경애",
            bank: "우리은행",
            accountNumber : "1002-447-575670",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박주혜",
            bank: "하나은행",
            accountNumber : "010-718038-65907",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FC9nIIzO9"
        }
        ,{
            role: "신부 부",
            name: "박도순",
            bank: "농협은행",
            accountNumber : "041-12-201272",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "신정재",
            bank: "우리은행",
            accountNumber : "1002-663-204172",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "여름어느날",
            brideAnswer: "조금긴장함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "잘할수있어",
            brideAnswer: "기대설렘반"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보세",
            brideAnswer: "항상응원해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "우리는청춘",
            brideAnswer: "오히려좋아"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "너만사랑해",
            brideAnswer: "현진이최고"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "하현진",
            call: "010 5196 3788"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "이명원",
        //     call: "010-3584-6149"
        // }
        ,{
            role: "신랑 모",
            name: "심귀선",
            call: "010 3838 4078"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "임수정",
            call: "010 2455 9201"
        }
        ,{
            role: "신부 부",
            name: "임현택",
            call: "010 7393 9630"
        }
        ,{
            role: "신부 모",
            name: "이인옥",
            call: "010 6364 5611"
        }
    ]

    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
    // #CE5751
    // #EF8470
    // #5b0100
    // #761B40
    // #423866
    // #d98db9
    // #FF8A83
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#817798"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} color={"#817798"} letteringFontColor={"#817798"} dateColor={"#817798"} bottom={"27%"} bottom2={"17.5%"} dateBottom={"1%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring3 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter fontSize={"14px"} titleFontSize={"19px"} title={title} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo2 conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <Dday_Spring isAttendFromClose={true} brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontSize={"15px"} fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList fontSize={"13px"} titleFontSize={"15px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring fontSize={"13px"} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc fontSize={"13px"} titleFontSize={"15px"} isPoint={true} forCar={forCar} forSubway={forSubway} forParking={forParking} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ParkJooHye;