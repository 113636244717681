import React from 'react';
import "../../css/letter.css"
import MotionWrapper from '../MotionWrapper';


const Letter = ({msg1, msg2, msg3, msg4, conceptStyle, title, titleFontSize, fontSize, isBold, isPointBold, contentTextAlign, pointColor}) => {
    const formatMessage = (message) => {
        return message.split(/(%.*?%|@.*?@)/g).map((part, index) => {
            if (part.startsWith("%") && part.endsWith("%")) {
                return (
                    <span key={index} style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px" }}>
                        {part.slice(1, -1).trim()}
                    </span>
                );
            } else if (part.startsWith("@") && part.endsWith("@")) {
                return (
                    <span key={index} style={{ fontWeight: "bold", fontSize: "15px", color: pointColor?pointColor: "#313131"}}>
                        {part.slice(1, -1).trim()}
                    </span>
                );
            }
            return part; 
        });
    };

    return (
        <div className='letter_container' style={{fontFamily : conceptStyle.fontFamilyKo}}>
            <MotionWrapper> 
                <div className='title' style={{color : conceptStyle.pointColor, fontSize: fontSize ? titleFontSize : "17px", fontWeight: isBold || isPointBold ? 600 : 300}}>
                    <p>{title}</p>
                </div>
            </MotionWrapper>
            <div className='body' style={{color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300, width: contentTextAlign?"300px":"98%", margin: "0 auto", textAlign: contentTextAlign?contentTextAlign:"center"}}>
                <MotionWrapper> 
                    <p className='body1' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {formatMessage(msg1)}
                    </p>
                </MotionWrapper>
                
                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {msg2 ? formatMessage(msg2) : ""}
                    </p>
                </MotionWrapper>

                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {msg3 ? formatMessage(msg3) : ""}
                    </p>
                </MotionWrapper>
                <MotionWrapper> 
                    <p className='body2' style={{fontFamily : conceptStyle.fontFamilyKo, fontSize: fontSize ? fontSize : "13px"}}>
                        {msg4 ? formatMessage(msg4) : ""}
                    </p>
                </MotionWrapper>
            </div>
        </div>
    );
};

export default Letter;