import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import Info_Spring2 from '../../component/Spring/Info_Spring2';


const LimSuJeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "임수정 ♥️ 하현진 결혼식에 초대합니다.";
    const openDesc = "5월 10일 낮 12시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/175/thumb.jpg";

    const lng = 129.126513499207;
    const lat = 35.1740782852492;

    const placeTelNum = "051-711-7755";

    const forCar = "[네비게이션] 더파티 센텀점 입력"
    const forSubway = "2호선 센텀시티 6번 출구 800m 직진 좌측대로변 위치"
    const forShuttleBus = "2호선 센텀시티역 4번 출구 마을버스 정류장 탑승 \n(약 15-20분 간격)"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 블루
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "수정 🧡 현진 결혼식에 \n소중한 사람들을 초대합니다.";

    const msg1 = "카페에서 일하며 에스프레소처럼 강렬하고 \n라떼처럼 부드럽게 시작된 우리의 사랑."
    const msg2 = "이제 카라멜 마끼아또처럼 달콤하게, \n새로운 시작을 맞이하려 합니다."
    const msg3 = "저희 두 사람이 부부 되는 소중한 날, \n여러분이 함께 해주시면 감사하겠습니다 🧡"

    const parents = [
        {groom : "하재욱 · 심귀선의 아들"},
        {bride : "임현택 · 이인옥의 딸"}
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "하현진",
            bank: "신한은행",
            accountNumber : "110-475-946949",
            isKakao : false
        }
        // ,{
        //     role: "신랑 부",
        //     name: "김재영",
        //     bank: "농협은행",
        //     accountNumber : "615040-52-059194",
        //     isKakao : false
        // }
        ,{
            role: "신랑 모",
            name: "심귀선",
            bank: "부산은행",
            accountNumber : "206-12-013368-9",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "임수정",
            bank: "부산은행",
            accountNumber : "302-9064-5238-01",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "임현택",
            bank: "우리은행",
            accountNumber : "852-077873-02-101",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "이인옥",
            bank: "부산은행",
            accountNumber : "112-2073-4509-00",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "여름어느날",
            brideAnswer: "조금긴장함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "잘할수있어",
            brideAnswer: "기대설렘반"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "잘살아보세",
            brideAnswer: "항상응원해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "우리는청춘",
            brideAnswer: "오히려좋아"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "너만사랑해",
            brideAnswer: "현진이최고"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "하현진",
            call: "010 5196 3788"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "이명원",
        //     call: "010-3584-6149"
        // }
        ,{
            role: "신랑 모",
            name: "심귀선",
            call: "010 3838 4078"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "임수정",
            call: "010 2455 9201"
        }
        ,{
            role: "신부 부",
            name: "임현택",
            call: "010 7393 9630"
        }
        ,{
            role: "신부 모",
            name: "이인옥",
            call: "010 6364 5611"
        }
    ]

    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
    // #CE5751
    // #EF8470
    // #5b0100
    // #761B40
    // #423866
    // #d98db9
    // #FF8A83
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} color={"#505D89"} letteringFontColor={"#505D89"} dateColor={"#505D89"} bottom={"85%"} bottom2={"75.5%"} dateBottom={"1%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring2 parents={parents}  weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc isPoint={true} forCar={forCar} forSubway={forSubway} forShuttleBus={forShuttleBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default LimSuJeong;