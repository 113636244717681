import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import musicTitle from "../../asset/music/버스커 버스커-01-봄바람.mp3";


const KimSeungJu = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "병화♥️승주 결혼합니다";
    const openDesc = "20250420 오후 12시 30분 동방호텔 그랜드볼룸홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/161/thumb.jpg";

    const lng = 128.092536845816;
    const lat = 35.1903618185496;

    const placeTelNum = "0557691700";
    
    const forParking = " • 호텔 뒤 주차타워 이용\n• 만차 시 주차타워 뒤쪽 지상 주차장 이용";
    const forPublic = " •시외버스 터미널 : 도보 5분이내\n•고속버스 터미널 : 시내버스 15분이내 \n    (340, 341, 342, 353) , 택시 5분이내\n•진주역 : 시내버스 27분이내 \n    (122, 124, 151) , 택시 20분이내 "
    
    const parents = [
        {groom : "준영 · 은실의 장남"},
        {bride : "해용 · 명옥의 장녀"}
    ] 

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf

    // const conceptStyle = {
    //     // spring 블루A
    //     concept: "SPRING",
    //     titleColor: "#9FB4E7",
    //     pointColor: "#9FB4E7",
    //     btnBgColor: "#bfcfef",
    //     fontColor: "#313131",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#f3f7ff",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다";

    const msg1 = " \"연애 챕터 끝, 이제 결혼 시즌 오픈! \" ";
    const msg2 = "프롤로그는 끝났다.\n티저도, 예고편도 다 끝났다."
    const msg3 = "이제 본편 시작합니다! \n우리 러브스토리의 시즌 2 첫 회, 함께해 주세요";

    const groomAccountList = [
        {
            role: "신랑",
            name: "이병화",
            bank: "농협은행",
            accountNumber : "352-1143-6823-43",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "이준영",
            bank: "농협은행",
            accountNumber : "1123-12-061391",
            isKakao : false
        }
        // ,{
        //     role: "신랑 모",
        //     name: "신선호",
        //     bank: "농협은행",
        //     accountNumber : "302-1615-1609-81",
        //     isKakao : false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김승주",
            bank: "농협은행",
            accountNumber : "351-0164-3293-63",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "김종영",
        //     bank: "농협은행",
        //     accountNumber : "302-1033-9388-11",
        //     isKakao : false
        // }
        ,{
            role: "신부 모",
            name: "박명옥",
            bank: "농협은행",
            accountNumber : "352-2265-4940-23",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이승욱",
            call: "010-2786-9838"
        }
        ,{
            role: "신랑 부",
            name: "이복수",
            call: "010-5335-6083"
        }
        ,{
            role: "신랑 모",
            name: "허혜란",
            call: "010-9567-9847"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "한서희",
            call: "010-5802-2401"
        }
        ,{
            role: "신부 부",
            name: "한송만",
            call: "010-9145-9317"
        },
        {
            role: "신부 모",
            name: "윤미영",
            call: "010-5530-9317"
        }
    ]

    const interviewContent = [
        {
            question : "서로의 첫인상은?",
            groomAnswer: "너무 이쁘네",
            brideAnswer: "오 괜찮다!"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 긴장됨",
            brideAnswer: "실감 안나유"
        },
        {
            question : "우리 부부만의 룰 하나 정한다면?",
            groomAnswer: "먼저 치우기",
            brideAnswer: "알잘딱깔센!"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "열심히 살자",
            brideAnswer: "좋은 아내 될게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해주세요",
            brideAnswer: "많이많이❤️"
        }
    ]

    const informationList =[
        {
            title : "♥신랑신부가 준비한 포토부스 이벤트♥",
            content : "10시30분-12시 운영합니다! \n많이 참여해주세요\n\n예쁜사진과 축하메시지를 남겨주시고,\n인생사진도 마음껏 가져가세요 :D"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicTitle}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forParking={forParking} forPublic={forPublic} conceptStyle={conceptStyle}/>
                    {/* <Information titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimSeungJu;