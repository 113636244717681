import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import Main_Winter from '../../component/Winter/Main_Winter';
import Save_Date_Fall from '../../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../../component/Fall/Info_Fall';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import CallInfo from '../../component/CallInfo';

const LeeMinJe = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "송기영♥이민제 결혼합니다";
    const openDesc = "2025년 6월 22일 일요일 오후 12시30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/170/thumb.jpg";

    const placeTelNum = "02-526-0300";

    const lng = 127.034949554107;
    const lat = 37.4827666361943;

    const forBus = "양재역, 서초문화예술회관에서 하차\n\n ·  지선 : 0411, 4432, 4435\n ·  간선 : 140, 341, 400, 405, 421, 440, 441, 452, 470, 542, \n   741\n ·  광역 : 9404, 9408, 9409, 9500, 9501, 9711, 9802, \n   M4403, M4434, M4448, M4449, M5422, M5438, M5443,\n   M6410, M6450\n ·  마을 : 서초09, 서초18, 서초18-1, 서초20\n ·  일반 : 6,11-3,917";
    const forSubway = "3호선 양재역/신분당선 양재역-9번 출구 바로 연결";
    const forParking = "엘블레스 옆 양재역 주차장 이용 \n(주차장 주소: 서초구 강남대로 221)\n* 양재역 주차장에 주차 후 옆 건물 스포타임으로 이동";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }


    const parents = [
        {groom : "송득방 · 정화남(정수진)의 \n아들"},
        {bride : "이강일 · 김춘옥의 \n딸"}
    ]

    const title = "소중한 분들을 초대합니다"

    const msg1 = "모든 순간을 서로 의지하며\n함께 걸어갈 두 사람이 영원한 사랑을 약속하려 합니다."
    const msg2 = "저희의 행복한 첫 걸음에 \n귀한 발걸음으로 함께해 주시면 더 큰 기쁨으로 간직하겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "송기영",
            bank: "우리은행",
            accountNumber : "1002-945-926711",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "송득방",
            bank: "국민은행",
            accountNumber : "771302-95-115237",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "정화남",
            bank: "농협은행",
            accountNumber : "235094-56-017328",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이민제",
            bank: "신한은행",
            accountNumber : "110-277-409012",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "이강일",
            bank: "신한은행",
            accountNumber : "110-030-823788",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김춘옥",
            bank: "우리은행",
            accountNumber : "1002-037-546390",
            isKakao : false
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김유현",
            call: "010-2340-2723"
        }
        ,
        {
            role: "신랑 부",
            name: "김성하",
            call: "010-9319-7081"
        },
        {
            role: "신랑 모",
            name: "주미옥",
            call: "010-3874-7082"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이수빈",
            call: "010-4598-9742"
        }
        ,{
            role: "신부 부",
            name: "이상기",
            call: "010-6889-0396"
        }
        ,{
            role: "신부 모",
            name: "최은경",
            call: "010-9930-9742"
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너 찜뽕",
            brideAnswer: "넌 내꺼다."
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렌다",
            brideAnswer: "울지만 말자"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "나도 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생잘할게",
            brideAnswer: "평생잘해라"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "넌평생내꺼",
            brideAnswer: "ㄱ..그래나도"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.groomNameEn)} groomNameEn={getFirstNameEn(weddingData.brideNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall parents={parents} conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={title} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList  fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring  weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    </MotionWrapper> */}
                    <MotionWrapper initialY={50}>
                        <Location_Spring  weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                        <Location_Desc forBus={forBus} forSubway={forSubway} forParking={forParking} conceptStyle={conceptStyle}/>
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeMinJe;