import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import musicFile from '../../asset/music/DayByPiano.mp3'

const LeeJuHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김인규 ❤ 이주현 결혼합니다. ";
    const openDesc = "2025년 4월 13일 오후 12시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/138/thumb.jpg";

    const lng = 128.662109053984;
    const lat = 35.8824102975974;

    const placeTelNum = "053-282-1000";

    const forSubway = "1호선 해안역 3번 출구 (도보 5분)";
    const forCar = "결혼식장 주차장이 다소 혼잡할 수 있으니,\n'홈플러스 동촌점 (퀸벨호텔 제2주차장)'에 주차하시고 \n셔틀버스를 이용하시거나 도보로 오시는 것을 \n추천드립니다.\n\n* 홈플러스 동촌점(퀸벨호텔 제2주차장) ↔ 퀸벨호텔 \n5분마다 셔틀버스 운행 / 도보 10분 거리 *";
    const forBus = "퀸벨호텔 앞 / 퀸벨호텔 건너 하차 (도보 2분)\n618, 719, 805, 818, 836, 980, 동구1, 동구1-1, 동구3"
    const forTrain2 = "동대구역 하차 후 지하철 이용\n(1호선 동대구역 → 해안역 / 4개 정거장)"

    const parents = [
        {groom : "김두희 · 이연숙의 장남"},
        {bride : "❁이석원 · 박영란의 차녀"}
    ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #E08514
    // #EFD140
    // #EB9217
    // #ff8faf
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "우리 결혼합니다.";

    const msg1 = "찰나가 영원이 되던 순간들, \n세상 어떤 힘든 일도 서로의 눈을 보면 잊혀지던 순간들,\n그 순간들을 놓치지 않고 영원을 약속하고자 합니다.";
    const msg2 = "함께할 때 가장 빛나는 두 사람,\n서로를 오롯이 비추며 평생을 함께 하겠습니다."
    const msg3 = "영원히 기억될 순간에 함께해 주세요"


    const groomAccountList = [
        {
            role: "신랑",
            name: "김인규",
            bank: "농협은행",
            accountNumber : "302-0824-3001-41",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FDMJNmYrP"
        }
        ,{
            role: "신랑 부",
            name: "김두희",
            bank: "IM뱅크",
            accountNumber : "220-13-165704",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "이연숙",
            bank: "IM뱅크",
            accountNumber : "138-08033953-1",
            isKakao :false
        }
    ]

    
    const brideAccountList = [
        {
            role: "신부",
            name: "이주현",
            bank: "카카오뱅크",
            accountNumber : "3333-20-4048322",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8pxcZcZ"
        }
        // ,{
        //     role: "신부 부",
        //     name: "서광석",
        //     bank: "농협은행",
        //     accountNumber : "352-0579-4527-03",
        //     isKakao :false
        // }
        ,{
            role: "신부 모",
            name: "박영란",
            bank: "IM뱅크",
            accountNumber : "015-08-072807",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FLHiu0lKV"
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "강우민",
            call: "010-7560-4109"
        }
        ,{
            role: "신랑 부",
            name: "명남식",
            call: "010-4028-0087"
        }
        ,{
            role: "신랑 모",
            name: "김영숙",
            call: "010-9118-4109"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "박지선",
            call: "010-9024-6645"
        }
        ,{
            role: "신부 부",
            name: "000",
            call: "010-5065-6858"
        },
        {
            role: "신부 모",
            name: "000",
            call: "010-3393-0658"
        }
    ]

    const interviewContent = [
        {
            question : "서로의 첫인상은 어땠나요?",
            groomAnswer: "예쁜 빵모자",
            brideAnswer: "멋진 안경남"
        },
        {
            question : "서로의 매력포인트는 무엇인가요?",
            groomAnswer: "상큼깨발랄",
            brideAnswer: "반전매력왕"
        },
        {
            question : "서로를 한마디로 소개해주세요.",
            groomAnswer: "만능끼쟁이",
            brideAnswer: "웃긴 집돌이"
        },
        {
            question : "결혼식은 앞둔 소감이 어떤가요?",
            groomAnswer: "두근거려요",
            brideAnswer: "너무 떨려요"
        },
        {
            question : "하객들에게 전하고 싶은 말은 무엇인가요?",
            groomAnswer: "행복할게요",
            brideAnswer: "잘살게요❤"
        }
    ]

    const informationList =[
        {
            title : "♥신랑신부가 준비한 포토부스 이벤트♥",
            content : "10시30분-12시 운영합니다! \n많이 참여해주세요\n\n예쁜사진과 축하메시지를 남겨주시고,\n인생사진도 마음껏 가져가세요 :D"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forTrain2={forTrain2} forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeJuHyeon;