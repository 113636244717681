import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import Main_Winter from '../../component/Winter/Main_Winter';
import Save_Date_Fall from '../../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../../component/Fall/Info_Fall';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';

const LeeYuJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "우연종🖤이유진 결혼합니다 ";
    const openDesc = "Jw컨벤션 3층 그랜드볼룸 4월 27일 3시 10분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/149/thumb.jpeg";

    const placeTelNum = "052-289-9851";

    const lng = 129.356249836941;
    const lat = 35.5738821210717;

    const forCar = "울산IC -북부순환도로-진장사거리우회전- jw컨벤션센터"
    const forBus = "· 울산시내버스(울산우편집중국 하차) \nㅤ112/225/246/402/442/453/701\n· 부산버스 (차량등록사무소입구하차) 1147"
    const forTrain2 = "KTX(울산(통도사역) \n리무진 5003 (병영사거리) 5005 (울산공항) 하차 후 \n택시 3분 소요";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }


    const parents = [
        {groom : "진용성 · 서윤희의 장남"},
        {bride : "박광익 · 장남옥의 차녀"}
    ]

    const title = "welcom to Yeon jong&yu jin"

    const msg1 = "Happy"
    const msg2 = "wedding day"
    const msg3 = "2025.04.27 sun 15:10"

    const groomAccountList = [
        {
            role: "신랑",
            name: "우연종",
            bank: "하나은행",
            accountNumber : "539-910320- 93707",
            isKakao : false
        }
        // ,{
        //     role: "신랑 부",
        //     name: "진용성",
        //     bank: "우리은행",
        //     accountNumber : "1002-861-747904",
        //     isKakao : false
        // }
        ,{
            role: "신랑 모",
            name: "이순옥",
            bank: "농협은행",
            accountNumber : "81513256149680",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이유진",
            bank: "카카오뱅크",
            accountNumber : "3333-30-9089980",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "박광익",
        //     bank: "농협은행",
        //     accountNumber : "574-02-008976",
        //     isKakao : false
        // }
        // ,{
        //     role: "신부 모",
        //     name: "장남옥",
        //     bank: "농협은행",
        //     accountNumber : "211057-51-056271",
        //     isKakao : false
        // }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "빨리만나고싶었어",
            brideAnswer: "넌 참 특이했지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무행복해",
            brideAnswer: "넘신나고설레"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "다투면 바로풀자",
            brideAnswer: "서로 배려하자"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "다른여잔 없다",
            brideAnswer: "평생 사랑할게"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "행복하게잘살자",
            brideAnswer: "사랑해 오빠!"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.groomNameEn)} groomNameEn={getFirstNameEn(weddingData.brideNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={title} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList2 conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring  weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Location_Spring  weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                        <Location_Desc forCar={forCar} forTrain2={forTrain2} forBus={forBus} conceptStyle={conceptStyle}/>
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeYuJin;