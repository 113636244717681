import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import Save_Date_Fall from '../../component/Fall/Save_Date_Fall';
import Info_Fall from '../../component/Fall/Info_Fall';

const ParkSuHye = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "보람 ♥ 수혜 결혼합니다.";
    const openDesc = "2025년 06월 29일 (일) 오후 5시 10분\n라마다서울신도림호텔 14층 하늘정원홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/142/thumb.jpeg";


    const lng = 126.885321;
    const lat = 37.5064312;

    const placeTelNum = "02-2162-2000";


    const forCar = "주차장 입구 : 서울특별시 구로구 경인로 624"
    const forSubway = "지하철 1호선, 2호선 신도림역 1번 출구 \n(신도림역 광장 도보 5분)"
    const forBus = "· 신도림역(구로역) 11-1, 11-2, 503, 10, 301, 320, \n600, 660, 662, 160, 5200, 6713, 6515, 6516, 6637, \n530, 83, 88\n · 신도림중학교 5714, 5615, 6512";
    const forShuttleBus = "신도림역 1번 출구 횡단보도 앞 노랑버스 (상시운행)"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 블루
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "우리 결혼 합니다.";

    const msg1 = "싱그러운 여름 향기가 가득한 날,";
    const msg2 = "8년동안 사랑한 두 사람이 \n부부라는 이름으로 마주보기 위해\n소중한 분들을 모시고\n사랑의 약속을 하려고 합니다."
    const msg3 = "한곳을 바라보며 \n첫 발을 내딛는 자리 \n그 시작에 함께해 주시면 \n더없는 기쁨으로 간직하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "차보람",
            bank: "신한은행",
            accountNumber : "110-390-509300",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FYz5GzjiN"
        }
        ,{
            role: "신랑 부",
            name: "차석용",
            bank: "부산은행",
            accountNumber : "060-12-089087-0",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FaucZJ3km"
        }
        ,{
            role: "신랑 모",
            name: "성미자",
            bank: "부산은행",
            accountNumber : "060-12-089087-0",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FaucZJ3km"
        }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "박수혜",
            bank: "기업은행",
            accountNumber : "545-021051-01-011",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FWIK3cqLA"
        }
        ,{
            role: "신부 부",
            name: "박정호",
            bank: "기업은행",
            accountNumber : "545-021051-01-011",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FWIK3cqLA"
        }
        // ,{
        //     role: "신부 모",
        //     name: "최경숙",
        //     bank: "농협은행",
        //     accountNumber : "463144-56-043083",
        //     isKakao : false
        // }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "차보람",
            call: "010-4520-4616"
        }
        ,{
            role: "신랑 부",
            name: "차석용",
            call: "010-3768-4616"
        }
        ,{
            role: "신랑 모",
            name: "성미자",
            call: "010-5260-4616"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "박수혜",
            call: "010-9029-4994"
        }
        ,{
            role: "신부 부",
            name: "박정호",
            call: "010-8370-9996"
        }
        // ,{
        //     role: "신부 모",
        //     name: "최경숙",
        //     call: "010-8818-5050"
        // }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "어떻게 잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "두근 거리네",
            brideAnswer: "너무 떨리네"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "나도 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "잘해줄게",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "잘 살게요",
            brideAnswer: "많관부~♥"
        },
    ]


    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} color={"#000000"} letteringFontColor={"#505D89"} dateColor={"#ffffff"} bottom={"82%"} bottom2={"72.5%"} dateBottom={"1%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forSubway={forSubway} forShuttleBus={forShuttleBus} forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ParkSuHye;