import React, { useState, useEffect } from 'react';
import Main_Spring from '../component/Spring/Main_Spring';
import Info_Spring from '../component/Spring/Info_Spring';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Save_Date from '../component/Save_Date';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import babyStepMusicFile from "../asset/music/babyStep.mp3";
import GalleryList from '../component/Gallery/GalleryList';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Info_Spring2 from '../component/Spring/Info_Spring2';
import Information from '../component/Information';

const LeeYeRim = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김지현🖤이예림 결혼합니다.";
    const openDesc = "3월 1일 오후 12시 30분 \n호텔인터불고 대구 파크빌리지";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/39/thumb.jpg";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen:''
    });

    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "김지현",
            bank: "국민은행",
            accountNumber : "823302-01-190099",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8ERwRfN"
        }
        ,{
            role: "신랑 부",
            name: "김용해",
            bank: "농협은행",
            accountNumber : "351-0605-1999-03",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "박미화",
            bank: "농협은행",
            accountNumber : "150012-52-188566",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이예림",
            bank: "신한은행",
            accountNumber : "110-554-248765",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FaXSFLC6L"
        },
        {
            role: "신부 부",
            name: "이강연",
            bank: "국민은행",
            accountNumber : "450902-95-122112",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김지나",
            bank: "국민은행",
            accountNumber : "426602-01-472699",
            isKakao : false
        }
    ]

    const msg1 = "2025년 3월 1일";
    const msg2 = "서로가 마주 보며 다져온 사랑을 \n이제 함께 한 곳을 바라보며\n걸어갈 수 있는 큰 사랑으로 키우고자 합니다.";
    const msg3 = "저희 두 사람이 사랑의 이름으로 지켜나갈 수 있게\n앞날을 축복해주시면 감사하겠습니다."

    const lng = 128.659657115395;
    const lat = 35.8743956534157;

    const placeTelNum = "053-602-7114";

    const forRentBus = "✅ 오전 7시 30분 “한양대구리병원” 출발\n(경기 72사 7404)\n(기사님 010-3501-6080)\n-> 오후 2시 30분 호텔인터불고 대구 출발\n\n✅ 오전 7시 30분 “강남역 11번출구” 출발\n(경기 78자 7320)\n(기사님 010-8891-8871)\n-> 오후 3시 호텔인터불고 대구 출발";
    const forSubway = "1호선 아양교역 하차 후 814 버스 환승\n2호선 만촌역 하차 후 849 버스 환승"
    const forCar = "내비게이션 '인터불고' 검색 시, 주소 '수성구 만촌동' 확인\n동대구 IC에서 시청 방향으로 4km (5분 소요)";
    const forTrain = "동대구역\n•택시 : 약 10분 소요\n•버스 : 814, 708(망우공원 하차), 651(호텔인터불고 \n 하차)\n\n대구역\n•택시 : 약 20분 소요\n•버스 : 651(호텔인터불고 하차)";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const parents = [
        {groom : "김용해 · 박미화의 아들"},
        {bride : "이강연 · 김지나의 딸"}
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "아직 생생해",
            brideAnswer: "어떻게 잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "실감이 안나",
            brideAnswer: "두근두근해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상 고마워",
            brideAnswer: "나도 고마워"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "일찍 다닐게",
            brideAnswer: "행복하자🖤"
        },
        {
            question : "마지막으로 하객분들께 전하고 싶은 이야기는?",
            groomAnswer: "우리 결혼식",
            brideAnswer: "축하해줘요"
        }
    ]

    const informationList =[
        {
            title : "대절 버스 안내",
            content : "✅ 오전 7시 30분 “한양대구리병원” 출발\n(경기 72사 7404)\n(기사님 010-3501-6080)\n-> 오후 2시 30분 호텔인터불고 대구 출발\n\n✅ 오전 7시 30분 “강남역 11번출구” 출발\n(경기 78자 7320)\n(기사님 010-8891-8871)\n-> 오후 3시 호텔인터불고 대구 출발"
        }
    ]

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);


    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    if (!weddingData || !conceptStyle) return <Loading color={weddingData.pointColor}/>;
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
            <>
            <Music concept={conceptStyle.concept} musicTitle={babyStepMusicFile}/>
            <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
            <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
            <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
            <MotionWrapper initialY={50}>
                <GalleryList color={conceptStyle.pointColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} hotoHeight={"235px"} gap={"4px"}/>
            </MotionWrapper>
            <MotionWrapper initialY={70}>
                <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
            </MotionWrapper>
            <MotionWrapper initialY={70}>
                    <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
            </MotionWrapper>
            <MotionWrapper initialY={70}>
                <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} height={"200px"} divHeight={"330px"} />
            </MotionWrapper>
            <MotionWrapper initialY={50}>
                <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
            </MotionWrapper>
            <MotionWrapper initialY={50}>
                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
            </MotionWrapper>
                <Location_Desc forCar={forCar} forRentBus={forRentBus} forTrain={forTrain} forSubway={forSubway} conceptStyle={conceptStyle}/>
                <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                <Footer />
            </>
            )}
        </div>
    );
};

export default LeeYeRim;
