import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import AccountList2 from '../../component/Account/AccountList2';

const YeoMinJu = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "최희재 ♥ 여민주 결혼합니다.";
    const openDesc = "6월 29일 일요일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/199/thumb.jpg";

    const lng = 126.645426431439;
    const lat = 37.3902250644638;

    const placeTelNum = "032-835-1000";

    const forShuttleBus = "* 평택 소방서 (평택 시청 맞은 편) 옆 오전 10시 출발\n* 인천대 입구역 4번 출구 오후 12시 ~ 오후 1시 \n/ 오후 2시 30분 ~ 오후 3시 30분 왕복 순환운행"
    const forSubway = "인천 1호선 인천대입구역 4번 출구 (도보 10분)";
    const forBus = "1301, M6724 > 서울역 – 컨벤시아 (하차)\n3002 > 고속철도 광명역(6번 출구) – 센트럴 공원 (하차)\nM6405 > 강남역 (6, 7번 출구) – 센트럴 파크 (하차)"
    const forCar = "네비게이션 '쉐라톤 그랜드 인천 호텔' 입력 "


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "초 대 합 니 다";

    const msg1 = "행복한 결혼은 \n약혼한 순간부터 죽는 날까지\n지루하지 않는 기나긴 대화를\n나누는 것과 같다.";
    const msg2 = "- 앙드레 모루아"
    const msg3 = "\n저희 두 사람이 함께하는 새로운 시작에\n귀한 발걸음으로 축복해 주시면 감사하겠습니다."
    const msg4 = "신랑 최희재 · 신부 여민주\n\n"

    const groomAccountList = [
        {
            role: "신랑",
            name: "최희재",
            bank: "카카오뱅크",
            accountNumber : "3333-06-5057904",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FMdpTpB7t"
        }
        ,{
            role: "신랑 부",
            name: "최완식",
            bank: "농협은행",
            accountNumber : "352-0248-2070-43",
            isKakao : false
        }
        // ,{
        //     role: "신랑 모",
        //     name: "강점옥",
        //     bank: "국민은행",
        //     accountNumber : "713702-01-098186",
        //     isKakao : false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "여민주",
            bank: "신한은행",
            accountNumber : "110-551-721476",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FDJz9Q0WB"
        }
        ,{
            role: "신부 부",
            name: "여인권",
            bank: "농협은행",
            accountNumber : "387-02-095525",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "이형진",
            bank: "농협은행",
            accountNumber : "302-0486-7530-81",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이우진",
            call: "010-7376-0248"
        }
        ,{
            role: "신랑 부",
            name: "이봉채",
            call: "010-4561-0224"
        }
        ,{
            role: "신랑 모",
            name: "이화정",
            call: "010-8244-7209"
        }
    ]
    
    const brideCallInfoList = [
        {
            role: "신부",
            name: "배정민",
            call: "010-3657-2128"
        }
        ,{
            role: "신부 부",
            name: "배천기",
            call: "010-4758-5362"
        }
        ,{
            role: "신부 모",
            name: "김상미",
            call: "010-2032-2321"
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "그날못잊어",
            brideAnswer: "첫눈에반함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렘과기대",
            brideAnswer: "너무행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "믿고의지해",
            brideAnswer: "나만바라봐"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "최고의남편",
            brideAnswer: "내조의여왕"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "평생내꺼해",
            brideAnswer: "평생사랑해"
        },
    ]
    
    const informationList =[
        {
            title : "결혼식 식사 안내",
            content : "결혼식과 함께 코스 식사 요리가 진행됩니다. \n\n예식 분위기와 어울리는 요리들이 순차적으로 \n제공되오니 식과 함께 편안한 마음으로 \n함께해 주시기 바랍니다. \n\n* 모든 분들을 소중한 마음으로 모실 수 있도록 \n참석 의사 전달 부탁드립니다. "
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
        // #636C62
        // #484D3D
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <>
                    {/* <Music concept={conceptStyle.concept}/> */}
                    <Main_Summer weddingData={weddingData} groomNameEn={getFirstEnName(weddingData.brideNameEn)} brideNameEn={getFirstEnName(weddingData.groomNameEn)} color={"#505D89"} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} bottom={"30%"} bottom2={"20.5%"} dateBottom={"2%"} top={"3%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle} />
                    {/* <CallInfo marginTop={"20px"} conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList fontSize={"12.5px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <Information divPadding={"10px"} fontSize={"12.5px"} width={"320px"} height={"230px"} divHeight={"320px"} conceptStyle={conceptStyle} informationList={informationList} />
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forSubway={forSubway} forCar={forCar} forShuttleBus={forShuttleBus} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default YeoMinJu;