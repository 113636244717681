import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import musicFile from "../../asset/music/IThinkTheyCallThisLove.mp3";


const BaeYeonBi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "송병헌 ♡ 배연비 결혼합니다.";
    const openDesc = "5월 24일 오후 2시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/132/thumb.jpeg";

    const placeTelNum = "02-432-9000";

    const lng = 126.895318461208;
    const lat = 37.4798477003537;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forSubway = "- 2호선 > 구로디지털단지역 하차 > 4번 출구 300m 앞 \n원광대 셔틀버스 (10분 소요)\n- 1,7호선 > 가산디지털단지역 하차 > 5번 출구 앞 \n셔틀버스 (10분 소요)";
    const forBus = "디지털단지 오거리 또는 삼부르네상스 하차 후 도보 8분 \n388, 504, 643,651, 5528, 5615, 5619, 5626, 금천07 "
    const forCar = "서울시 구로구 디지털로 26길 38 지타워컨벤션 \n(Gate 1, Gate 2만 진입가능) \n2시간 무료주차 / 10분당 700원";
    const forRentBus = "- 상행 : 오전 11시 20분 출발, 청주 체육관 주차장\n- 하행 : 오후 4시 10분 출발, 지타워 정문 앞"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FE987B 코랄
    // 레몬 옐로우 #EFD140
    // #EB9217
    // 빈티지 옐로우 : #EAC63C
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다.";

    const msg1 = "살기 좋은 집처럼 포근한 남편이 되겠습니다.";
    const msg2 = "몸에 맞는 옷처럼 편안한 아내가 되겠습니다."
    const msg3 = "같은 하늘 아래 있음을 행복해하던\n저희 두 사람 이제 사랑으로\n함께 가는 길 축복의 박수로 격려해주십시오."

    const groomAccountList = [
        {
            role: "신랑",
            name: "송병헌",
            bank: "농협은행",
            accountNumber : "356-0759-7629-43",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "송윤식",
            bank: "농협은행",
            accountNumber : "173254-51-010564 ",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "이은희",
            bank: "농협은행",
            accountNumber : "352-0353-6659-13",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "배연비",
            bank: "신한은행",
            accountNumber : "110-288-613602",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "배수환 (예금주 :이은미)",
            bank: "국민은행",
            accountNumber : "165801-04-318838",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "이은미",
            bank: "신한은행",
            accountNumber : "110-223-727039",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "송병헌",
            call: "010-7648-8500"
        }
        ,{
            role: "신랑 부",
            name: "송윤식",
            call: "010-2744-3513"
        }
        ,{
            role: "신랑 모",
            name: "이은희",
            call: "010-3425-3518"
        }
    ]


    const brideCallInfoList = [
        {
            role: "신부",
            name: "배연비",
            call: "010-2909-5507"
        }
        ,{
            role: "신부 부",
            name: "배수환",
            call: "010-8891-5507"
        },
        {
            role: "신부 모",
            name: "이은미",
            call: "010-2694-5507"
        }
    ]

    const interviewContent = [
        {
            question : "서로를 처음 본 순간 든 생각은?",
            groomAnswer: "너무예뻤어",
            brideAnswer: "찾았다내짝"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설레입니다",
            brideAnswer: "두근두근해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑합니다",
            brideAnswer: "나도사랑해"
        },
        {
            question : "설레는 결혼생활 각오 한마디!",
            groomAnswer: "행복합시다",
            brideAnswer: "늘함께하자"
        },
        {
            question : "청첩장을 보고 계신 분들에게 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "감사합니다"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forRentBus={forRentBus} forBus={forBus} forCar={forCar} forSubway={forSubway} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default BaeYeonBi;