import React, { useState, useEffect } from 'react';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Gallery_Spring from '../component/Spring/Gallery_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Fall from '../component/Fall/Main_Fall';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import Info_Fall from '../component/Fall/Info_Fall';
import axios from 'axios';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import AccountList from '../component/Account/AccountList';
import GalleryList from '../component/Gallery/GalleryList';
import Information from '../component/Information';
import CallInfo from '../component/CallInfo';

const KimDaEun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams(); 

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #AC3058
    const conceptStyle = {
        // fall 
        concept: "FALL",
        titleColor: "#000000",
        pointColor: "#AC3058",
        btnBgColor: "#AC3058",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const groomAccountList = [
        {
            role: "신랑",
            name: "이주철",
            bank: "우리은행",
            accountNumber : "1002-246-544941",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej8shzOT6"
        }
        ,{
            role: "신랑 부",
            name: "이동수",
            bank: "신한은행",
            accountNumber : "110-012-286129",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "추미숙",
            bank: "신한은행",
            accountNumber : "318-02-184223",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김다은",
            bank: "국민은행",
            accountNumber : "506502-04-192528",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7xE2S4u"
        },
        {
            role: "신부 부",
            name: "김진권",
            bank: "농협은행",
            accountNumber : "503064-52-024818",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "장미숙",
            bank: "농협은행",
            accountNumber : "501020-56-087004",
            isKakao : false
        }
    ]

    const msg1 = "살랑이는 바람결에 \n사랑이 묻어나는 계절입니다.";
    const msg2 = "여기 곱고 예쁜 두 사람이 \n사랑을 맺어 \n인생의 반려자가 되고자 합니다.";
    const msg3 = "새 인생을 시작하는 이 자리에 오셔서\n축복해 주시면 감사하겠습니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const openTitle = "이주철🖤김다은 결혼합니다.";
    const openDesc = "3월22일 1시50분 월드컵컨벤션";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/107/thumb.jpeg";

    const lng = 126.897273034482;
    const lat = 37.5682884450841;

    const placeTelNum = "02-3152-7700";

    const forParking = "월드컵경기장 서문 진입 후 서측 1,2 주차장 이용\n- 축의대에서 주차 사전 등록 후 출차 (90분 무료)\n\n경기·행사가 있는 날에는 서측 주차장 만차 시 주차 요원 \n안내에 따라 난지천공원, 평화의공원 이용 (2시간 무료)"
    const forBus = "[월드컵경기장 서측. 문화비축기지 정류장] 도보 3분\n\n  간선 - 571, 710, 760\n지선 - 7019. 7715, 8777\n광역 - 9711";
    const forSubway = "6호선 월드컵경기장역 2번 출구 도보 3분\n월드컵경기장역 2번 출구에 경기장 서측(W) 방향 안내 배너";


    const parents = [
        {groom : "이동수 · 추미숙의 장남"},
        {bride : "김진권 · 장미숙의 차녀"}
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이주철",
            call: "010-8447-8658"
        }
        // ,
        // {
        //     role: "신랑 부",
        //     name: "강기수",
        //     call: "010 3747 9533"
        // },
        // {
        //     role: "신랑 모",
        //     name: "김미숙",
        //     call: "010 9067 1678"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김다은",
            call: "010-9269-1041"
        }
        // ,
        // {
        //     role: "신부 부",
        //     name: "김현석",
        //     call: "010 6386 7792"
        // },
        // {
        //     role: "신부 모",
        //     name: "박채희",
        //     call: "010 5899 3546"
        // }
    ]


    const interviewContent = [
        {
            question : "서로의 첫인상을 다섯글자로 말한다면?",
            groomAnswer: "꿀피부여신",
            brideAnswer: "보조개미남"
        },
        {
            question : "어떤 모습이 가장 사랑스러워?",
            groomAnswer: "방긋웃을때",
            brideAnswer: "애교부릴때"
        },
        {
            question : "지금 이 순간 서로에게 하고 싶은 말은?",
            groomAnswer: "적당히예뻐",
            brideAnswer: "항상고마워"
        },
        {
            question : "함께 이루고 싶은 꿈이나 목표가 있어?",
            groomAnswer: "아들둘딸둘",
            brideAnswer: "행복한가정"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "에헤라디야",
            brideAnswer: "잘살아보세"
        }
    ]

    const informationList =[
        {
            title : "전주-서울 버스 안내",
            content : "1. 월드컵경기장(내) 월드컵사우나(앞) : \n오전 9시 탑승\n2. 호성동 차량등록사업소 옆 만남의 광장 : \n오전 9시 10분 탑승 (주차 가능)\n\n혼주측 연락처 : 010-6654-8678\n< 버스를 이용하실 하객분들은 \n연락 부탁드립니다. >"
        }
        ,{
            title : "포토부스",
            content : "포토부스가 설치될 예정입니다\n\n귀한 발걸음 해주신 여러분의 환한 미소와 \n따뜻한 말씀 남겨주시면 소중히 간직하도록 하겠습니다."
        }
    ]

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; // 모든 폰트가 로드될 때까지 대기
            setFontsLoaded(true); // 폰트 로드 완료 시 상태 변경
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            console.log("response: ", response);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // Park Seo Young -> Park Seo young
    const toLowerCaseString = (str) => {
        const parts = str.split(" ");
        if (parts.length < 2) return str; // 성과 이름이 없으면 그대로 반환

        // 첫 단어(성)는 그대로 유지
        const firstPart = parts[0];

        // 두 번째 단어 이후로 처리
        const restParts = parts.slice(1).map((part, index) =>
            index === 0
                ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() // 첫 단어만 첫 글자 대문자
                : part.toLowerCase() // 나머지는 소문자로 변환
        );

        return `${firstPart} ${restParts.join(" ")}`;
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#AC3058"} loading={loading} />
            ) : (
                <> 
            <Music concept={conceptStyle.concept} initial={true}/>
            <Main_Fall photoWidth={"340px"} brideNameEn={toLowerCaseString(weddingData.brideNameEn)} groomNameEn={toLowerCaseString(weddingData.groomNameEn)} weddingData={weddingData} conceptStyle={conceptStyle}/>
            <Save_Date_Fall weddingData={weddingData} color={conceptStyle.pointColor}/>
            <Info_Fall parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} />
            <Letter titleFontSize={"18px"} fontSize={"15px"} title={"우리 결혼합니다"}  msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
            <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
            <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
            <MotionWrapper initialY={50}>
                <GalleryList titleFontSize={"18px"} fontSize={"15px"} color={conceptStyle.pointColor} fontFamilyEn={conceptStyle.fontFamilyEn} fontFamilyKo={conceptStyle.fontFamilyKo} fontSize={"15px"} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"3px"}/>
                    <MotionWrapper initialY={70}>
                    <AccountList titleFontSize={"16px"} fontSize={"13px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain}/>
                        <MotionWrapper initialY={70}>
                            <Message_Spring titleFontSize={"18px"} fontSize={"15px"} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate = {weddingData.weddingDate} conceptStyle={conceptStyle} btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.pointColor}/>
                            <MotionWrapper initialY={50}>
                                {/* <Interview_Spring titleFontSize={"15px"} fontSize={"14px"} interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/> */}
                                <Information conceptStyle={conceptStyle} informationList={informationList} height={"200px"} divHeight={"330px"} />
                                <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                <Location_Desc forSubway={forSubway} forBus={forBus} forParking={forParking} conceptStyle={conceptStyle}/>
                                <Sharing title={openTitle} description={openDesc} thumbImg={openThumb} conceptStyle={conceptStyle}/>
                                <Footer />
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </MotionWrapper> 
                </>    
            )}
        </div>
    );
};

export default KimDaEun;