import React, {useEffect} from 'react';
import '../../css/summer/main.css';
import '../../css/summer/main_summer.scss';

const Main_Summer = ({welcomeLineHeight, topContent, isBold, color, weddingData, brideNameEn, groomNameEn, letteringFontColor, dateColor, top, bottom, bottom2, dateBottom, datefontSize, welcomeFontSize, topFontSize, isNotWelcome}) => {
    useEffect(() => {
        const preventPinchZoom = (e) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventPinchZoom);
    }, []);

    return (
        <div className='summer_main_container'>
            <img className='main_summer_img' src={weddingData.mainImg} alt='Main Image' onContextMenu="return false"></img>
            {!isNotWelcome && (
                <div
                    className="welcome_summer"
                    style={{ color: color, top: top ? top : "10%" }}
                >
                    <p
                        style={{
                            fontSize: welcomeFontSize ? welcomeFontSize : "15px",
                            fontWeight: isBold ? 800 : 400,
                            color: color, 
                            lineHeight: welcomeLineHeight?"1.5em":"2em"
                        }}
                    >
                        {topContent?topContent:"Welcome to"}
                    </p>
                    <p
                        style={{
                            fontSize: topFontSize ? topFontSize : "21px",
                            fontWeight: isBold ? 800 : 400,
                            color: color,
                        }}
                    >
                        {brideNameEn} & {groomNameEn}
                    </p>
                </div>
            )}
            <div>
            <div className='main_text_summer' style={{'--svg-fill-color': letteringFontColor, bottom: bottom}}>
                <svg xmlns="http://www.w3.org/2000/svg">
                    {['H', 'a', 'p', 'p','y'].map((letter, index) => {
                        var xPosition = 0;
                        if(index === 0) {
                            xPosition = 0
                        } else if(index === 1){
                            xPosition = 50
                        } else {
                            xPosition += index * 37; 
                        }
                        return (
                            <text 
                                key={index} 
                                className={`svg_text_summber svg_text${index + 1}`} 
                                x={xPosition} 
                                y="110" 
                                fill={"#ffffff"}
                                style={{ 
                                    animationDelay: `${index * 0.2}s` 
                                    , fontSize: "80px"
                                    , fontFamily: "Cherolina"
                                    , color: letteringFontColor
                                }}
                                >
                                {letter}
                            </text>
                        );
                    })}
                </svg>
            </div>
            <div className='main_text_summer_bottom' style={{'--svg-fill-color': letteringFontColor, bottom: bottom2}}>
                <svg xmlns="http://www.w3.org/2000/svg" width={350}>
                    {['W', 'e', 'd', 'd','i','n','g','d','a','y'].map((letter, index) => {
                        var xPosition = 0;
                        if(index === 0) {
                            xPosition = 0
                        } else if(index ===1){
                            xPosition = 65
                        } else {
                            xPosition = index * 37 + 10; 
                        }

                        if(index === 3){
                            xPosition -= 10
                        } else if(index === 4){
                            xPosition -= 15
                        } else if(index >= 5 && index < 7){
                            xPosition -= 35
                        } else if(index >= 7){
                            xPosition -= 45
                        }
                        
                        return (
                            <text 
                                key={index} 
                                className={`svg_text_summber svg_text${index + 1}`} 
                                x={xPosition} 
                                y="115" 
                                fill={"#fff"}
                                style={{ 
                                    animationDelay: `${index * 0.2 + 1.4}s`
                                    , fontSize: "80px"
                                    , fontFamily: "Cherolina"
                                    , color: letteringFontColor
                                }}
                                >
                                {letter}
                            </text>
                        );
                    })}
                </svg>
            </div>
        </div>
        <div className='main_date_box_summer' style={{color: dateColor, bottom: dateBottom, fontSize: datefontSize?datefontSize:"16px"}}>
            <p>{weddingData.year}.{weddingData.month}.{weddingData.date} {weddingData.dayOfWeek} {weddingData.time}</p>
        </div>
    </div>
    );
};

export default Main_Summer;