import React, {useEffect} from 'react';
import "../../css/main_spring.css"
import "../../css/spring/main.scss"
import AutoPlayAudio from '../AutoPlayAudio';

const Main_Spring = ({groomNameEn, brideNameEn, weddingData, color, fontWeight, isNotName, infoColor}) => {

    useEffect(() => {
        // 핀치 줌 방지 이벤트 등록
        const preventPinchZoom = (e) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventPinchZoom);
    }, []);

    return (
        <div className='main_container' style={{color : color ,'--svg-fill-color': color}}>
            <AutoPlayAudio/>
            {!isNotName && (
                <div className='main_name_box' style={{ fontWeight: fontWeight ? fontWeight : 400, color:infoColor?infoColor: color }}>
                    <p>{groomNameEn}</p>
                    <p>{brideNameEn}</p>
                </div>
            )}
            <div style={{ position: 'relative' }}>
                <img className='main_img' src={weddingData.mainImg} alt='Main Image'></img>

                <div className='main_text'>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible' }}>
                        {['H', 'a', 'p', 'p', 'i', 'l', 'y'].map((letter, index) => {
                            var xPosition = 0;
                            if(index === 0) {
                                xPosition = 0
                            } else if(index ===1){
                                xPosition = 85
                            } else {
                                xPosition = index * 37 + 42; 
                            }

                            if(index === 5){
                                xPosition -= 5;
                            } else if (index === 6){
                                xPosition -= 21;
                            }

                            return (
                                <text 
                                    key={index} 
                                    className={`svg_text svg_text${index + 1}`} 
                                    x={xPosition} 
                                    y="110" 
                                    fill={color}
                                    style={{ animationDelay: `${index * 0.1}s` }}
                                    >
                                    {letter}
                                </text>
                            );
                        })}
                    </svg>
                </div>

                <div className='main_text_bottom'>
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ overflow: 'visible', width: "350px"}}>
                        {['E', 'v', 'e', 'r','A','f','t','e','r'].map((letter, index) => {
                            var xPosition = 0;
                            if(index === 0) {
                                xPosition = 0
                            } else if(index ===1){
                                xPosition = 65
                            } else {
                                xPosition = index * 37 + 18; 
                            }

                            if(index === 3){
                                xPosition -= 13
                            } else if(index === 4){
                                xPosition -= 6
                            }else if(index === 5) {
                                xPosition += 38
                            } else if(index === 6) {
                                xPosition += 25
                            } else if(index === 7) {
                                xPosition += 9
                            } else if(index === 8) {
                                xPosition -= 3
                            } 


                            return (
                                <text 
                                    key={index} 
                                    className={`svg_text svg_text${index + 1}`} 
                                    x={xPosition} 
                                    y="115" 
                                    fill={color}
                                    style={{
                                        animationDelay: `${index * 0.1 + 0.7}s`,
                                        zIndex: 300 - index, // 기본값 200에서 index만큼 감소
                                        // position: "relative", // z-index 적용을 위한 설정
                                    }}
                                    >
                                    {letter}
                                </text>
                            );
                        })}
                    </svg>
                </div>

            </div>

            <div className='main_date_box' style={{fontWeight: fontWeight ? fontWeight: 400, color:infoColor?infoColor: color }}>
                <p>{weddingData.year}.{weddingData.month}.{weddingData.date} {weddingData.dayOfWeek} {weddingData.time}</p>
            </div>
        </div>
    );
};

export default Main_Spring;