import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';
import Information from '../component/Information';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import Info_Fall from '../component/Fall/Info_Fall';

const JungHanNa = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "소순재🖤정한나 결혼합니다.";
    const openDesc = "5월10일(토) 오후12시30분\n진주 더 리움 웨딩홀 라루체홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/120/thumb.jpg";

    const lng = 128.221150706646;
    const lat = 35.1805495463039;

    const placeTelNum = "0507-1324-7114";

    const forCar = "웨딩홀 앞 지상주차장 이용";
    const forBus = "· 시내버스노선 8, 281, 380, 381 진성면 사무소 하차 \n(도보5분)\n   · 시외버스터미널 택시 (20~25분 소요) : 정면 택시 승차창 \n이용\n   · 고속버스터미널 택시 (20분 소요) : 터미널 정면 택시 \n승차장 이용\n· 경상국립대학교 칠암캠퍼스 앞 택시 승차장 이용"
    const forTrain2 = "진주역 택시(10~15분 소요) : 역앞 정면 택시 승차장 이용";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 블랙
        concept: "SUMMER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#fff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const msg1 = "좋은 인연으로 만나 \n늘 곁에서 아껴주신 분들께 감사드립니다.\n이제 설레는 마음으로 인생의 첫걸음을 내딛으려 합니다.";
    const msg2 = "새로운 다짐과 의미를 간직하며,\n어제보다 오늘 더 사랑하고, 더 행복하게 살겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "소순재",
            bank: "우리은행",
            accountNumber : "2564-08820-02-102",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "소승호",
            bank: "농협은행",
            accountNumber : "513-0345-2026-011",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김남순",
            bank: "농협은행",
            accountNumber : "352-1717-9090-33",
            isKakao : false
        }
    ]


    const brideAccountList = [
        {
            role: "신부",
            name: "정한나",
            bank: "농협은행",
            accountNumber : "899-02-257021",
            isKakao :false
        }
        ,{
            role: "신부 부",
            name: "정민환",
            bank: "농협은행",
            accountNumber : "806-01-025050",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김정순",
            bank: "농협은행",
            accountNumber : "831064-52-071326",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "눈못마주침",
            brideAnswer: "뒷꿈치포기"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "아직잘모름",
            brideAnswer: "청심환어디?"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상웃어줘",
            brideAnswer: "넌내전부야"
        },
        // {
        //     question : "유부남/녀 기념 각오 한마디!",
        //     groomAnswer: "행복 그잡채",
        //     brideAnswer: "늘 지금처럼"
        // },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "고맙습니다",
            brideAnswer: "행복할게요"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "소순재",
            call: "010-3073-0833"
        }
        // ,
        // {
        //     role: "신랑 부",
        //     name: "소승호",
        //     call: "010-8643-3433"
        // },
        // {
        //     role: "신랑 모",
        //     name: "김남순",
        //     call: "010-5760-3809"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "정한나",
            call: "010-8808-4593"
        }
        // ,{
        //     role: "신부 부",
        //     name: "정민환",
        //     call: "010-9732-9980"
        // }
        // ,{
        //     role: "신부 모",
        //     name: "김정순",
        //     call: "010-8533-4593"
        // }
    ]

    const informationList =[
        {
            title : "식사 안내",
            content : "• 식사시간\nPM 14:00~16:00 뷔페 이용 가능\n\n• 스테이크 교환권 안내\n교환권을 스테이크 코너에 제시 해 주시면\n'안심 스테이크'가 제공됩니다.\n\n• 어린이 동반 하객\n미취학 아동은 식권없이 입장 가능합니다.\n(식권은 8세 이상 부터 수령)"
        }
        ,{
            title: "셔틀버스 안내",
            content : "그랜드모먼트 셔틀버스를 운행하고 있습니다.\n(15~20분 간격으로 운행)\n• 오실때\n지하철 2호선 남천역 4번출구 기준, \n전방 250m 남천더샵아파트 103동 근처\n\n• 가실때\n그랜드모먼트(예식홀) B4층 기준, \n좌측 셔틀버스 정류장\n\n* 식전 차량이 많아 진입 시\n교통혼란이 있습니다. 참고 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };

    // #904144
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    {/* <Music concept={conceptStyle.concept}/> */}
                    <Main_Summer topContent={"The wedding of"} welcomeFontSize={"16px"} weddingData={weddingData} color={"#ffffff"} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} bottom={"24%"} bottom2={"14%"} dateBottom={"1%"} top={"2%"}/>
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={"결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    {/* <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper> */}
                    {/* <Information width={"320px"} height={"210px"} divHeight={"340px"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forTrain2={forTrain2} forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default JungHanNa;