import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Info_Spring2 from '../component/Spring/Info_Spring2';


const ChoHyeRin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "임규헌❤조혜린 결혼합니다💍";
    const openDesc = "2025년 4월 12일 (토) 오후 4시 50분\n빌라드지디 안양 3F 크리스탈캐슬홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/92/thumb.jpg";

    const lng = 126.951315010315;
    const lat =  37.4015092435868;

    const placeTelNum = "031-382-3838";


    const forCar = "웨딩홀 건물 주차장 [안양시 동안구 관악대로 254]\n안양종합운동장 주차장 [안양시 동안구 평촌대로 389] \n(셔틀버스 수시운행)";
    const forBus = "종합운동장 하차\n\n일반 8, 8-1, 9, 9-3, 11-2, 11-3, 51, 60, 80\n지선(초록) 5625, 5626, 5713\n간선(파랑) 541";
    const forSubway = "4호선 인덕원역 8번출구 앞 (셔틀버스 수시운행)\n4호선 평촌역 3번출구 금강빌딩 (셔틀버스 수시운행)";


    const parents = [
        {groom : "임성호 · 김순녀의 차남"},
        {bride : "조정훈 · 오연서의 장녀"}
    ]

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    // 빈티지 옐로우 : #EAC63C
    // 귤 : #f2a052
    const conceptStyle = {
        // spring - 귤
        concept: "SPRING",
        titleColor: "#f2a052",
        pointColor: "#f2a052",
        btnBgColor: "#EAC63C",
        fontColor: "#ffffff",
        btnFontColor: "#313131",
        chatBgColor: "#fff9e9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    // const conceptStyle = {
    //     // spring 핑크B
    //     concept: "SPRING",
    //     titleColor: "#ff8faf",
    //     pointColor: "#ff8faf",
    //     btnBgColor: "#ffcfe1",
    //     fontColor: "#313131",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#fff5f9",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }


    const msg1 = "모든 것이 새로워지는 봄날, \n사랑하는 두 사람이\n새로운 출발을 시작하려 합니다.";
    const msg2 = "바쁘시더라도 귀한 걸음 하셔서 \n따뜻한 마음으로 격려해 주신다면 \n큰 기쁨이 되겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "임규헌",
            bank: "국민은행",
            accountNumber : "271202-04-204183",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9OB7Wrm"
        }
        ,{
            role: "신랑 부",
            name: "임성호",
            bank: "국민은행",
            accountNumber : "679802-01-012169",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "김순녀",
            bank: "우리은행",
            accountNumber : "477-044881-02-001",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "조혜린",
            bank: "하나은행",
            accountNumber : "102-910715-39107",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FMgX8wyfO"
        }
        ,{
            role: "신부 부",
            name: "조정훈",
            bank: "카카오뱅크",
            accountNumber : "3333-04-1462831",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "오연서",
            bank: "국민은행",
            accountNumber : "878302-01-144456",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이영인",
            call: "010-4789-2824"
        },
        {
            role: "신랑 부",
            name: "이청현",
            call: "010-4412-2824"
        },
        {
            role: "신랑 모",
            name: "이병순",
            call: "010-5437-2824"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이보은",
            call: "010-6487-7597"
        },
        {
            role: "신부 부",
            name: "이성열",
            call: "010-5402-7597"
        },
        {
            role: "신부 모",
            name: "유선희",
            call: "010-5558-7597"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "첫눈에 반함",
            brideAnswer: "너무 떨렸어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "실감이 안나",
            brideAnswer: "난 기대돼ෆ"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "넌 이제 내꺼",
            brideAnswer: "많이 사랑해"
        },
        {
            question : "결혼을 앞두고 부모님들께 드리고싶은 말은?",
            groomAnswer: "감사합니다",
            brideAnswer: "사랑해요ෆ"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이ෆ"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#f2a052"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"따스한 봄날, 우리 결혼합니다♥"} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forCar={forCar} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default ChoHyeRin;