import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const ByeonYunJi = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "수훈 🖤 윤지 결혼식에 초대합니다.";
    const openDesc = "2025년 04월 20일(일) 오후 12시\n의정부 웨딩팰리스 2층 단독홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/182/thumb.jpg";

    const placeTelNum = "031-837-0101";

    const lng = 127.042848181608;
    const lat = 37.7380243581824;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forBus = " ·  공항버스ㅣ 김포공항 - 인천공항 - 의정부\n·  시내버스ㅣ 1, 2, 3, 5, 8, 21, 23, 25, 33, 36, 39,\n    50, 51, 55, 106, 72\n·  좌석버스ㅣ 138, 138-5, 138-6, 138-7, 138-8, \n    138-9\n·  마을버스ㅣ 201, 202-1, 206-2, 207, 208"
    const forCar = " 경기 의정부시 시민로 70 웨딩팰리스 2층 단독홀\n031)837-0101"
    const forSubway = "·  1호선 의정부역 하차\n    → 2번 출구 도보 5분 이내 거리\n·  경전철 ㅣ 의정부경전철 의정부역 하차\n    → 1번 출구 도보 2분 이내 거리"
    const forParking = "1. 삼성생명 건물 지하 주차장\n( 진입로가 좁은편으로 SUV 같은 큰 차량은 조금 까다로울 \n수 있는 점 미리 양해 바랍니다. )\n\n2. 지상 주차장 2곳\n- 맞은편 의정부 유료 주차장\n- 뒤편 잠실 감자탕 주차장\n\n웨딩홀 입구에서 웨딩팰리스 주차 요원들이 상주하며 \n주차장 안내를 도와 드리고 있습니다.\n(총 300대 수용 가능/하객 무료 2시간 가능)\n\n🖤 Tip\n신세계 백화점 앱 다운로드 후 가입 → 주차 메뉴 \n→ 의정부점 (웨딩홀 도보 5분이내) → 푸시 알림 설정 \n→ 3시간 무료주차권 발급"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EFD140
    // #EB9217
    // #ff8faf
    // #E36402
    // #88abd1
    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
  //  #E2396A
  // #F77BAD
    // #ffb0d2 쿨 연핑크
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "저희 결혼식에 초대 합니다🖤";

    const msg1 = "'내 모든 삶은 너를 찾아가는 여행이었어'\nmy whole life has been a journey to find you";
    const msg2 = " - 영화 <이프 온리> -"
    const msg3 = "\n벚꽃이 만개하던 4월의 어느 봄날, \n운명 같은 우리의 인연을 시작으로 \n이제는 하나가 되어 평생을 함께 하려고 합니다."
    const msg4 = "꽃보다 아름답게 햇살보다 따뜻하게 살 수 있도록 \n앞날을 축복해 주시면 감사하겠습니다.\n\n";

    const groomAccountList = [
        {
            role: "신랑",
            name: "김수훈",
            bank: "농협은행",
            accountNumber : "352-0796-3792-03",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9RjsCcT"
        }
        ,{
            role: "신랑 부",
            name: "김태근",
            bank: "농협은행",
            accountNumber : "225020-52-015491",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "김영옥",
            bank: "농협은행",
            accountNumber : "356-0053-2276-13",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "변윤지",
            bank: "국민은행",
            accountNumber : "664602-01-493469",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FQjhfxCNC"
        }
        // ,{
        //     role: "신부 부",
        //     name: "김종호",
        //     bank: "농협은행",
        //     accountNumber : "568-01-019151 ",
        //     isKakao :false
        // }
        ,{
            role: "신부 모",
            name: "문소미",
            bank: "국민은행",
            accountNumber : "664602-01-370744",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "윤병익",
            call: " 010-7199-3936"
        }
        ,{
            role: "신랑 부",
            name: "윤명구",
            call: "010-3218-3936"
        }
        ,{
            role: "신랑 모",
            name: "박용신",
            call: "010-7235-3936"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이나현",
            call: "010-7597-0815"
        }
        ,{
            role: "신부 부",
            name: "이규호",
            call: "010-4254-4815"
        },
        {
            role: "신부 모",
            name: "문정선",
            call: "010-3333-4708"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "완~전ㅎㅎ",
            brideAnswer: "못 잊어ㅎㅎ"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "나 장가 간다!",
            brideAnswer: "나 시집 간다!"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사이 좋게",
            brideAnswer: "잘 지내자"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "지혜롭게!",
            brideAnswer: "현명하게!"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "잘 살게요❤️",
            brideAnswer: "감사합니다❤️"
        }
    ]


    const informationList =[
        {
            title : "화환 안내",
            content : "예식장 사정상 화환반입이 불가하오니 양해하여 주시기 바랍니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"153px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ByeonYunJi;