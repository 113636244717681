import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const HwangGaYoung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이정섭 ♥ 황가영 결혼합니다.";
    const openDesc = "5월 10일 오후 3시30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/195/thumb.jpg";

    const lng = 128.533210876944;
    const lat = 35.8368668508677;

    const placeTelNum = "054-556-8888";

    const forCar = "대구 달서구 구마로 52 (본동 687)";
    const forParking = "파라다이스웨딩 지하주차장 이용 가능 \n* 전용주차장이 다소 혼잡할 수 있사오니 가급적 대중교통 \n이용 부탁 드립니다."
    const forRentBus = "* 신부측 대절버스\n출발지: 용상 안동병원 앞 \n시간: 오후 1시 출발"
    const forTrain2 = " · 동대구역 출발\n지하철1호선-'대명역 1번출구' 564버스 환승 \n→ '남대구IC'하차 / 택시 5분\n\n · 서대구역 출발\n환승버스 급행8 '서대구역 남측1' 승차 \n→ '학산공원1' 하차 / 택시 10분"

    {/* const parents = [
        {groom : "김두희 · 이연숙의 장남"},
        {bride : "❁이석원 · 박영란의 차녀"}
    ] */}

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
    // #EE9962
    // #ECAD7C
    // #eb7e38
    // #fc8135
   //  #ff9a75
   // #F58671
       // #ffb0d2 쿨 연핑크
       // #B86D82
           // 딥로즈 핑크 : #BC5078
    // 빈티지 로즈 : #D77599
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다.";

    const msg1 = "매년 5월 10일이 되면,\n저희는 그날을 기념하며 사진첩을 꺼내\n인생에서 가장 행복했던 기억을 떠올릴 것입니다."
    const msg2 = "그 때, 그 사진 속에, \n저희와 함게 웃고 있는 여러분이 있었으면 좋겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "이정섭",
            bank: "신한은행",
            accountNumber : "110-392-358350",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "이동수",
            bank: "대구은행",
            accountNumber : "158-07-210316-001",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "김미옥",
            bank: "대구은행",
            accountNumber : "051-08-032481",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "황가영",
            bank: "농협은행",
            accountNumber : "302-3191-5872-41",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "황건상",
            bank: "농협은행",
            accountNumber : "707018-52-172225",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김덕자",
            bank: "기업은행",
            accountNumber : "160-026819-02-035",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이정섭",
            call: "010-9820-8111"
        }
        ,{
            role: "신랑 부",
            name: "이동수",
            call: "010-9428-9675"
        }
        ,{
            role: "신랑 모",
            name: "김미옥",
            call: "010-3508-2220"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "황가영",
            call: "010-3191-5872"
        }
        ,{
            role: "신부 부",
            name: "황건상",
            call: "010-4505-0201"
        },
        {
            role: "신부 모",
            name: "김덕자",
            call: "010-9326-0440"
        }
    ]

    const interviewContent = [
        {
            question : "서로의 첫인상 기억해?",
            groomAnswer: "4차원소녀🧝🏻‍♀️",
            brideAnswer: "동글왕감자🥔"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "긴장반설렘반",
            brideAnswer: "두근콩닥콩닥"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "옆자리내꺼♥️",
            brideAnswer: "알라뷰쏘마치"
        },
        {
            question : "부부가 된 기념 한마디!",
            groomAnswer: "즐기며살자",
            brideAnswer: "나만따라와"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "모든분들",
            brideAnswer: "행복만가득♥️"
        }
    ]

    const informationList =[
        {
            title : "신부측 대절버스 안내",
            content : "출발지: 용상 안동병원 앞 \n시간: 오후 1시 출발"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo marginTop={"0px"} conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forCar={forCar} forParking={forParking} forTrain2={forTrain2} forRentBus={forRentBus} conceptStyle={conceptStyle}/>
                    <Information height={"60px"} titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} />
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default HwangGaYoung;