import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
// import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import MotionWrapper from './MotionWrapper';

const Information = ({conceptStyle, informationList, pointFontColor, height, divHeight, width, titleFontSize, fontSize, divPadding}) => {

    const formatMessage = (message) => {
        return message.split(/(%.*?%|@.*?@)/g).map((part, index) => {
            if (part.startsWith("%") && part.endsWith("%")) {
                return (
                    <span key={index} style={{ fontWeight: "bold", fontStyle: "italic", fontSize: "15px" }}>
                        {part.slice(1, -1)} {/* Remove the % symbols */}
                    </span>
                );
            } else if (part.startsWith("@") && part.endsWith("@")) {
                return (
                    <span key={index} style={{ fontWeight: "bold", fontSize: "15px", color: pointFontColor?pointFontColor: "#313131" }}>
                        {part.slice(1, -1)} {/* Remove the @ symbols */}
                    </span>
                );
            }
            return part; // Plain text
        });
    };

    return (
        <div style={{textAlign: "center", marginBottom: "120px", height: "fitContent"}}>
            <h3 className='spring_h3_title' style={{paddingTop: "76px", color: conceptStyle.titleColor, fontFamily: conceptStyle.fontFamilyEn}}>INFORMATION</h3>
            <MotionWrapper>
            <div className='info_swiper' style={{display: "flex", alignItems: "center", justifyContent: "center", height: "fitContent", overflow: "hidden"}}>
                <Swiper
                    height={"500px"}
                    cssMode={true}
                    navigation={true}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    mousewheel={true}
                    keyboard={true}
                    loop={true} 
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className='mySwiper'
                    speed={500}
                >
                    {informationList.map((info, idx) => (
                        <SwiperSlide key={idx}>
                            <div 
                                style={{
                                    width: width?width:"300px",
                                    height: divHeight?divHeight:"210px",
                                    borderRadius: "12px",
                                    boxShadow: "0 1px 2px rgba(0,0,0,0.07), \n 0 2px 4px rgba(0,0,0,0.07), \n0 4px 8px rgba(0,0,0,0.07), \n0 8px 16px rgba(0,0,0,0.07),\n0 16px 32px rgba(0,0,0,0.07)",
                                    padding: divPadding?divPadding:"20px",
                                    display : "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    textAlign: "center",
                                    alignItems: "center",
                                    fontFamily: conceptStyle.fontFamilyKo,
                                    margin: width?"45px 10px":"45px 30px",
                                    overflow: "hidden"
                                }}>
                                <p style={{margin: "10px auto", fontSize: titleFontSize?titleFontSize:"16px", fontWeight: "500"}}>{info.title}</p>
                                <div style={{height: height?height:"210px", alignContent: "center", overflow: "hidden"}}>
                                    <p style={{fontSize: fontSize?fontSize:"14px"}}>{formatMessage(info.content)}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            </MotionWrapper>
        </div>
    );
};

export default Information;
