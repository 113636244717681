import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GuestBookCard from './GuestBookCard';
import Loading from '../Loading';

const GuestBookList = ({invitationInfoId, pointColor}) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [guestBookList, setGuestBookList] = useState([]); 

    const [isLoading, setIsLoading] = useState(true); 

    const fetchGuestBooks = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/guest_book/invitationInfo/${invitationInfoId}/all`);
            setGuestBookList(response.data.data); 
            setIsLoading(false);
        } catch (err) {
            console.log("에러 발생 : ", err);
        } 
    };

    useEffect(() => {
        fetchGuestBooks();
    }, []);

    if (isLoading) {
        return <Loading/>;
    }
    return (
        <div style={{maxHeight: "410px", overflowY: "scroll", marginTop: "10px"}}>
            {guestBookList.map((guestBook, index) => (
                <GuestBookCard
                    key={index}
                    color={pointColor || '#000000'}
                    guestBook={guestBook}
                    index={guestBookList.length - index}
                />
            ))}
        </div>
    );
};

export default GuestBookList;
