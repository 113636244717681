import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import AccountList2 from '../../component/Account/AccountList2';

const KimKaYoung3 = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "허재관❤️김가영 결혼합니다.";
    const openDesc = "2025년 06월 22일 일요일 11시 50분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/191/thumb.jpg";

    const lng = 127.39278052858;
    const lat =36.3770177025933;

    const placeTelNum = "042-866-5100";

    const forCar = "대전광역시 유성구 엑스포로123번길 55 (도룡동)";
    const forSubway = "대전 1호선 대전역 → 618번(정부청사역) \n→ 스마트시티5단지 정류장 하차";
    const forBus = " · 707번(대전역) → 스마트시티 2단지\n606번(대전역) → 618번(대전예술의전당) \n→ 스마트시티5단지\n\n · 606번(대전역) → 911번(수정타운아파트정문) \n→ 스마트시티5단지";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FF8A83",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "우리 결혼합니다";

    const msg1 = "그대라는 존@재@를 만나 저는\n매일 웃는 습@관@이 생겼어요.\n\n앞으로도 서로의 입@가@를 미소짓게 만들며\n곁을 지켜주는 @영@원한 동반자가\n되어줄 것을 약속할게요";
    const msg3 = "평생을 약속하는 뜻깊은 자리에 오셔서\n축하해 주시면 감사하겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "허재관",
            bank: "농협은행",
            accountNumber : "312-0043-5730-01",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "허공석",
            bank: "국민은행",
            accountNumber : "459001-04-198333",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "이행숙",
            bank: "농협은행",
            accountNumber : "352-1177-1443-83",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김가영",
            bank: "하나은행",
            accountNumber : "642-910795-25407",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "김용교",
            bank: "신한은행",
            accountNumber : "814-04-429835",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "이기순",
            bank: "경남은행",
            accountNumber : "578-22-0438023",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "허재관",
            call: "01066536663"
        }
        ,{
            role: "신랑 부",
            name: "허공석",
            call: "01088146042"
        }
        ,{
            role: "신랑 모",
            name: "이행숙",
            call: "01034136042"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김가영",
            call: "01071915863"
        }
        ,{
            role: "신부 부",
            name: "김용교",
            call: "01033415863"
        }
        ,{
            role: "신부 모",
            name: "이기순",
            call: "01038875863"
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "천사같았어",
            brideAnswer: "어떻게 잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "많이떨리네",
            brideAnswer: "너무즐거워"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이사랑해",
            brideAnswer: "나도사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "잘살아봐요",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "많이많이♥"
        },
    ]


    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
        // #E5E8EE
        // #EFD140
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getFirstEnName(weddingData.brideNameEn)} brideNameEn={getFirstEnName(weddingData.groomNameEn)} color={"#ffffff"} letteringFontColor={"#E5E8EE"} dateColor={"#ffffff"} bottom={"85%"} bottom2={"75.5%"} dateBottom={"1%"} top={"1%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter pointColor={"#FF8A83"} title={title} msg1={msg1} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forBus={forBus} forSubway={forSubway} forCar={forCar} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimKaYoung3;