import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import Main_Winter from '../../component/Winter/Main_Winter';
import Save_Date_Fall from '../../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../../component/Fall/Info_Fall';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import CallInfo from '../../component/CallInfo';

const LeeSuBeen = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "유현 ♥ 수빈 결혼합니다.";
    const openDesc = "2025. 06. 14 토 오후 3시 30분\n마리나컨벤션 웨딩홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/169/thumb1.jpg";

    const placeTelNum = "051. 746. 5112";

    const lng = 129.14852301727;
    const lat = 35.1581166994754;

    const forCar = "'마리나컨벤션 웨딩홀' 또는 '마리나센터' 검색\n\n ·  안내사항\n웨딩홀 건물 내 주차장 혼잡시,\n인근 '오렌지프라자' 또는 '베네시티' 상가 주차장 이용 \n(2시간 무료)\n\n ·  주차장 진입로가 협소하여 혼잡할 수 있으니 대중교통 \n이용 부탁드립니다."
    const forPublic = "  ·  지하철\n지하철 2호선 동백역 1번 출구 (도보 10분)\n\n  ·  버스\n대우 마리나 하차 (도보 2분) / 운촌 하차 (도보 8분)";
    
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Milchella"
    }


    const parents = [
        {groom : "진용성 · 서윤희의 장남"},
        {bride : "박광익 · 장남옥의 차녀"}
    ]

    const title = "유현 ♥ 수빈 결혼합니다."

    const msg1 = "따스한 여름의 시작,"
    const msg2 = "푸른 여름 하늘처럼\n맑고 깨끗한 순수한 사랑을 약속하려 합니다."
    const msg3 = "햇살처럼 소중한 저희 두사람의 사랑이\n크고 깊은 단단한 사랑이 되려합니다."
    const msg4 = "소중한 날,\n축복과 격려주시면 소중한 추억으로간직하겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "김유현",
            bank: "농협은행",
            accountNumber : "302-1678-7202-91",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "김성하",
            bank: "하나은행",
            accountNumber : "161-19-106382",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "주미옥",
            bank: "농협은행",
            accountNumber : "721037-56-268348",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이수빈",
            bank: "부산은행",
            accountNumber : "112-2176-3514-04",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "이상기",
            bank: "부산은행",
            accountNumber : "101-2064-0276-09",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "최은경",
            bank: "부산은행",
            accountNumber : "126-12-027650-8",
            isKakao : false
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김유현",
            call: "010-2340-2723"
        }
        ,
        {
            role: "신랑 부",
            name: "김성하",
            call: "010-9319-7081"
        },
        {
            role: "신랑 모",
            name: "주미옥",
            call: "010-3874-7082"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이수빈",
            call: "010-4598-9742"
        }
        ,{
            role: "신부 부",
            name: "이상기",
            call: "010-6889-0396"
        }
        ,{
            role: "신부 모",
            name: "최은경",
            call: "010-9930-9742"
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너 찜뽕",
            brideAnswer: "넌 내꺼다."
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렌다",
            brideAnswer: "울지만 말자"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "나도 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "평생잘할게",
            brideAnswer: "평생잘해라"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "넌평생내꺼",
            brideAnswer: "ㄱ..그래나도"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={title} msg1={msg1} msg2={msg2} msg3={msg3} msg4={msg4} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList  fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring  weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Location_Spring  weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                        <Location_Desc forCar={forCar} forPublic={forPublic} conceptStyle={conceptStyle}/>
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeSuBeen;