import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import Info_Spring2 from '../../component/Spring/Info_Spring2';

const LeeMiSuk = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "박연규 ❤️ 이미숙 결혼합니다.";
    const openDesc = "2025년 05월 03일 오전 11시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/141/thumb.jpg";

    const lng = 127.122120211139;
    const lat = 35.8333539202061;

    const placeTelNum = "063-253-9790";

    const forCar = "전북 전주시 완산구 온고을로 1 한국교직원공제회 \n전북회관 4층\n\n* 주차장 입구 : 농협 정문(지하 1~4층 무료주차)\n만차 시 주차요원 안내에 따라 이동하시면 됩니다.";
    const forBus = "[버스터미널 이용 시]\n시외&고속버스터미널 하차 후 도보 10~15분 \n(택시 이용 시 기본요금)\n\n[시내버스 이용 시]\n ·  이마트 하차(서신현대아파트 방면) 3-2번, 101번, \n102번, 104번, 200번, 309번, 2002번\n ·  한일고 하차(서신유창아파트 방면) 2001번\n ·  KT정보센터 하차(경기장거성아파트 방면) 74번, \n75번, 6001번"
    
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 블루
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "우리 결혼합니다.";


    const msg1 = "'어린 시절 난 심술 꾸러기 였고\n내 청소년기는 힘들었는지 모르지만\n이제 이렇게 사랑하는 당신이 거기에 서 있으니\n내가 과거에 그 무언가\n좋은 일을 했음에 틀림없어요'";
    const msg2 = "- 영화 Sound of music 中 'Something good' -"
    const msg3 = "\n따스한 햇살과 싱그러운 바람이 가득한 봄날, \n두 사람이 하나 되어 새 인생을 시작하려 합니다.\n이 소중한 자리에 사랑하는 분들과 \n기쁨을 나누고 싶습니다.";


    const parents = [
        {groom : "❁박금주 · 이윤실의 장남"},
        {bride : "이학두 · 최경숙의 차녀"}
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "박연규",
            bank: "농협은행",
            accountNumber : "352-1568-2029-83",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7mJqFlt"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "이명원",
        //     bank: "농협은행",
        //     accountNumber : "891-12-241521",
        //     isKakao : false
        // }
        ,{
            role: "신랑 모",
            name: "이윤실",
            bank: "농협은행",
            accountNumber : "658-02-024141",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이미숙",
            bank: "국민은행",
            accountNumber : "373301-01-415704",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7lT5Rr6"
        }
        ,{
            role: "신부 부",
            name: "이학두",
            bank: "농협은행",
            accountNumber : "356-1432-1406-03",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "최경숙",
            bank: "농협은행",
            accountNumber : "463144-56-043083",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "눈못마주침",
            brideAnswer: "뒷꿈치포기"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "아직잘모름",
            brideAnswer: "청심환어디?"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상웃어줘",
            brideAnswer: "넌내전부야"
        },
        // {
        //     question : "유부남/녀 기념 각오 한마디!",
        //     groomAnswer: "행복 그잡채",
        //     brideAnswer: "늘 지금처럼"
        // },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "고맙습니다",
            brideAnswer: "행복할게요"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "박연규",
            call: "010-4611-5752"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "이명원",
        //     call: "010-3584-6149"
        // }
        ,{
            role: "신랑 모",
            name: "이윤실",
            call: "010-8773-5752"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이미숙",
            call: "010-6224-7173"
        }
        ,{
            role: "신부 부",
            name: "이학두",
            call: "010-2513-9013"
        }
        ,{
            role: "신부 모",
            name: "최경숙",
            call: "010-8818-5050"
        }
    ]

    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getRemoveSpaceName(weddingData.groomNameEn)} brideNameEn={getRemoveSpaceName(weddingData.brideNameEn)} color={"#505D89"} letteringFontColor={"#505D89"} dateColor={"#505D89"} bottom={"82%"} bottom2={"72.5%"} dateBottom={"1%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring2 parents={parents} fontSize={"14px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter titleFontSize={"21px"} fontSize={"15px"} title={title} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontSize={"15px"} fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList titleFontSize={"14px"} fontSize={"13px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc titleFontSize={"15px"} fontSize={"13px"} isPoint={true} forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeMiSuk;