import React from 'react';
import lineImg from '../../css/line_hori_gray.png';

const Location_Desc = ({ desc, forCar, forParking, forBus, forSubway, forShuttleBus, forTrain, forTrain2, forRentBus, forFlight, forTaxi, conceptStyle, titleFontSize, fontSize, isBold, isPoint, forPublic }) => {
    return (
        <div>
            <div style={{ color: "#313131", display: "flex", flexDirection: "column", justifyContent: "center", margin: "50px 0", alignItems: "center", lineHeight: "1.2rem", fontFamily: conceptStyle.fontFamilyKo}}>
                {desc && 
                    <p style={{ fontSize: fontSize?fontSize:"14px", padding: "10px 0", textAlign:"center" }}>{desc}</p>
                }
                {forRentBus && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>대절버스 이용안내</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forRentBus}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forShuttleBus && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>셔틀버스 이용안내</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0",color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forShuttleBus}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forCar && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131" }}>자가용 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0",color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forCar}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forParking && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131" }}>주차장 안내</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forParking}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forPublic && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131" }}>대중교통 안내</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forPublic}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forTaxi && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: "14px", margin: "10px 0" , fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>택시 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forTaxi}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forBus && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0", fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131" }}>버스 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forBus}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}


                {forSubway && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{  fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0" , fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>지하철 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forSubway}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}


                {forTrain && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0" , fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>철도 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forTrain}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forTrain2 && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{  fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0" , fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>기차 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forTrain2}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}

                {forFlight && (
                    <>
                        <span style={{ width: "300px", margin: "0 auto", textAlign: "left", alignItems: "center" }}>
                            <p style={{ fontSize: titleFontSize?titleFontSize:"14px", margin: "10px 0" , fontWeight: "600", color: (isBold || isPoint) ? conceptStyle.pointColor : "#313131"}}>항공 이용 시</p>
                            <p style={{ fontSize: fontSize?fontSize:"12px", margin: "10px 0", color: conceptStyle.baseFontColor ? conceptStyle.baseFontColor:"#313131", fontWeight : isBold ? 600 : 300 }}>{forFlight}</p>
                        </span>
                        <img src={lineImg} style={{ width: "260px", margin: "20px 0" }} />
                    </>
                )}


            </div>
        </div>
    );
};

export default Location_Desc;
