import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const KimHyeLin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김승일 ❤️ 김혜린 결혼합니다.";
    const openDesc = "4월 5일 오후 1시 20분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/153/thumb.jpg";

    const placeTelNum = "032-554-6400";

    const lng = 126.72302;
    const lat = 37.526314;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forBus = "시외버스 부천88번, 김포90번\n시내버스 30, 86\n광역버스 5000, 1500, 9500\n마을버스 583, 584-1, 588, 590, 585"
    const forCar = "경인고속도로 → 부평 I.C에서 우회전 50m"
    const forSubway = "· 인천 지하철 작전역 하차 4번 출구 (도보 5분)\n· 1호선 부평역 / 7호선 부평구청역에서 인천지하철로 \n    환승 (계양- 귤현 방향)"
    const forParking = "호텔 기계 주차 타워\n호텔 옆 부지\n호텔 옆 중앙교회\n호텔 앞 홈플러스 (2시간무료)"
    const forRentBus = "원주>인천 버스대절\n출발지: 원주 따뚜 만남의광장 (강원도 원주시 남원로 565)\n출발시간: 10시30분";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FE987B 코랄
    // 레몬 옐로우 #EFD140
    // #EB9217
    // 빈티지 옐로우 : #EAC63C


    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "우리 결혼합니다.";

    const msg1 = "모든 것이 새롭게 시작되는 봄날,\n사랑하는 두 사람이\n함께하는 새 인생을 시작하려 합니다.";
    const msg2 = "봄처럼 따뜻한 마음으로\n저희의 새로운 시작을 격려해 주신다면\n더할 나위 없이 감사하겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "김승일",
            bank: "기업은행",
            accountNumber : "239-117624-01-012",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "김은기",
            bank: "농협은행",
            accountNumber : "631109-52-135737",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "손기분",
            bank: "농협은행",
            accountNumber : "302-5364-1240-81",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김혜린",
            bank: "카카오뱅크",
            accountNumber : "3333-03-5941217",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "김인광",
            bank: "농협은행",
            accountNumber : "352-1883-2913-33",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "주성미",
            bank: "국민은행",
            accountNumber : "225-24-0076-274",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김승일",
            call: "010-7184-9450"
        }
        ,{
            role: "신랑 부",
            name: "김은기",
            call: "010-6371-1240"
        }
        ,{
            role: "신랑 모",
            name: "손기분",
            call: "010-5364-1240"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김혜린",
            call: "010-6530-4371"
        }
        ,{
            role: "신부 부",
            name: "김인광",
            call: "010-9031-3489"
        },
        {
            role: "신부 모",
            name: "주성미",
            call: "010-3736-2508"
        }
    ]

    const interviewContent = [
        {
            question : "서로를 처음 본 순간 든 생각은?",
            groomAnswer: "미치는줄",
            brideAnswer: "첫눈에 반함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 설렌다",
            brideAnswer: "빨리 왔으면"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "평생 네 옆에",
            brideAnswer: "자기만 볼게"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "가족 건강 💪🏻",
            brideAnswer: "가족이 1순위"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "나만 바라봐",
            brideAnswer: "잘 살아보자"
        }
    ]

    const informationList =[
        {
            title : "대절 버스 안내",
            content : "원주>인천 버스대절\n출발지: 원주 따뚜 만남의광장 \n(강원도 원주시 남원로 565)\n\n출발시간: 10시30분"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} />
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} />
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forBus={forBus} forCar={forCar} forParking={forParking} forSubway={forSubway} forRentBus={forRentBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimHyeLin;