import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import musicTitle from '../../asset/music/picnic.mp3';

const ParkNaHye = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "박나혜 ♥ 전길중 결혼합니다.";
    const openDesc = "2025년 5월 10일(토) 오후 1시 두가헌";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/188/thumb.jpg";

    const placeTelNum = "02-3210-2100";

    const lng = 126.97990589042668;
    const lat = 37.57718586632545;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forCar = "두가헌 주차장이 매우 협소하고, 주말 광화문, 청와대 주변 \n교통이 혼잡하므로 대중교통 이용을 부탁드립니다.\n\n* 두가헌 주차장 만차시, 주변의 유료주차장으로 주차 \n하셔야하며, 신랑신부측에서 별도 안내 도와드리겠습니다."
    const forSubway = "3호선 안국역 1번 출구 도보 6분 \n5호선 광화문역 2번 출구 도보 10분";
    const forBus = "272, 401, 406, 704, 7022, 7025번, 덕성여중고 정류장\n 하차 후 도보 330m, 대로변에서 오른편에 보이는 \n‘아트큐브’와 ‘갤러리현대’ 사이로 진입하시면 됩니다.";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FE987B 코랄
    // 레몬 옐로우 #EFD140
    // #EB9217
    // 빈티지 옐로우 : #EAC63C
        // #fc8135
   //  #ff9a75
   // #F58671
       // #ffb0d2 쿨 연핑크
       // #9FB4E7
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "We're Getting Married!";

    const msg1 = "5월 10일, \n어여쁜 책갈피를 꽂아 \n오래 기억할 수 있는 하루를 만들고자 합니다.\n두 사람의 이야기를 새로 쓰는 따뜻한 봄날, \n다정한 발걸음 해주시면 감사하겠습니다.";
    const msg2 = "정식과 우영의 딸 나혜와\n제완과 미경의 아들 길중 올림."

    const groomAccountList = [
        {
            role: "신랑",
            name: "전길중",
            bank: "신한은행",
            accountNumber : "110-301-729029",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "전제완",
            bank: "카카오뱅크",
            accountNumber : "3333-15-3397390",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "장미경",
            bank: "농협은행",
            accountNumber : "696-02-132080",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박나혜",
            bank: "카카오뱅크",
            accountNumber : "3333-03-5571234",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "김인광",
        //     bank: "농협은행",
        //     accountNumber : "352-1883-2913-33",
        //     isKakao :false
        // }
        ,{
            role: "신부 모",
            name: "김우영",
            bank: "농협은행",
            accountNumber : "201154-51-006091",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "전길중",
            call: "010-3774-3173"
        }
        ,{
            role: "신랑 부",
            name: "전제완",
            call: "010-3610-3777"
        }
        ,{
            role: "신랑 모",
            name: "장미경",
            call: "010-8601-6911"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "박나혜",
            call: "010-7480-0014"
        }
        ,{
            role: "신부 부",
            name: "박정식",
            call: "010-3312-2980"
        },
        {
            role: "신부 모",
            name: "김우영",
            call: "010-9674-0014"
        }
    ]

    const interviewContent = [
        {
            question : "서로의 첫 인상은?",
            groomAnswer: "귀여운 앞니",
            brideAnswer: "책읽는 선비"
        },
        {
            question : "상대방의 장점은 뭐야?",
            groomAnswer: "매초 귀여워",
            brideAnswer: "매일 다정해"
        },
        {
            question : "그럼 단점은?",
            groomAnswer: "단점이 뭔데?",
            brideAnswer: "팔불출인 점"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "벌써 눈물나",
            brideAnswer: "비오지마라"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "사랑이 항상",
            brideAnswer: "세상을 구해"
        }
    ]

    const informationList =[
        {
            title : "지정좌석 안내",
            content : "소중한 분들만 모시고 \n작은 예식으로 진행합니다.\n\n손님들 좌석이 미리 배정되어 있으므로 \n만약 참석을 못 하실 경우 일찍 연락을 주시면 \n큰 도움이 됩니다.\n\n뜻 깊은 자리에 시간과 마음을 내 주셔서 \n정말 감사합니다."
        }
    ]
    

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicTitle} />
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo marginTop={"0px"} conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <Information width={"320px"} height={"210px"} divHeight={"300px"}  pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} />
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forBus={forBus} forCar={forCar} forSubway={forSubway} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ParkNaHye;