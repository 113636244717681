import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const JeonGyungIn = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "이준호 ♥ 전경인 결혼합니다.";
    const openDesc = "6월 22일 11시 10분 세인트컨벤션웨딩";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/163/thumb.jpg";

    const placeTelNum = "033)653-6633";

    const lng = 128.949686726348;
    const lat = 37.7651156084734;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };


    const forCar = " 강릉방면 진입\n→ 시청, 터미널 방면(고가도로) 진입\n→ 직진(터미널, 강릉역지남)\n→ 우측차로 주행(강릉경찰서 나오면 우회전)\n→ 직진(남대천 공단다리 건넘)\n→ 계속 직진 후 반석교회에서 좌회전 후 직진\n→ 강릉골프연습장에서 우회전\n→ 직진 후 남항진교 지나서 죄회전\n→ 세인트컨벤션 웨딩홀";
    const forBus = "남항진 정류장에서 하차 227, 228"
    // const forSubway = "  ·  강릉 고속터미널 앞에서 약 15 ~ 20분 소요\n  ·  강릉역 KTX 하차 후, 3번 출구에서 택시 탑승(10분 소요)";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FE987B 코랄
    // 레몬 옐로우 #EFD140
    // #EB9217
    // 빈티지 옐로우 : #EAC63C
    // #FC62A4
    // #f5408d
    // #ffb0d2 쿨 연핑크
    // #CB797C
    // #cc5458
    const conceptStyle = {
        // spring 쿨 연핑크
        concept: "SPRING",
        titleColor: "#ffb0d2",
        pointColor: "#ffb0d2",
        btnBgColor: "#ffb0d2",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "준호와 경인, 결혼 합니다.";

    const msg1 = "때론 오래된 친구처럼,\n때론 처음 만나는 사람처럼,\n그 많은 당신을 다 사랑하고 싶습니다.";
    const msg2 = "-'사랑고백'中-"
    const msg3 = "\n우리라는 이름을 처음 시작하는 날,\n늘 곁에서 아껴주셨던 고마운 분들을 모십니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "이준호",
            bank: "신한은행",
            accountNumber : "110-242-412757",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "이학관",
            bank: "기업은행",
            accountNumber : "010-8162-3054",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "김영자",
            bank: "신한은행",
            accountNumber : "110-352-771551",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "전경인",
            bank: "농협은행",
            accountNumber : "351-0016-0097-83",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "전연철",
            bank: "농협은행",
            accountNumber : "333810-51-122775",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "이영희",
            bank: "농협은행",
            accountNumber : "351-0059-4941-53",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김승일",
            call: "010-7184-9450"
        }
        ,{
            role: "신랑 부",
            name: "김은기",
            call: "010-6371-1240"
        }
        ,{
            role: "신랑 모",
            name: "손기분",
            call: "010-5364-1240"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김혜린",
            call: "010-6530-4371"
        }
        ,{
            role: "신부 부",
            name: "김인광",
            call: "010-9031-3489"
        },
        {
            role: "신부 모",
            name: "주성미",
            call: "010-3736-2508"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "오.내꺼다",
            brideAnswer: "수줍음 많은 남자🤣..알 수가 없다.."
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "얘랑 할 줄 알았음",
            brideAnswer: "내가 진짜 결혼한다구?🥹"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "경인아 알라뷰",
            brideAnswer: "준호야, 아내 말을 잘 듣자!"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "싸워도 각방은 쓰지말자!",
            brideAnswer: "변화는 있어도 변함없이! 알콩달콩 잘~살자♥"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "결혼하면 행복하다!",
            brideAnswer: "너희도 다 해봐라..😏"
        }
    ]

    const informationList =[
        {
            title : "대절 버스 안내",
            content : "원주>인천 버스대절\n출발지: 원주 따뚜 만남의광장 \n(강원도 원주시 남원로 565)\n\n출발시간: 10시30분"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ffb0d2"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} />
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default JeonGyungIn;