import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const HyunSeoKyoung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "호준♥서경";
    const openDesc = "여러분을 결혼식에 초대합니다.";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/189/thumb.jpg";

    const lng = 128.663941;
    const lat = 35.8829222;

    const placeTelNum = "1800-1999";

    const forCar = "· 팔공산 IC에서 오실 때\n팔공산 IC를 나와서 첫 신호등에서 대구공항 방면으로 \n좌회전하여 계속 직진 후 막힌 삼거리 신호등에서 \n회전하여 약 3km 직진하시면 동촌사거리 좌측에 \n있습니다.\n\n· 동대구IC에서 오실 때\n동대구 IC를 나온 후 첫 신호등에서 좌회전하여 800m \n직진 후 육교에서 우회전 후 200m 직진 후 좌회전하여 \n1.5km 정도 오신 후 두번째 육교에서 직진하시면 됩니다.\n\n* 타지방에서 차량을 이용하는 경우 팔공산 IC를 이용하는 \n것이 더 편리합니다!";
    const forPublic = "· 일반버스: 동구1, 동구1-1, 동구3, 618, 805, 808, \n                          836, 980\n· 좌석버스: 719\n· 지하철: 1호선 동촌역 1번, 4번 출구";

    {/* const parents = [
        {groom : "김두희 · 이연숙의 장남"},
        {bride : "❁이석원 · 박영란의 차녀"}
    ] */}

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // const conceptStyle = {
    //     // spring 블루C
    //     concept: "SPRING",
    //     titleColor: "#505D89",
    //     pointColor: "#505D89",
    //     btnBgColor: "#8190be",
    //     fontColor: "#ffffff",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#f0f4e1",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }
    // #EFD140
    // #EB9217
    // #ff8faf
    // #E36402
    // #88abd1
    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
  //  #E2396A
  // #F77BAD
      // #ffb0d2 쿨 연핑크
      // #E44F69
      // #f5336d

      const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "저희 결혼합니다.";

    const msg1 = "힘든 순간이 오더라도\n이 사람이라면 괜찮겠다 싶은 \n사람을 만났습니다.";
    const msg2 = "있는 그대로의 모습을 사랑해 주는 사람,\n순수하고 진실한 사람인 그의 배우자가 되어\n곁을 지켜주고 싶습니다."
    const msg3 = "서로에게 안식처가 되는 \n따뜻한 가정을 이룰 수 있도록\n따뜻한 봄날에 오셔서 함께 축복해 주시면 감사하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "이호준",
            bank: "카카오뱅크",
            accountNumber : "3333-08-6910381",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "이기권",
            bank: "im뱅크",
            accountNumber : "056-08-133802",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "박해숙",
            bank: "im뱅크",
            accountNumber : "042-08-201054",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "현서경",
            bank: "im뱅크",
            accountNumber : "508-13-1366155-5",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "현상용",
            bank: "im뱅크",
            accountNumber : "508-10-261211-3",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "공유진",
            bank: "im뱅크",
            accountNumber : "010-2245-3913",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이호준",
            call: "010-2745-9326"
        }
        ,{
            role: "신랑 부",
            name: "이기권",
            call: "010-3819-9326"
        }
        ,{
            role: "신랑 모",
            name: "박해숙",
            call: "010-3515-9326"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "현서경",
            call: "010-5779-3913"
        }
        ,{
            role: "신부 부",
            name: "현상용",
            call: "010-3522-3913"
        },
        {
            role: "신부 모",
            name: "공유진",
            call: "010-2245-3913"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "첫 눈에 반함",
            brideAnswer: " 당연히 하지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "떨리고 설레",
            brideAnswer: "너무 설렌다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑해 공쥬",
            brideAnswer: "늘 행복하자"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "아껴줄게요",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "찾아주셔서",
            brideAnswer: "감사합니다"
        }
    ]

    const informationList =[
        {
            title : "♥신랑신부가 준비한 포토부스 이벤트♥",
            content : "10시30분-12시 운영합니다! \n많이 참여해주세요\n\n예쁜사진과 축하메시지를 남겨주시고,\n인생사진도 마음껏 가져가세요 :D"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter pointColor={"#ff8faf"} title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo marginTop={"0px"} conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forPublic={forPublic} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default HyunSeoKyoung;