import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import musicTitle from '../../asset/music/JulianMoon.mp3'


const JeonJiWon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "황보승국♥전지원 결혼합니다";
    const openDesc = "4월 26일 토요일 오후 1시 30분\n목화웨딩컨벤션 15층 벨리스홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/179/thumb1.jpg";

    const lng = 129.080664794725;
    const lat = 35.188054516869;

    const placeTelNum = "051-714-2243";

    const forSubway = "연산역(1호선/3호선) 하차 7번 출구 도보 3분 이내";
    const forBus = "29, 51, 179, 189, 100-1"
    const forParking = "본 건물 주차장 B1-B5층\n웰메이드 주차장\n해성주차장\n목화주차빌딩"

    {/* const parents = [
        {groom : "김두희 · 이연숙의 장남"},
        {bride : "❁이석원 · 박영란의 차녀"}
    ] */}

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf

    // const conceptStyle = {
    //     // spring 블루A
    //     concept: "SPRING",
    //     titleColor: "#9FB4E7",
    //     pointColor: "#9FB4E7",
    //     btnBgColor: "#bfcfef",
    //     fontColor: "#313131",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#f3f7ff",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }
    const conceptStyle = {
        concept: "SPRING",
        titleColor: "#DE3C69",
        pointColor: "#DE3C69",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다.";

    const msg1 = "같이 있으면 서로를 웃게 하고\n함께하는 미래를 꿈꾸게 하는\n소중한 사람을 만났습니다.";
    const msg2 = "둘이서 맞이하는 세 번째 봄날,\n서로의 남은 모든 계절을 함께하려 합니다."
    const msg3 = "우리라는 이름으로 새롭게 시작하는\n첫 순간에 고마운 분들을 초대합니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "황보승국",
            bank: "카카오뱅크",
            accountNumber : "3333-09-1148835",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "황보석",
            bank: "기업은행",
            accountNumber : "099-049153-01-011",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "강순엽",
            bank: "부산은행",
            accountNumber : "015-12-011678-1",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "전지원",
            bank: "카카오뱅크",
            accountNumber : "3333-14-4929884",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "전창환",
            bank: "우체국",
            accountNumber : "610352-02-022141",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "박연경",
            bank: "우체국",
            accountNumber : "610352-02-110121",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "황보승국",
            call: "010-7754-5802"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "황보석",
        //     call: "010-6620-1579"
        // }
        // ,{
        //     role: "신랑 모",
        //     name: "강순엽",
        //     call: "010-7689-7478"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "전지원",
            call: "010-7439-0126"
        }
        // ,{
        //     role: "신부 부",
        //     name: "전창환",
        //     call: "010-9235-8920"
        // },
        // {
        //     role: "신부 모",
        //     name: "박연경",
        //     call: "010-8580-8920"
        // }
    ]

    const interviewContent = [
        {
            question : "서로의 첫인상 기억해?",
            groomAnswer: "4차원소녀🧝🏻‍♀️",
            brideAnswer: "동글왕감자🥔"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "긴장반설렘반",
            brideAnswer: "두근콩닥콩닥"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "옆자리내꺼♥️",
            brideAnswer: "알라뷰쏘마치"
        },
        {
            question : "부부가 된 기념 한마디!",
            groomAnswer: "즐기며살자",
            brideAnswer: "나만따라와"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "모든분들",
            brideAnswer: "행복만가득♥️"
        }
    ]

    const informationList =[
        {
            title : "♥신랑신부가 준비한 포토부스 이벤트♥",
            content : "10시30분-12시 운영합니다! \n많이 참여해주세요\n\n예쁜사진과 축하메시지를 남겨주시고,\n인생사진도 마음껏 가져가세요 :D"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName2 = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(2).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join('').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#DE3C69"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicTitle}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName2(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forParking={forParking} forBus={forBus} forSubway={forSubway} conceptStyle={conceptStyle}/>
                    {/* <Information titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default JeonJiWon;