import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const MoonSoHyun = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "하덕우❤️문소현 결혼합니다.";
    const openDesc = "4월12일 토요일 오후12시30분\n울산 타니베이호텔 소라홀 3층";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/151/thumb.jpg";

    const placeTelNum = "052-201-8001";

    const lng = 129.430720989231;
    const lat = 35.4927214667739;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forTaxi = "- 울산공항: 아산로 경유 15km (20분 소요)\n- 태화강역: 아산로 경유 11km (15분 소요)\n- 울산역(KTX): 울밀로 경유 33km (50분 소요)"
    const forPublic = "- 울산공항: 102, 122\n- 울산역(KTX): 급행5002\n- 태화강역: 124, 133\n- 시외, 고속버스 터미널: 108, 124, 133, 401, 1401"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FE987B 코랄
    // 레몬 옐로우 #EFD140
    // #EB9217
    // 빈티지 옐로우 : #EAC63C

    const conceptStyle = {
        // spring - 블루C
        concept: "SPRING",
        titleColor: "#505d89",
        pointColor: "#505d89",
        btnBgColor: "#8190be",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#f3f6ff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "우리 결혼합니다";

    const msg1 = "따스한 봄날\n평생의 인연이 되는 \n소중한 자리에 여러분을 모시려 합니다.";
    const msg2 = "저희 두 사람이 함께 걸어갈 길에\n따뜻한 축복을 더해주시면 감사하겠습니다."


    const groomAccountList = [
        {
            role: "신랑",
            name: "하덕우",
            bank: "우리은행",
            accountNumber : "1002-062-480890",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "하유수",
            bank: "부산은행",
            accountNumber : "022-12-016226-2",
            isKakao :  false
        }
        // ,{
        //     role: "신랑 모",
        //     name: "이은희",
        //     bank: "농협은행",
        //     accountNumber : "352-0353-6659-13",
        //     isKakao :false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "문소현",
            bank: "국민은행",
            accountNumber : "866001-01-436038",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "배수환 (예금주 :이은미)",
        //     bank: "국민은행",
        //     accountNumber : "165801-04-318838",
        //     isKakao :false
        // }
        ,{
            role: "신부 모",
            name: "변도숙",
            bank: "농협은행",
            accountNumber : "352-0308-5697-13",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "하덕우",
            call: "010-7703-2123"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "송윤식",
        //     call: "010-2744-3513"
        // }
        // ,{
        //     role: "신랑 모",
        //     name: "이은희",
        //     call: "010-3425-3518"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "문소현",
            call: "010-9074-8863"
        }
        // ,{
        //     role: "신부 부",
        //     name: "배수환",
        //     call: "010-8891-5507"
        // },
        // {
        //     role: "신부 모",
        //     name: "이은미",
        //     call: "010-2694-5507"
        // }
    ]

    const interviewContent = [
        {
            question : "서로를 처음 본 순간 든 생각은?",
            groomAnswer: "너무예뻤어",
            brideAnswer: "찾았다내짝"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설레입니다",
            brideAnswer: "두근두근해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑합니다",
            brideAnswer: "나도사랑해"
        },
        {
            question : "설레는 결혼생활 각오 한마디!",
            groomAnswer: "행복합시다",
            brideAnswer: "늘함께하자"
        },
        {
            question : "청첩장을 보고 계신 분들에게 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "감사합니다"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505d89"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} />
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forPublic={forPublic} forTaxi={forTaxi} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default MoonSoHyun;