import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const HwangSuHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김성규❤️황수현 결혼합니다";
    const openDesc = "2025년 5월25일 일요일 낮 12시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/160/thumb.JPG";

    const placeTelNum = "054-536-2005";

    const lng = 128.181728997876;
    const lat = 36.4192912640497;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forCar = "마리앙스 웨딩컨벤션 주차장\n만차 시,태평성대경상감영공원,삼백농업농촌 \n테마공원 주차장 이용";
    const forBus = "상주 고속버스터미널 하차,택시 이용 \n기차 상주역 하차,택시 이용"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FE987B 코랄
    // 레몬 옐로우 #EFD140
    // #EB9217
    // 빈티지 옐로우 : #EAC63C
    // #FC62A4
    // #f5408d
    // #ffb0d2 쿨 연핑크
    // #CB797C
    // #cc5458

    const conceptStyle = {
        // spring 퍼플
        concept: "SPRING",
        titleColor: "#bf9cd8",
        pointColor: "#bf9cd8",
        btnBgColor: "#e4d0f7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#f8f3ff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }



    const infoTitle = "김성규 ♥︎ 황수현 결혼합니다.";

    const msg1 = "2017년 뜨거운 여름날에 만나,\n20대의 전부를 함께한 저희가 \n2025년 따뜻한 봄날 \n부부라는 이름으로 시작해보려 합니다.";
    const msg2 = "소중한 분들을 초대합니다."


    const groomAccountList = [
        {
            role: "신랑",
            name: "김성규",
            bank: "농협은행",
            accountNumber : "302-0448-3014-01",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "김응하",
            bank: "농협은행",
            accountNumber : "351-1340-3268-43",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "이귀숙",
            bank: "농협은행",
            accountNumber : "782-02-081026",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "황수현",
            bank: "국민은행",
            accountNumber : "613-2020-4234-096",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "황광일",
            bank: "농협은행",
            accountNumber : "741091-51-001440",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "오현정",
            bank: "기업은행",
            accountNumber : "010-5547-1364",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김성규",
            call: "010-5053-8903"
        }
        ,{
            role: "신랑 부",
            name: "김응하",
            call: "010-3157-8903"
        }
        ,{
            role: "신랑 모",
            name: "이귀숙",
            call: "010-8580-8903"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "황수현",
            call: "010-5608-9323"
        }
        ,{
            role: "신부 부",
            name: "황광일",
            call: "010-2323-5464"
        },
        {
            role: "신부 모",
            name: "오현정",
            call: "010-5547-1364"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "오.내꺼다",
            brideAnswer: "수줍음 많은 남자🤣..알 수가 없다.."
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "얘랑 할 줄 알았음",
            brideAnswer: "내가 진짜 결혼한다구?🥹"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "경인아 알라뷰",
            brideAnswer: "준호야, 아내 말을 잘 듣자!"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "싸워도 각방은 쓰지말자!",
            brideAnswer: "변화는 있어도 변함없이! 알콩달콩 잘~살자♥"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "결혼하면 행복하다!",
            brideAnswer: "너희도 다 해봐라..😏"
        }
    ]

    const informationList =[
        {
            title : "대절 버스 안내",
            content : "원주>인천 버스대절\n출발지: 원주 따뚜 만남의광장 \n(강원도 원주시 남원로 565)\n\n출발시간: 10시30분"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#bf9cd8"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} />
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter fontSize={"15px"} titleFontSize={"18px"} title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontSize={"15px"} titleFontSize={"18px"} fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList fontSize={"15px"} titleFontSize={"16px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring fontSize={"15px"} titleFontSize={"16px"} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring fontSize={"13px"} titleFontSize={"15px"} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc fontSize={"13px"} titleFontSize={"15px"} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default HwangSuHyeon;