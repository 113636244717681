import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import musicFile from "../../asset/music/winter.mp3"


const KimMinJeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "신충섭 ♥ 김민정 결혼합니다";
    const openDesc = "25.05.17 낮 12시 \n빌라드아모르 르 블루 씨엘 1층";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/146/thumb1.jpg";

    const placeTelNum = "031-638-5222";

    const lng = 127.442556837491;
    const lat = 37.2531310267673;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const forBus = "  · 이천역\n3번,12번,12-1번\n\n  · 이천터미널(SC제일은행 앞 정류장)\n22-3번, 22-9번\n\n증일 2리 입구 하차 > 도보 300m";
    const forCar = "경기 이천시 이섭대천로 921 빌라드아모르 이천 \n(빌라드아모르 지상 주차장 이용)";
    const forParking = "빌라드 아모르 지상 주차장 이용";
    const forSubway = "경강선 이천역에서 하차\n3번, 12번, 12-1번 탑승 후 증일 2리 입구 하차 \n→ 도보 300m";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EFD140
    // #EB9217
    // #ff8faf
    // #E36402
    // #88abd1
    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다.";

    const msg1 = "/ SHUN, 내 꿈은 당신과 나태하게 사는 것";
    const msg2 = "그냥 매일 손 잡고 걸을 수 있는\n여유로운 저녁이 있는 것\n지친 하루의 끝마다 돌아와 꼭 함께하는 것\n잠시 마주앉아 서로 이야기를 들어줄 수 있는 것"


    const groomAccountList = [
        {
            role: "신랑",
            name: "신충섭",
            bank: "신한은행",
            accountNumber : "110-373-589581",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FY8vQ5m2r"
        }
        ,{
            role: "신랑 부",
            name: "신동구",
            bank: "농협은행",
            accountNumber : "301-0099-4399-11",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "이경애",
            bank: "농협은행",
            accountNumber : "233015-51-052450",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김민정",
            bank: "신한은행",
            accountNumber : "110-531-073786",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FbpjwViWr"
        }
        ,{
            role: "신부 부",
            name: "김종호",
            bank: "농협은행",
            accountNumber : "568-01-019151 ",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "윤경혜",
            bank: "농협은행",
            accountNumber : "356-0324-4399-33",
            isKakao : false
        }
    ]


    // const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."
    const account_explain = ""

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "윤병익",
            call: " 010-7199-3936"
        }
        ,{
            role: "신랑 부",
            name: "윤명구",
            call: "010-3218-3936"
        }
        ,{
            role: "신랑 모",
            name: "박용신",
            call: "010-7235-3936"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이나현",
            call: "010-7597-0815"
        }
        ,{
            role: "신부 부",
            name: "이규호",
            call: "010-4254-4815"
        },
        {
            role: "신부 모",
            name: "문정선",
            call: "010-3333-4708"
        }
    ]

    const interviewContent = [
        {
            question : "언제 제일 예뻐?",
            groomAnswer: "자고있을 때",
            brideAnswer: "ㅋㅋㅋㅋㅋ"
        },
        {
            question : "언제 제일 멋져?",
            groomAnswer: "매일 멋있지",
            brideAnswer: "쉬잇! 조용히"
        },
        {
            question : "결혼을 결심한 계기가 뭐야?",
            groomAnswer: "계속 생각나",
            brideAnswer: "나도,그래서"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "좋아! 가보자",
            brideAnswer: "오케 레츠고"
        },
        {
            question : "10년 후 우리는?",
            groomAnswer: "아들,딸 함께",
            brideAnswer: "누구맘대로"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter contentTextAlign={"right"} title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimMinJeong;