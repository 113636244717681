import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import Video from '../../component/Video';


const BaekSeungA = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "승현, 승아 결혼 합니다.";
    const openDesc = "2025년 4월 20일 오후 12시30분\n: 바이더그린 By the green";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/156/thumb.jpg";

    const lng = 126.991385915731;
    const lat = 37.4038340360244;

    const placeTelNum = "0507-1370-3356";

    const forCar = "[네비게이션 '그라운드 333' 검색]\n제2경인고속도로 북의왕 TG → 의왕(포일) 방향으로 \n1분 미만 거리"
    const forParking = "산빛공원 공영주차장 : 경기도 의왕시 이미로 34 \n(도보 5분, 지상 주차비 무료)\n\n* 공영주차장 ↔ 예식장 : 셔틀버스 운행"
    const forPublic = "4호선 인덕원역. 2번 출구\n- 마을버스 12번 '인덕원 IT 밸리' 하차 (도보 3분)\n- 마을버스 13번 '숲속마을 3.5단지' 하차 (도보 10분) ";

    {/* const parents = [
        {groom : "김두희 · 이연숙의 장남"},
        {bride : "❁이석원 · 박영란의 차녀"}
    ] */}

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        // spring - 오렌지
        concept: "SPRING",
        titleColor: "#FFAB35",
        pointColor: "#FFAB35",
        btnBgColor: "#ffcb8d",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5eb",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "저희 결혼합니다.";


    const msg1 = "4월의 어느 봄 날,\n아이처럼 들뜬 마음으로 결혼합니다.\n설레는 우리의 시작에\n소중한 분들과 함께하고 싶습니다.";
    const msg2 = "귀한 시간 내어 함께해 주신다면,\n그 따스한 마음을 오래도록 간직하겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "이승현",
            bank: "국민은행",
            accountNumber : "620-602-01-411380",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "이병갑",
            bank: "농협은행",
            accountNumber : "352-0213-8958-63",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "양재정",
            bank: "기업은행",
            accountNumber : "117-07-1077-03-019",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "백승아",
            bank: "우리은행",
            accountNumber : "1002-343-48-2424",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "백기환",
            bank: "제주은행",
            accountNumber : "180-215-4361",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "이명희",
            bank: "국민은행",
            accountNumber : "703-401-04-188408",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이승현",
            call: "010-5684-8238"
        }
        ,{
            role: "신랑 부",
            name: "이병갑",
            call: "010-5446-9184"
        }
        ,{
            role: "신랑 모",
            name: "양재정",
            call: "010-3770-9184"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "백승아",
            call: "010-4142-4524"
        }
        ,{
            role: "신부 부",
            name: "백기환",
            call: "010-3696-5180"
        },
        {
            role: "신부 모",
            name: "이명희",
            call: "010-9978-5180"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무예뻤어",
            brideAnswer: "어떻게잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무짜릿해",
            brideAnswer: "설레죽겠다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상사랑해",
            brideAnswer: "나도사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "항상잘할게",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해주세요",
            brideAnswer: "많이많이❤️"
        }
    ]

    const informationList =[
        {
            title : "김포공항 대절버스 안내",
            content : "귀한 발걸음을 해주시는 하객분들의 편의를 \n위해 전세버스를 준비하였습니다.\n\n출발 시간 : 25년 4월 20일 \n오전 10시 30분 예정\n(예식장까지 약 1시간 소요)\n\n탑승지 : 김포공항 국내선 주차장\n* 정확한 탑승위치는 추후 문자로 연락 \n드리겠습니다.*"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FFAB35"} loading={loading} />
            ) : (
                <>
                    {/* <Music concept={conceptStyle.concept}/> */}
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <Video width={"100%"}  url={"https://moodsean.s3.ap-northeast-2.amazonaws.com/156/video.mp4"} conceptStyle={conceptStyle}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forPublic={forPublic} forParking={forParking} forCar={forCar} conceptStyle={conceptStyle}/>
                    <Information height={"210px"} divHeight={"340px"} titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} />
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default BaekSeungA;