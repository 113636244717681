import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const LeeSeIn = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "세인♥준영 결혼식에 초대합니다";
    const openDesc = "5월 25일 일요일 오후 1시\n소노캄거제 B3층 그랜드볼룸 홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/135/thumb.JPG";

    const placeTelNum = "055-733-7372";

    const lng = 128.7029253;
    const lat = 34.8434203;

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };


    const forCar = " *서울 - 거제 [경부고속도로 / 대전통영고속도로]\n서울경부고속도로 > 대전통영고속도로 > 통영IC \n> 한화오션 > 소노캄 거제\n\n*부산 - 거제 [가덕TG / 거가대교]\n부산가덕TG > 거가대교 > 한화오션 > 소노캄 거제"
    const forBus = "* 고현터미널에서 오시는 방법\n1) 시내버스: 터미널 내에서 승차, 소동 정류장에서 하차 \n22, 23-1\n2) 택시: 약 20~30분 소요\n\n* 장승포터미널에서 오시는 방법\n1) 시내버스: 터미널 쪽 정류장에서 승차, 소동 정류장에서 \n하차 61, 61-1, 36, 64, 67\n2) 택시: 약 10분 소요";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // ##EC8099
    // ##E3AAB9
    // #ff1493
    // #ff147a
    // #e81589
    //rgb(240, 236, 206)

    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "우리 결혼합니다";

    const msg1 = "따뜻한 햇살과 봄바람이 속삭이는 계절,\n우리는 사랑을 맹세하며 \n하나 되는 길을 걷기로 약속합니다.";
    const msg2 = "행복의 첫걸음을 소중한 기억으로 간직할 수 있도록\n기쁜 마음으로 함께해 주시면 감사하겠습니다."


    const groomAccountList = [
        {
            role: "신랑",
            name: "홍준영",
            bank: "농협은행",
            accountNumber : "302-7540-8111-51",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/FRleI6LCN"
        }
        ,{
            role: "신랑 부",
            name: "홍영석",
            bank: "농협은행",
            accountNumber : "302-0806-8645-31",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "김주해",
            bank: "부산은행",
            accountNumber : "022-12-039820-8",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이세인",
            bank: "농협은행",
            accountNumber : "312-9130-7467-31",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Ej7sbgsHt"
        }
        ,{
            role: "신부 부",
            name: "이정민",
            bank: "농협은행",
            accountNumber : "878-02-125660",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "한임혜",
            bank: "농협은행",
            accountNumber : "351-0113-5033-13",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 가지지 않으셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "홍준영",
            call: "010-7540-8111"
        }
        ,{
            role: "신랑 부",
            name: "홍영석",
            call: "010-5424-8758"
        }
        ,{
            role: "신랑 모",
            name: "김주해",
            call: "010-5624-8758"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이세인",
            call: "010-9130-7467"
        }
        ,{
            role: "신부 부",
            name: "이정민",
            call: "010-9704-7467"
        },
        {
            role: "신부 모",
            name: "한임혜",
            call: "010-6545-7467"
        }
    ]

    const interviewContent = [
        {
            question : "우리를 이어준 한마디는?",
            groomAnswer: "둘이볼까요?",
            brideAnswer: "밥사주세요"
        },
        {
            question : "우리 연애 스타일을 한마디로 표현하면?",
            groomAnswer: "계획,실행해",
            brideAnswer: "투닥꺄르륵"
        },
        {
            question : "결혼을 결심한 이유는?",
            groomAnswer: "예쁜똑똑이",
            brideAnswer: "한결같은너"
        },
        {
            question : "배우자로서 한 가지를 약속한다면?",
            groomAnswer: "나보다너를",
            brideAnswer: "언제나네편"
        },
        {
            question : "평생 함께할 우리만의 규칙을 정하자!",
            groomAnswer: "평생손잡기",
            brideAnswer: "늘손꼭잡기"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forCar={forCar} forBus={forBus}  conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeSeIn;