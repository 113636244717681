import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import Main_Winter from '../../component/Winter/Main_Winter';
import Save_Date_Fall from '../../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../../component/Fall/Info_Fall';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import CallInfo from '../../component/CallInfo';

const AnHyeonJu = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);


    const openTitle = "정우진❤️안현주 결혼합니다.";
    const openDesc = "4월 13일 오후 2시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/152/thumb.jpeg";

    const placeTelNum = "051-711-0177";

    const lng = 129.051025653009;
    const lat = 35.1644055707399;

    const forBus = "· 진구청앞 하차 : 54, 66, 81, 83-1, 133, 63 \n ㅤ[이마트 주차장 입구쪽으로 도보 5분]\n· 진양교차로 하차 : 17, 23, 44, 129-1, 138-1, 141, 160, \n ㅤ167, 169-1 [부암로타리 방면 도보 5분]"
    const forSubway = "1호선, 2호선 서면역 9번출구 [셔틀운행]\n2호선 부암역 4번출구 [도보 10분 및 셔틀이용]";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }


    const parents = [
        {groom : "진용성 · 서윤희의 장남"},
        {bride : "박광익 · 장남옥의 차녀"}
    ]

    const title = "우리 결혼합니다."

    const msg1 = "포근한 봄바람이 불어오는 이 날,\n두 사람이 하나 되어 새로운 길을 시작합니다."
    const msg2 = "꽃이 피어나듯 사랑도 피어난 이 순간을\n소중한 분들과 함께할 수 있다면,\n더없이 큰 기쁨으로 간직하겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "정우진",
            bank: "기업은행",
            accountNumber : "387-057-434-01-011",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FPxdHy9ti"
        }
        ,{
            role: "신랑 부",
            name: "정창호",
            bank: "농협은행",
            accountNumber : "356-1541-7272-93",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "김복경",
            bank: "농협은행",
            accountNumber : "352-4200-3349-13",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "안현주",
            bank: "기업은행",
            accountNumber : "510-074547-01-011",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FM35QlLEn"
        }
        ,{
            role: "신부 부",
            name: "안인성",
            bank: "국민은행",
            accountNumber : "669501-04-291691",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김미정",
            bank: "새마을금고",
            accountNumber : "9003-2936-5841-1",
            isKakao : false
        }
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "정우진",
            call: "010-5467-5127"
        }
        ,
        {
            role: "신랑 부",
            name: "정창호",
            call: "010-3867-5127"
        },
        {
            role: "신랑 모",
            name: "김복경",
            call: "010-2425-3349"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "안현주",
            call: "010-2490-9377"
        }
        ,{
            role: "신부 부",
            name: "안인성",
            call: "010-2324-9377"
        }
        ,{
            role: "신부 모",
            name: "김미정",
            call: "010-7935-9377"
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "지하철 앞",
            brideAnswer: "당연하지!"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "긴장 돼",
            brideAnswer: "두근두근"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "널 사랑해",
            brideAnswer: "사랑해❤️"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "한눈 안 팜",
            brideAnswer: "절대 지켜"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "행복하자",
            brideAnswer: "고마워"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Winter width={"300px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstNameEn(weddingData.brideNameEn)} groomNameEn={getFirstNameEn(weddingData.groomNameEn)} />
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall conceptStyle={conceptStyle} weddingData={weddingData} />
                    <Letter title={title} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring  weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Location_Spring  weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                        <Location_Desc forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                </>
            )}
        </div>
    );
    
};

export default AnHyeonJu;