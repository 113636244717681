import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import AccountList2 from '../../component/Account/AccountList2';

const JeongHaYoon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김현섭 (하트) 정하윤 결혼합니다.";
    const openDesc = "2025년 6월 29일 일요일 오전 11시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/185/thumb1.jpg";

    const lng = 127.049963989803;
    const lat = 37.5043809944112;

    const placeTelNum = "02-501-7000";

    const forCar = "'샹제리제센터' 또는 '르비르모어' 검색\n주말 입차시 발렛파킹이 가능하오니 이용하시기 바랍니다.";
    const forSubway = "지하철 2호선 ▪ 수인분당선 선릉역 1번출구 앞";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 그린
        concept: "SUMMER",
        titleColor: "#1b6612",
        pointColor: "#1b6612",
        btnBgColor: "#6AA152",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const title = "우리 결혼합니다.";

    const msg1 = "장담하건대, 세상이 다 겨울이어도\n우리 사랑은 늘 봄처럼 따뜻하고 \n때론 여름처럼 뜨거울 겁니다.\n앞으로의 날들을 감사로 채우며 살아가겠습니다.";
    const msg3 = "저희의 아름다운 첫 페이지에\n소중한 분들을 초대합니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "김현섭",
            bank: "국민은행",
            accountNumber : "284802-04-175911",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7kOFjmu"
        }
        ,{
            role: "신랑 부",
            name: "김영주",
            bank: "우리은행",
            accountNumber : "150-02-089351",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "강미경",
            bank: "카카오뱅크",
            accountNumber : "3333-19-101-6574",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "정하윤",
            bank: "우리은행",
            accountNumber : "1002-558-601152",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7tSySjG"
        }
        ,{
            role: "신부 부",
            name: "정일후",
            bank: "신한은행",
            accountNumber : "110-412-286957",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "신서연",
            bank: "국민은행",
            accountNumber : "499425-94-110662",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김현섭",
            call: "010-5158-5350"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "차석용",
        //     call: "010-3768-4616"
        // }
        // ,{
        //     role: "신랑 모",
        //     name: "성미자",
        //     call: "010-5260-4616"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "정하윤",
            call: "010-7380-6067"
        }
        // ,{
        //     role: "신부 부",
        //     name: "박정호",
        //     call: "010-8370-9996"
        // }
        // ,{
        //     role: "신부 모",
        //     name: "최경숙",
        //     call: "010-8818-5050"
        // }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "그날못잊어",
            brideAnswer: "첫눈에반함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렘과기대",
            brideAnswer: "너무행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "믿고의지해",
            brideAnswer: "나만바라봐"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "최고의남편",
            brideAnswer: "내조의여왕"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "평생내꺼해",
            brideAnswer: "평생사랑해"
        },
    ]


    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#1b6612"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getFirstEnName(weddingData.brideNameEn)} brideNameEn={getFirstEnName(weddingData.groomNameEn)} color={"#405200"} letteringFontColor={"#405200"} dateColor={"#ffffff"} bottom={"85%"} bottom2={"75.5%"} dateBottom={"1%"} top={"1%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forSubway={forSubway} forCar={forCar} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default JeongHaYoon;