import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import AccountList2 from '../../component/Account/AccountList2';

const YoonSunYoung = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "황민규 ♥️ 윤선영 결혼합니다";
    const openDesc = "6월 8일 오후 2시, 셀럽앤어셈";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/190/thumb.jpg";

    const lng = 127.03455695782222;
    const lat = 37.517527385290705;

    const placeTelNum = "02-545-2222";

    const forBus = "  · 서울세관 정류장 : 간선 141, 직행 3600, 공항 6703\n  · 서울세관사거리 정류장 : 간선 401, 지선 6411, \n지선 8641, 마을08"
    const forSubway = "  · 7호선 학동역 10번 출구 (도보 4분)\n  · 수인분당선 & 7호선 강남구청역 3번 출구 (도보 10분)"
    const forCar = "  · 네비게이션에 '셀럽앤어셈' 또는 '건설회관' 입력\n  · 2시간 무료 주차, 2층 주차 등록 데스크 개별 등록 필요 "

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 와인
        concept: "SUMMER",
        titleColor: "#5b0100",
        pointColor: "#5b0100",
        btnBgColor: "#5b0100",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const title = "저희 결혼합니다.";

    const msg1 = "@민@들레 홀씨처럼 퍼지는 행복을 느끼며,\n@규@정 없이 함께하는 자체로 행복한 사랑을 알게 되었습니다.\n\n@선@물 같았던 우리의 8년,\n@영@원한 사랑으로 이제는 평생을 함께하려 합니다."
    const msg2 = "2025년 6월 8일,\n저희에게 소중한 분들께서 함께해주신다면\n부부로서 새로운 시작이 더욱 빛날 것입니다.";
    
    const groomAccountList = [
        {
            role: "신랑",
            name: "황민규",
            bank: "농협",
            accountNumber : "302-0518-0652-11",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FPoBGDP9j "
        }
        // ,{
        //     role: "신랑 부",
        //     name: "유 연",
        //     bank: "카카오뱅크",
        //     accountNumber : "3333-02-1322446",
        //     isKakao : false
        // }
        ,{
            role: "신랑 모",
            name: "한정혜",
            bank: "농협",
            accountNumber : "019-12-383871",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "윤선영",
            bank: "카카오뱅크",
            accountNumber : "3333-22-7250285",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7jbUFee"
        }
        // ,{
        //     role: "신부 0",
        //     name: "000",
        //     bank: "우리은행",
        //     accountNumber : "1002-835-298137",
        //     isKakao : false
        // }
        ,{
            role: "신부 모",
            name: "이희순",
            bank: "국민은행",
            accountNumber : "502925-94-105164",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜어드리기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "유찬우",
            call: "010-5158-5350"
        }
        ,{
            role: "신랑 부",
            name: "유 연",
            call: "01050006477"
        }
        ,{
            role: "신랑 모",
            name: "정연아",
            call: "01050006477"
        }
    ]


    const brideCallInfoList = [
        {
            role: "신부",
            name: "김연아",
            call: "01036323450"
        }
        ,{
            role: "신부 부",
            name: "김영민",
            call: "01091593450"
        }
        ,{
            role: "신부 모",
            name: "유준경",
            call: "01090153450"
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "그날못잊어",
            brideAnswer: "첫눈에반함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렘과기대",
            brideAnswer: "너무행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "믿고의지해",
            brideAnswer: "나만바라봐"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "최고의남편",
            brideAnswer: "내조의여왕"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "평생내꺼해",
            brideAnswer: "평생사랑해"
        },
    ]


    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#5b0100"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getFirstEnName(weddingData.groomNameEn)} brideNameEn={getFirstEnName(weddingData.brideNameEn)} color={"#ffffff"} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} bottom={"29%"} bottom2={"19.5%"} dateBottom={"7%"} top={"55%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forSubway={forSubway} forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default YoonSunYoung;