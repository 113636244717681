import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const KimDaSeul = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "제수만 ❤️ 김다슬 결혼합니다.";
    const openDesc = "5월 4일 오후 1:30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/184/thumb1.png";

    const lng = 128.625627645429;
    const lat =  35.84759323482;

    const placeTelNum = "053-766-2100";

    const desc = "자가용 이용시 어린이회관 공영 주차장을 이용바랍니다.\n(2시간 무료티켓 증정)";

    const forBus = "어린이세상 앞 100, 234, 814, 8140, 수성1, 급행3\n어린이세상 건너1 100-1, 수성1-1, 급행3\n어린이세상 건너2 204\n대구고용노동청 앞 100-1, 수성1-1\n대구고용노동청 건너 100, 234, 814, 8140, 수성1";
    const forSubway = "어린이세상역\n1번 출구 → 도보 6분\n\n황금역\n1번 출구 → 도보 16분\n\n수성구민운동장역\n1번 출구 → 도보 10분";

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #EFD140
    // #EB9217
    // #E36402
    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
        // 블루A #9FB4E7
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
  //  #E2396A
  // #F77BAD
  // #ff8faf
    // #b681db
    // #FF8A83
        // #ffb0d2 쿨 연핑크
    const conceptStyle = {
        // spring 퍼플
        concept: "SPRING",
        titleColor: "#edacb1",
        pointColor: "#edacb1",
        btnBgColor: "#edacb1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#f8f3ff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "소중한 분들을 초대합니다.";

    const msg1 = "곁에 있을 때 나를 가장 나답게 해주는 사람,\n나를 항상 행복하게 하는 인생의 단짝을 만났습니다."
    const msg2 = "촉촉한 비가 땅을 적시고 \n나무에 새로운 싹을 피우는 봄처럼 \n‘우리’의 ‘봄’을 시작하려 합니다."
    const msg3 = "저희의 첫걸음을 소중한 분들과 함께하고 싶습니다.\n축복해 주시면 큰 기쁨으로 기억하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "제수만",
            bank: "카카오뱅크",
            accountNumber : "3333198855076",
            isKakao : false
        }
        // ,{
        //     role: "신랑 부",
        //     name: "신동구",
        //     bank: "농협은행",
        //     accountNumber : "301-0099-4399-11",
        //     isKakao :  false
        // }
        // ,{
        //     role: "신랑 모",
        //     name: "이경애",
        //     bank: "농협은행",
        //     accountNumber : "233015-51-052450",
        //     isKakao :false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부 부",
            name: "김상화",
            bank: "농협은행",
            accountNumber : "743-02-130881",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "김종호",
        //     bank: "농협은행",
        //     accountNumber : "568-01-019151 ",
        //     isKakao :false
        // }
        ,{
            role: "신부 모",
            name: "최승애",
            bank: "농협은행",
            accountNumber : "352-2129-866613",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "제수만",
            call: "010-7724-9506"
        }
        ,{
            role: "신랑 부",
            name: "제상국",
            call: "010-7556-0515"
        }
        ,{
            role: "신랑 모",
            name: "현금림",
            call: "010-4867-1259"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "김다슬",
            call: "010-6580-6497"
        }
        ,{
            role: "신부 부",
            name: "김상화",
            call: "010-7358-6497"
        },
        {
            role: "신부 모",
            name: "최승애",
            call: "010-4443-6497"
        }
    ]

    const interviewContent = [
        {
            question : "언제 제일 예뻐?",
            groomAnswer: "자고있을 때",
            brideAnswer: "ㅋㅋㅋㅋㅋ"
        },
        {
            question : "언제 제일 멋져?",
            groomAnswer: "매일 멋있지",
            brideAnswer: "쉬잇! 조용히"
        },
        {
            question : "결혼을 결심한 계기가 뭐야?",
            groomAnswer: "계속 생각나",
            brideAnswer: "나도,그래서"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "좋아! 가보자",
            brideAnswer: "오케 레츠고"
        },
        {
            question : "10년 후 우리는?",
            groomAnswer: "아들,딸 함께",
            brideAnswer: "누구맘대로"
        }
    ]


    const informationList =[
        {
            title : "화환 안내",
            content : "예식장 사정상 화환반입이 불가하오니 양해하여 주시기 바랍니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#edacb1"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forSubway={forSubway} forBus={forBus} desc={desc} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimDaSeul;