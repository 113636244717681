import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';
import Interview_Spring from '../component/Spring/Interview_Spring';
import CallInfo from '../component/CallInfo';

const ShinHyunJeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "엄현식♥️신현정 결혼합니다";
    const openDesc = "4월 13일 일요일 오후 3:30";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/115/thumb.jpeg";

    const lng = 126.99654684714;
    const lat = 37.2627596925365;

    const placeTelNum = "0507-1447-5612";

    const forCar = "내비게이션 : 경기도 수원시 권선구 세화로 116 \n(서둔동 389) 또는 메리빌리아 더 프레스티지 웨딩홀 \n입력";
    const forBus = " · 수원역 환승센터 (1승강장) 하차 \n · 평동 동남아파트 하차";
    const forSubway = "지하철1호선, 수인분당선, KTX\n수원역 2층 3번출구\n(도보8분 거리 타임빌라스 수원 (구 롯데몰) 맞은편";
    const forTrain2 = "KTX 및 열차\n수원역 2층 3번출구\n(도보 8분거리 타임빌라스 수원 (구 롯데몰) 맞은편";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 핑크B
        concept: "SUMMER",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ff8faf",
        fontColor: "#fff",
        btnFontColor: "#ffffff",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const msg1 = "특별한 일을 하지 않아도,\n바라만 봐도 마음이 따뜻해지는 사람과\n새롭게 시작하는 따스한 봄날에 결혼합니다.";
    const msg2 = "행복한 첫걸음을 내딛는 이 날,\n귀한 걸음 하시어 축복과 기쁨을 나눠 주시면 \n더없이 큰 기쁨으로 간직하겠습니다."


    const groomAccountList = [
        {
            role: "신랑",
            name: "엄현식",
            bank: "기업은행",
            accountNumber : "01035705714",
            isKakao :false
        },
        {
            role: "신랑 부",
            name: "엄태중",
            bank: "신한은행",
            accountNumber : "501-01-182692",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "차도희",
            bank: "신한은행",
            accountNumber : "110-077-722627",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "신현정",
            bank: "농협은행",
            accountNumber : "356-0554-2576-13",
            isKakao : false
        }
        ,{
            role: "신부 부",
            name: "신경호",
            bank: "기업은행",
            accountNumber : "329-060476-01-011",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김미화",
            bank: "농협은행",
            accountNumber : "352-1141-243063",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무 예뻤어",
            brideAnswer: "잊지못하지"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무 떨려",
            brideAnswer: "실감이 안나"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "많이 사랑해",
            brideAnswer: "엄청 사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "행복 그잡채",
            brideAnswer: "늘 지금처럼"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "아자아자!",
            brideAnswer: "화이팅!"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "문병관",
            call: "01056503499"
        }
        ,
        {
            role: "신랑 부",
            name: "문사권",
            call: "01066360179"
        },
        {
            role: "신랑 모",
            name: "황혜영",
            call: "01065360179"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이하린",
            call: "01021867191"
        }
        ,{
            role: "신부 부",
            name: "이홍강",
            call: "01064699689"
        }
        ,{
            role: "신부 모",
            name: "박윤옥",
            call: "01057734553"
        }
    ]


    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
        // 7FAD2A 그린
        // FA7384 핑크
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer welcomeFontSize={"20px"} topFontSize={"28px"} weddingData={weddingData} color={"#ffffff"} groomNameEn={getFirstEnName(weddingData.groomNameEn)} brideNameEn={getFirstEnName(weddingData.brideNameEn)} letteringFontColor={"#ff8faf"} dateColor={"#ffffff"} bottom={"30%"} bottom2={"20%"} dateBottom={"2%"} top={"3%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"}  fontSize={"13px"}/>
                    <Info_Spring fontSize={"15px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter titleFontSize={"20px"} fontSize={"15px"} title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontSize={"15px"} fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList titleFontSize={"15px"} fontSize={"14px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring fontSize={"15px"} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc titleFontSize={"16px"} fontSize={"13px"} isPoint={true} forTrain2={forTrain2} forSubway={forSubway} forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ShinHyunJeong;