import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import Video from '../../component/Video';


const ChoEunA = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김송헌 ♥︎ 조은아 결혼합니다. ";
    const openDesc = "2025.06.14 토요일 오후 2시\n더메이호텔 2층 마제스틱볼룸홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/167/thumb.jpg";

    const lng = 127.095705650207;
    const lat = 35.8598315367387;

    const placeTelNum = "063-246-5555";

    const forCar = "전주 IC ▶︎ 서부우회도로(10분)\n전주역 ▶︎ 백제대로(15분)\n고속/시외버스터미널 ▶︎ 가리내로(12분)"
    const forBus = "버스(휴비스전주공장 정류장)\n101 103 337 375 380 381 383 385 416 423 424"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 블루
        concept: "SUMMER",
        titleColor: "#505D89",
        pointColor: "#505D89",
        btnBgColor: "#505D89",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const title = "소중한 분들을 초대합니다.";

    const msg1 = "우리가 함께 웃고 울며\n때로는 다름을 마주하며 성장해온 시간은\n서로에게 소중한 추억이 되었고"
    const msg2 = "이제는 그 추억을 바탕으로\n새로운 시작을 하려고 합니다"
    const msg3 = "저희의 시작을 따뜻한 마음으로 축복해주신다면\n그 힘으로 평생을 걸어가겠습니다"


    const parents = [
        {groom : "❁박금주 · 이윤실의 장남"},
        {bride : "이학두 · 최경숙의 차녀"}
    ]

    const groomAccountList = [
        {
            role: "신랑",
            name: "김송헌",
            bank: "하나은행",
            accountNumber : "12191041232707",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "김재영",
            bank: "농협은행",
            accountNumber : "615040-52-059194",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "김정란",
            bank: "농협은행",
            accountNumber : "643-02-001145",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "조은아",
            bank: "농협은행",
            accountNumber : "302-9064-5238-01",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "이학두",
        //     bank: "농협은행",
        //     accountNumber : "356-1432-1406-03",
        //     isKakao : false
        // }
        ,{
            role: "신부 모",
            name: "이예정",
            bank: "농협은행",
            accountNumber : "537-01-070891",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "눈못마주침",
            brideAnswer: "뒷꿈치포기"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "아직잘모름",
            brideAnswer: "청심환어디?"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상웃어줘",
            brideAnswer: "넌내전부야"
        },
        // {
        //     question : "유부남/녀 기념 각오 한마디!",
        //     groomAnswer: "행복 그잡채",
        //     brideAnswer: "늘 지금처럼"
        // },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "고맙습니다",
            brideAnswer: "행복할게요"
        },
    ]

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김송헌",
            call: "010-4126-3520"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "이명원",
        //     call: "010-3584-6149"
        // }
        // ,{
        //     role: "신랑 모",
        //     name: "이윤실",
        //     call: "010-8773-5752"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "조은아",
            call: "010-9064-5238"
        }
        // ,{
        //     role: "신부 부",
        //     name: "이학두",
        //     call: "010-2513-9013"
        // }
        // ,{
        //     role: "신부 모",
        //     name: "최경숙",
        //     call: "010-8818-5050"
        // }
    ]

    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #761B40
        // #423866
        // #d98db9
        // #FF8A83
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#505D89"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} color={"#505D89"} letteringFontColor={"#505D89"} dateColor={"#ffffff"} bottom={"85%"} bottom2={"75.5%"} dateBottom={"1%"} top={"2%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring2  weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <Video width={"100%"}  url={"https://moodsean.s3.ap-northeast-2.amazonaws.com/167/video.mp4"} conceptStyle={conceptStyle}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc isPoint={true} forCar={forCar} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ChoEunA;