import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import winterMusicFile from "../../asset/music/winter.mp3";


const KimNaRa = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "[나라♡용택] 결혼식에 초대합니다.";
    const openDesc = "2025년 05월 31일(토) 11시 \n월드컵컨벤션 임페리얼볼룸홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/130/thumb.jpg";

    const placeTelNum = "02-3152-5900";

    const lng = 126.897273034482;
    const lat = 37.5682884450841;

    const forCar = "[목적지 검색: 상암월드컵컨벤션]\n ·  상세주소: 서울특별시 마포구 월드컵로 240 2층 \nㅤ(성산동 서울월드컵경기장 서측)";
    const forParking = " ·  월드컵경기장 서문 진입 후 서측 1, 2 주차장 이용\n ·  주차 접수대 사전 등록 후 출차 (90분 무료)\n ·  만차 시, 난지천공원 또는 평화의공원 이용 (3시간 무료)\n\n* '상암월드컵컨벤션'으로 검색하시면 정확한 주차장 \nㅤ안내를 받으실 수 있습니다. \nㅤ(홈플러스 주차장은 무료주차 불가합니다.)";
    const forSubway = "[6호선 월드컵경기장역 2번출구]\n ·  월드컵경기장역 2번 출구에 경기장 서측 방향 안내\n  ㅤ배너가 있습니다.";

    // const parents = [
    //     {groom : "명남식 · 故안인순의 아들"},
    //     {bride : "이금하 · 구은숙의 딸"}
    // ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // #7f86a3
        // #EF8DC1
    // #edacb1
    // #ff8faf
    // #e4aced
    // #aa62b5
    // #edacb1
    // #D77599
    // #FF8A83
        // 살구 : #D2AB93
    // 민트 : #95cfc5
    // 소라 : #88abd1
    // 퍼플 : #b681db
    // 토마토 우유 : #db818f
    // 귤 : #f2a052
    // 오렌지 우유 : #f08b69
    // #EFD140
    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "가장 아름다운 봄날에 초대합니다.";

    const msg1 = "함께한 나날이 고맙고 \n함께할 나날이 설레어\n저희 결혼합니다.";
    const msg2 = "서로의 청춘을 함께한 두 사람이\n연인으로 함께한 8번째 봄날, \n남은 수많은 계절을 부부라는 이름으로 함께하려 합니다.";
    const msg3 ="귀한 걸음 하시어 축복해 주시면\n더없는 기쁨으로 간직하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "정용택",
            bank: "우리은행",
            accountNumber : "1002-649-482916",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7rVOsE0"
        }
        ,{
            role: "신랑 부",
            name: "정운태",
            bank: "우리은행",
            accountNumber : "137-120517-01401",
            isKakao :  false
        }
        ,{
            role: "신랑 모",
            name: "김선주",
            bank: "우리은행",
            accountNumber : "1002-629-238757",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김나라",
            bank: "신한은행",
            accountNumber : "110-437-207872",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8fQIFFB"
        }
        ,{
            role: "신부 부",
            name: "김현수",
            bank: "기업은행",
            accountNumber : "054-113812-01-019",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "이화순",
            bank: "국민은행",
            accountNumber : "211001-04-413887",
            isKakao : false
        }
    ]


    const account_explain = "참석이 어려우신 분들을 위해 계좌번호를 기재하였습니다.\n 너른 마음으로 양해 부탁드립니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "이재훈",
            call: "010-3734-0614"
        }
        ,{
            role: "신랑 부",
            name: "이철희",
            call: "010-3546-4403"
        }
        ,{
            role: "신랑 모",
            name: "000",
            call: "010-5532-4402"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "박서원",
            call: "010-8992-6697"
        }
        ,{
            role: "신부 부",
            name: "000",
            call: "010-5464-6854"
        },
        {
            role: "신부 모",
            name: "안양숙",
            call: "010-2580-6854"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "아담하네?",
            brideAnswer: "실물이낫다"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "아직안믿겨",
            brideAnswer: "실감이안나"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "건행하자♡",
            brideAnswer: "행복하자♡"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "나만믿어!",
            brideAnswer: "가보자고!"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "항상고마워",
            brideAnswer: "항상함께해"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={winterMusicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forCar={forCar} forParking={forParking} forSubway={forSubway} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimNaRa;