import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Main_Winter from '../component/Winter/Main_Winter';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import CallInfo from '../component/CallInfo';
import Interview_Spring from '../component/Spring/Interview_Spring';
import AccountList2 from '../component/Account/AccountList2';
import AccountList from '../component/Account/AccountList';
import Information from '../component/Information';
import Main_Spring from '../component/Spring/Main_Spring';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';


const ShinInGyeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "김정태 ♥ 신인경 결혼합니다.";
    const openDesc = "4월 12일 토요일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/88/thumb1.jpg";

    const placeTelNum = "054-852-1004";

    const lng = 128.780488760578
    const lat = 36.5672341325114

    const forShuttleBus = "[가는 편] : 롯데마트 월드컵점\n광주 서구 금화로 240 주차장 P6 구역 (08:30 출발)\n\n [오는 편] : 안동그랜드호텔 주차장 (15:00 출발)";
    const forCar = "네비게이션 '안동그랜드호텔' 입력";
    const forTrain2 = "서울역/청량리역 - 안동역 \n(안동역에서 택시 이용시 25분 소요)";


    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // const conceptStyle = {
    //     // spring 퍼플
    //     concept: "SPRING",
    //     titleColor: "#bf9cd8",
    //     pointColor: "#bf9cd8",
    //     btnBgColor: "#e4d0f7",
    //     fontColor: "#313131",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#f8f3ff",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }

    // #5D67A5
    // 좀쨍한 블루 : #4359AF
    // 살짝 탁한 라임 : #D2CC5A
    // 탁하고 진한 올리브 :   #395E40
    // 어두운 녹색 : #1D501F
    // 검녹색 : #012918
    const conceptStyle = {
        // spring - 어두운 그린
        concept: "SPRING",
        titleColor: "#1D501F",
        pointColor: "#1D501F",
        btnBgColor: "#437545",
        fontColor: "#313131",
        btnFontColor: "#ffffff",
        chatBgColor: "#f3f6ff",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const msg1 = "설레는 첫 만남처럼\n서로를 바라보며 걸어온 시간\n이제 평생을 함께하는\n저희 약속의 자리에\n소중한 분들을 모시고자 합니다.";
    const msg2 = "부디 귀한 시간 내주시어 저희의 앞날을\n축복해 주시면 감사하겠습니다.";

    const groomAccountList = [
        {
            role: "신랑",
            name: "김정태",
            bank: "농협은행",
            accountNumber : "733-12-3965-76 ",
            isKakao : false
        },
        {
            role: "신랑 부",
            name: "김광근",
            bank: "농협은행",
            accountNumber : "707072-52-012779",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "홍필선",
            bank: "카카오뱅크",
            accountNumber : "3333-32-5656811",
            isKakao :false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "신인경",
            bank: "우리은행",
            accountNumber : "1002-061-310977",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "신현호",
            bank: "농협은행",
            accountNumber : "616-12-137427",
            isKakao : false
        },
        {
            role: "신부 모",
            name: "박영미",
            bank: "농협은행",
            accountNumber : "616-12-154158",
            isKakao : false
        }
    ]

    const account_explain = "먼 곳에서 마음 전하고 싶으신 분들을 위해 \n계좌번호를 기재하였습니다";

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "변종욱",
            call: "010-6636-5638"
        },
        {
            role: "신랑 부",
            name: "변민석",
            call: "010-3862-5638"
        },
        {
            role: "신랑 모",
            name: "김은하",
            call: "010-7331-5638"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "배민지",
            call: "010-5430-9890"
        },
        {
            role: "신부 부",
            name: "배종진",
            call: "010-5430-0303"
        },
        {
            role: "신부 모",
            name: "정성례",
            call: "010-3882-3777"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "후련할 거 같아!",
            brideAnswer: "신혼여행❤"
        },
        {
            question : "우리의 결혼에서 가장 중요한 가치는?",
            groomAnswer: "peace",
            brideAnswer: "fidelity"
        },
        {
            question : "우리의 인생의 목표는?",
            groomAnswer: "건강하게 오래 살기",
            brideAnswer: "순간을 즐기며 행복하게"
        },
        {
            question : "10년, 20년 후 우리의 모습은 어떤 모습일까?",
            groomAnswer: "변치 않을거야.",
            brideAnswer: "어른이 될거야."
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑해 영원히.",
            brideAnswer: "덕분에 행복해."
        }
    ]


    const informationList =[
        {
            title : "피로연 안내",
            content : "25년 3월 1일 \n 서귀포시 대정읍 하모리 동성수산"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#1D501F"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.groomNameEn)} brideNameEn={toUpperCaseString(weddingData.brideNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={"저희 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forTrain2={forTrain2} forCar={forCar} forShuttleBus={forShuttleBus} conceptStyle={conceptStyle}/>
                                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default ShinInGyeong;