import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import AccountList2 from '../../component/Account/AccountList2';

const BangSiA = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "영호 💗 시아 결혼합니다!";
    const openDesc = "경기도 수원시 권선구 경수대로 401 루클라비\n10층 라비에벨 홀";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/143/thumb1.jpg";


    const lng = 127.02396988052965;
    const lat = 37.26222290826387;

    const placeTelNum = "031-223-5595";

    const forParking = "·제1주차장 : KT 남수원지 사주차장\n·제2주차장 : 한화생명 주차장\n·제3주차장 : 수원시청 제2부설 주차장\n※ 주차 요원의 안내를 받으세요.";
    const forSubway = "< 수원시청역 5번 출구 >\n도보 이용시 : 5번 출구에서 직진 후 쌍용자동차 앞 \n사거리 횡단보도를 건넌 후 좌측";
    const forBus = "< KT남수원지사 하차 >\n·일반버스 : 81, 300-1\n ·좌석버스 : 300\n\n< 권선초등학교 하차 >\n·일반버스 : 92, 92-1"
    const forShuttleBus = "수원시청역 5번 출구 앞 승차\n(예식 당일 5-7분 간격 운행)"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 올리브
        concept: "SUMMER",
        titleColor: "#405200",
        pointColor: "#405200",
        btnBgColor: "#405200",
        fontColor: "#fff",
        btnFontColor: "#fff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const title = "우리 결혼 합니다.";

    const msg1 = "봄 햇살이 함께 했던 지난날,\n여러 해의 봄날 처럼 \n앞으로도 햇살같은 봄날을 함께 하려 합니다.";
    const msg3 = "부디 귀한 시간 내주시어 \n저희의 봄날에 함께 축복 해주시면 감사하겠습니다.";


    const groomAccountList = [
        {
            role: "신랑",
            name: "정영호",
            bank: "국민은행",
            accountNumber : "567602-01-175-660",
            isKakao : false
        }
        ,{
            role: "신랑 부",
            name: "정지욱",
            bank: "신한은행",
            accountNumber : "110-245-503589",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "강점옥",
            bank: "국민은행",
            accountNumber : "713702-01-098186",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "방시아",
            bank: "국민은행",
            accountNumber : "534201-04-202-759",
            isKakao : false
        }
        // ,{
        //     role: "신부 부",
        //     name: "박정호",
        //     bank: "기업은행",
        //     accountNumber : "545-021051-01-011",
        //     isKakao : true,
        //     kakaoUrl: "https://qr.kakaopay.com/FWIK3cqLA"
        // }
        ,{
            role: "신부 모",
            name: "박미영",
            bank: "기업은행",
            accountNumber : "566-009713-01-012",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "차보람",
            call: "010-4520-4616"
        }
        ,{
            role: "신랑 부",
            name: "차석용",
            call: "010-3768-4616"
        }
        ,{
            role: "신랑 모",
            name: "성미자",
            call: "010-5260-4616"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "박수혜",
            call: "010-9029-4994"
        }
        ,{
            role: "신부 부",
            name: "박정호",
            call: "010-8370-9996"
        }
        // ,{
        //     role: "신부 모",
        //     name: "최경숙",
        //     call: "010-8818-5050"
        // }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "그날못잊어",
            brideAnswer: "첫눈에반함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렘과기대",
            brideAnswer: "너무행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "믿고의지해",
            brideAnswer: "나만바라봐"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "최고의남편",
            brideAnswer: "내조의여왕"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "평생내꺼해",
            brideAnswer: "평생사랑해"
        },
    ]


    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#405200"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getFirstEnName(weddingData.brideNameEn)} brideNameEn={getFirstEnName(weddingData.groomNameEn)} color={"#405200"} letteringFontColor={"#405200"} dateColor={"#ffffff"} bottom={"84%"} bottom2={"74.5%"} dateBottom={"1%"} top={"1%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter title={title} msg1={msg1} msg3={msg3} conceptStyle={conceptStyle} />
                    {/* <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/> */}
                    <Dday_Spring brideName={getFirstKoName(weddingData.brideName)} groomName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"auto"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList2 conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc forSubway={forSubway} forShuttleBus={forShuttleBus} forParking={forParking} forBus={forBus} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default BangSiA;