import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import axios from 'axios';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Summer from '../component/Summer/Main_Summer';
import Save_Date from '../component/Save_Date';
import Info_Spring from '../component/Spring/Info_Spring';

const AnHyeJin = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);
    
    const openTitle = "한세❤️혜진 결혼합니다.";
    const openDesc = "2025년 2월 15일 오후 4시 40분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/27/thumb.jpg";
    
    const placeTelNum = "032-227-5005";

    const forBus = "문학경기장 (박태환 수영장) 38-311, 38-310, 38-329\nB 6, 6-1, 27, 63, 754, 780-2\nG 43(순환), 522, 523\nR 303, 304, 908\n\n문학경기장(야구장) 37-144, 37-157\nB 4, 5, 11, 13, 111-2\nG 515";
    const forSubway = "인천 1호선 문학경기장역 2번 출구 도보 5분(491m)";
    const forParking = "주차비 정산 안내 (차량 번호 등록 필수)\n\n1. 1층에서 차량번호 등록 후 출차해주세요 \n(3시간무료주차)\n\n2.야구 경기 시 주차 선불제로 운영되며 입차시 2000원 \n카드 결제 후 차량번호를 등록하시면 출차시 자동으로 \n결제가 취소됩니다.";
    
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "SUMMER",
        titleColor: "#5EA152",
        pointColor: "#5EA152",
        btnBgColor: "#5EA152",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "이한세",
            bank: "기업은행",
            accountNumber : "381-095545-01-019",
            isKakao :false
        },
        {
            role: "신랑 부",
            name: "이대노",
            bank: "신한은행",
            accountNumber : "110-458-417870",
            isKakao : false
        },
        {
            role: "신랑 모",
            name: "강형숙",
            bank: "농협은행",
            accountNumber : "302-0087-6902-21",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "안혜진",
            bank: "카카오뱅크",
            accountNumber : "3333-01-0755864",
            isKakao : false
        },
        {
            role: "신부 부",
            name: "안대기",
            bank: "우리은행",
            accountNumber : "1002-030-975457",
            isKakao : false
        }
    ]

    const msg1 = "함께한 모든 추억을 \n%한%아름 가득 안고\n온 %세%상에 하얀 눈꽃 흐드러질 무렵 \n다정한 남편, 지%혜%로운 아내로 거듭나\n함께 끝없이 이어%진% 길을 걸어가려 합니다.";
    const msg2 = "부부라는이름으로 하나되는 뜻깊은 날에\n가까이하시어 축복해 주시면 감사하겠습니다.";

    const lng = 126.6916383;
    const lat = 37.4349977;

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        // 핀치 줌 방지 이벤트 등록
        const preventPinchZoom = (e) => {
            e.preventDefault();
        };
        document.addEventListener("gesturestart", preventPinchZoom);
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

        // 성을 제외하고 이름만 추출 ex.Gayeong
        const getFirstEnName = (fullName) => {
            const nameParts = fullName.split(' '); 
            const firstName = nameParts.slice(1).join(''); 
            return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
        };
    
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#5EA152"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} color={"#5EA152"} brideNameEn={getRemoveSpaceName(weddingData.groomNameEn)} groomNameEn={getRemoveSpaceName(weddingData.brideNameEn)} letteringFontColor={"#ffffff"} dateColor={"#5EA152"} top={"0%"} bottom={"25%"} bottom2={"17%"} dateBottom={"5%"} />
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring isTimeKoDetail={true} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={weddingData.brideName} groomNameEn={weddingData.groomName} />     
                    <Letter title={"결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                    <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"4px"}/>
                        <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                            <MotionWrapper initialY={70}>
                                <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                                    conceptStyle={conceptStyle} 
                                    btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                                    />
                                <MotionWrapper initialY={50}>
                                    <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                                    <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                                    <Footer />
                                </MotionWrapper>
                            </MotionWrapper>
                        </MotionWrapper>
                    </MotionWrapper>
                </>
            )}
        </div>
    );
    
};

export default AnHyeJin;