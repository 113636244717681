import React, { useState, useEffect } from 'react';

const ReplyTable = ({replyData}) => {
    const dataArray = replyData && replyData.data ? replyData.data : [];

    return (
        <div style={{textAlign: "center"}}>
        <table border="1" style={{ width: '100%'}}>
            <thead>
            <tr>
            <th style={{fontSize: "12px", width: "45px"}}>이름</th>
            {/* <th>전화번호</th> */}
            <th style={{fontSize: "12px"}}>참석여부</th>
            <th style={{fontSize: "12px"}}>식사여부</th>
            <th style={{fontSize: "12px", width: "45px"}}>참석측</th>
            <th style={{fontSize: "12px"}}>참석인원</th>
            <th style={{fontSize: "12px"}}>답변일자</th>
        </tr>
        </thead>
        <tbody>
            {Array.isArray(dataArray) && dataArray.length > 0 ? (
                dataArray.map((reply, index) => (
                <tr key={index}>
                    <td style={{ width: "50px"}}>{reply.name}</td>
                    {/* <td style={{ textAlign: 'center' }}>{reply.phoneNumber}</td> */}
                    <td style={{ width: "50px" }}>{reply.willAttend === 'Y' ? 'O' : 'X'}</td>
                    <td style={{ }}>{reply.willHaveMeal === 'Y' ? 'O' : 'X'}</td>
                    <td
                        style={{
                        textAlign: 'center',
                        color: reply.weddingPartyRole === 'BRIDE' ? 'pink' : 'skyblue'
                        }}
                    >
                        {reply.weddingPartyRole === 'BRIDE' ? '신부' : '신랑'}
                    </td>
                    <td style={{  }}>{reply.companionCount}</td>
                    <td style={{  }}>
                        {(() => {
                            const date = new Date(reply.createdAt);
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = String(date.getFullYear()).slice(-2);
                            return `${year}.${month}.${day}`;
                        })()}
                    </td>
                </tr>
                ))
            ) : (
                <tr>
                <td colSpan="7">No data available</td>
                </tr>
            )}
            </tbody>
        </table>
        </div>
    );
};

export default ReplyTable;
