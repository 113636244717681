import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import Letter_Pre from '../../component/Spring/Letter_Pre';


const ParkSunYeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김준철 ♥ 박선영 결혼합니다.";
    const openDesc = "2025년 06월 01일 오후 1시 30분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/187/thumb.jpg";

    const lng = 126.826992736525;
    const lat = 37.5672479846037;

    const placeTelNum = "0507-1412-8301";

    const forSubway = "· 9호선: 마곡나루역 1, 2번 출구 방면 진입 통로 연결\n·  공항철도: 9호선 환승역 방향 마곡나루역 1, 2번 출구 \n방면 진입 통로 연결";
    const forCar = "네비게이션: '보타닉파크웨딩 주차장' 또는 '마곡동 760'\n주차장 안내: 지하 3층 ~ 지하 8층 주차 가능"
    const forBus = "마곡나루역 정류장 하차\nN64, 6642, 6645, 6648, 강서05-1, 강서 07"

    {/* const parents = [
        {groom : "김두희 · 이연숙의 장남"},
        {bride : "❁이석원 · 박영란의 차녀"}
    ] */}

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    // const conceptStyle = {
    //     // spring 블루C
    //     concept: "SPRING",
    //     titleColor: "#505D89",
    //     pointColor: "#505D89",
    //     btnBgColor: "#8190be",
    //     fontColor: "#ffffff",
    //     btnFontColor: "#313131",
    //     chatBgColor: "#f0f4e1",
    //     fontFamilyKo: "SCDream3",
    //     fontFamilyEn: "Edensor"
    // }
    // #EFD140
    // #EB9217
    // #ff8faf
    // #E36402
    // #88abd1
    // 연퍼플 #e4aced
    // 빈티지 연퍼플 #bf9cd8
    // 소라 : #88abd1
    // #edacb1
    // #D77599
    // #BC5078
    // #ff8faf
  //  #E2396A
  // #F77BAD
      // #ffb0d2 쿨 연핑크
      // #E44F69
      // #f5336d
    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }

    const infoTitle = "소중한 분들을 초대합니다.";


    const msg1 = "사랑은 소유가 아니라\n동행임을 아는 두 사람은\n잡은 손을 놓지 않되\n함부로 잡아끌지 않을 것이며\n서로의 두 눈을 고요히 바라보아\n말하지 않아도 같은 쪽으로 걸어가리라";
    const msg2 = "- 박미라, <아름다운 날에 부치다> 중에서 -\n\n"
    const msg3 = "오랜 기다림 속에서 저희 두 사람,\n한 마음 되어 참된 사랑의 결실을 맺게 되었습니다.";
    const msg4 = "오셔서 축복해 주시면 큰 기쁨이겠습니다."


    const groomAccountList = [
        {
            role: "신랑",
            name: "김준철",
            bank: "신한은행",
            accountNumber : "110-434-583660",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7rbDgqc"
        }
        ,{
            role: "신랑 부",
            name: "김기선",
            bank: "새마을금고",
            accountNumber : "9002-1453-0182-4",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "박미향",
            bank: "신한은행",
            accountNumber : "110-352-270191",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "박선영",
            bank: "카카오뱅크",
            accountNumber : "3333-06-4633682",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej9GRgq9r"
        }
        ,{
            role: "신부 부",
            name: "박진규",
            bank: "우체국은행",
            accountNumber : "200014-02-620213",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "곽금선",
            bank: "국민은행",
            accountNumber : "682402-01-494132",
            isKakao : false
        }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 가지지 않으셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김준철",
            call: "010-5105-5742"
        }
        ,{
            role: "신랑 부",
            name: "김기선",
            call: "010-9447-5742"
        }
        ,{
            role: "신랑 모",
            name: "박미향",
            call: "010-2778-5742"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "박선영",
            call: "010-4901-9547"
        }
        ,{
            role: "신부 부",
            name: "박진규",
            call: "010-8449-3743"
        },
        {
            role: "신부 모",
            name: "곽금선",
            call: "010-4114-1483"
        }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "너무예뻤어",
            brideAnswer: "어떻게잊어"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "너무짜릿해",
            brideAnswer: "설레죽겠다"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "항상사랑해",
            brideAnswer: "나도사랑해"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "항상잘할게",
            brideAnswer: "행복합시다"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하해주세요",
            brideAnswer: "많이많이❤️"
        }
    ]

    const informationList =[
        {
            title : "♥신랑신부가 준비한 포토부스 이벤트♥",
            content : "10시30분-12시 운영합니다! \n많이 참여해주세요\n\n예쁜사진과 축하메시지를 남겨주시고,\n인생사진도 마음껏 가져가세요 :D"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter_Pre pointColor={"#FF8A83"} title={""} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    <Letter pointColor={"#FF8A83"} title={infoTitle} msg1={msg3} msg2={msg4} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo marginTop={"30px"} conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.brideName)} brideName={getFirstKoName(weddingData.groomName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default ParkSunYeong;