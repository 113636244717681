import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList2 from '../../component/Account/AccountList2';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';
import winterMusicFile from "../../asset/music/winter.mp3";


const YuJinYeong = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "김진모 ♥ 유진영 결혼합니다.";
    const openDesc = "2025년 6월 1일 일요일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/158/thumb1.jpg";


    const lng = 127.064839277236;
    const lat = 37.5153282685328;

    const placeTelNum = "02-540-0711";

    const forCar = "  ·   경부고속도로\n반포IC 종합운동장 방향으로 직진, 교보생명 > 차병원 \n> 봉은사 > 아셈타워 직진 > 노블발렌티 삼성점\n\n  ·  서울 서부, 강북지역\n올림픽대로 영동대교 남단 입체 교차로에서 무역교역센터 \n방향, 홍실 아파트 > 아셈타워 앞 봉은사 사거리에서 \n좌회전 > 노블발렌티 삼성점\n\n  ·  서울 동남부 지역\n2호선 삼성역 무역센터 교차로에서 영동대교 방향, \n아셈타워 앞 봉은사 사거리에서 우회전 \n> 노블발렌티 삼성점";
    const forBus = "  ·  봉은사역 3번 출구, 삼성 1파출소 \n(하차 정류소 번호 : 23192)\n간선버스 : 143, 146, 301, 343, 345, 401\n지선버스 : 2413, 2415, 3217, 3414, 4318\n직행버스 : 9407, 9507, 9607"
    const forSubway = "  ·   지하철 9호선 봉은사역 4번 출구 도보 약 3분\n  ·   지하철 2호선 삼성역 7번 출구\n(버스 강남01, 3417번 버스로 환승하여 노블발렌티 \n정류장 하차)"
    const forParking = "· 노블발렌티 삼성 본관 주차장, 제2주차구역(노블발렌티 \n삼성 도보 4분 소요, 본관 앞 주차 안내 직원 대기 중)\n· 주차 2시간 무료"

    const parents = [
        {groom : "김선기 · 김혜승의 아들"},
        {bride : "유영무 · 김혜란의 딸"}
    ] 

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        // 핑크A
        concept: "SPRING",
        titleColor: "#FF8A83",
        pointColor: "#FF8A83",
        btnBgColor: "#FFE8E7",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fefaf9",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }

    const infoTitle = "저희, 결혼합니다.";

    const msg1 = "꽃 피는 봄 처음 만나 많은 계절 함께 건너\n어느덧 일곱 번째 여름,\n이제는 가족이라는 이름으로 함께하려 합니다.";
    const msg2 = "소중한 순간, 함께해 주시면 감사하겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "김진모",
            bank: "하나은행",
            accountNumber : "599-910004-02005",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej76bfdSl"
        }
        ,{
            role: "신랑 부",
            name: "김선기",
            bank: "국민은행",
            accountNumber : " 221-21-0595-653",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "김혜승",
            bank: "농협은행",
            accountNumber : "302-0472-4675-51",
            isKakao : false
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "유진영",
            bank: "농협은행",
            accountNumber : "356-0951-9828-73",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8CMoDXD"
        }
        ,{
            role: "신부 부",
            name: "유영무",
            bank: "농협은행",
            accountNumber : "421014-52-064579",
            isKakao : false
        }
        ,{
            role: "신부 모",
            name: "김혜란",
            bank: "농협은행",
            accountNumber : "421014-52-130656",
            isKakao : false
        }
    ]


    const account_explain = ""

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "김진모",
            call: "010-5121-7342"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "조진기",
        //     call: "010-4880-3897"
        // }
        // ,{
        //     role: "신랑 모",
        //     name: "차영애",
        //     call: "010-8557-3897"
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "유진영",
            call: "010-2586-0473"
        }
        // ,{
        //     role: "신부 부",
        //     name: "이태영",
        //     call: "010-9333-2361"
        // },
        // {
        //     role: "신부 모",
        //     name: "석현주",
        //     call: "010-4382-1025"
        // }
    ]

    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "예쁘고친절",
            brideAnswer: "성실끝판왕"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "새로운시작",
            brideAnswer: "너무떨려용"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "나만믿어요",
            brideAnswer: "우린하나야"
        }
        ,{
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "나는야가장",
            brideAnswer: "현명한아내"
        }
        ,{
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "축하와격려",
            brideAnswer: "감사합니당"
        }
    ]

    const informationList =[
        {
            title : "♥신랑신부가 준비한 포토부스 이벤트♥",
            content : "10시30분-12시 운영합니다! \n많이 참여해주세요\n\n예쁜사진과 축하메시지를 남겨주시고,\n인생사진도 마음껏 가져가세요 :D"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: parseInt(m, 10),  // "06" -> 6
                        date: parseInt(d, 10),   // "01" -> 1
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join('').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#FF8A83"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={winterMusicFile}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"150px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} />
                            <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forParking={forParking} forSubway={forSubway} forBus={forBus} forCar={forCar} conceptStyle={conceptStyle}/>
                    {/* <Information titleFontSize={"15px"} pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default YuJinYeong;