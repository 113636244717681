import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../component/MotionWrapper';
import Save_Date_Fall from '../component/Fall/Save_Date_Fall';
import axios from 'axios';
import Info_Fall from '../component/Fall/Info_Fall';
import Letter from '../component/Spring/Letter';
import Dday_Spring from '../component/Spring/Dday_Spring';
import Message_Spring from '../component/Spring/Message_Spring';
import Location_Spring from '../component/Spring/Location_Spring';
import Footer from '../component/Footer';
import Loading from '../component/Loading';
import Location_Desc from '../component/Location/Location_Desc';
import Music from '../component/Music';
import AccountList from '../component/Account/AccountList';
import Sharing from '../component/Sharing';
import GalleryList from '../component/Gallery/GalleryList';
import Main_Fall from '../component/Fall/Main_Fall';
import CallInfo from '../component/CallInfo';
import musicFile from '../asset/music/소수빈-01-그대라는 선물.mp3'
import ParentInfo from '../component/Info/ParentInfo';

const ChoiJiHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "박범진 ♥ 최지현 결혼합니다";
    const openDesc = "4월 20일 오후 2시 50분";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/117/thumb.jpeg";
    
    // const parents = [
    //     {groom : "박삼용의 아들"},
    //     {bride : "최재선 · 김연순의 손녀"}
    // ] 

    const placeTelNum = "042-866-5100";

    const lng = 127.392987;
    const lat = 36.3768891;

    const forCar = "네비게이션 이용 시 해당 주소로 검색하시기 바랍니다. \n- 대전광역시 유성구 엑스포로123번길 55 (도룡동)";
    const forBus = "[대전 시내버스 이용 시]\n· 간선버스 707(대전역) → 스마트시티 2단지 정류장 하차\n· 지선버스 606(대전역) → 간선버스 618(대전예술의전당) \n • → 스마트시티5단지 정류장 하차\n· 지선버스 606(대전역) → 지선버스 911(수정타운아파트\n •정문) → 스마트시티5단지 정류장 하차\n· 지선버스 606(대전역) → 지선버스 121(국립중앙과학관) \n •→ MBC/TJB방송국 정류장 하차";
    const forSubway = "[대전 지하철 이용 시]\n대전 1호선 대전역 → 간선버스 618(정부청사역) \n → 스마트시티5단지 정류장 하차";

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });

    const conceptStyle = {
        concept: "WINTER",
        titleColor: "#000000",
        pointColor: "#000000",
        btnBgColor: "#000000",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F4F4F4",
        fontFamilyKo: "JejuMyeongjo",
        fontFamilyEn: "Milchella"
    }


    const groomAccountList = [
        {
            role: "신랑",
            name: "박범진",
            bank: "우리은행",
            accountNumber : "1002-556-895929",
            isKakao : true,
            kakakoUrl : "https://qr.kakaopay.com/FD8uLwJsW"
        }
        ,{
            role: "신랑 부",
            name: "박삼용",
            bank: "농협은행",
            accountNumber : "56001006066",
            isKakao : false
        },
        // {
        //     role: "신랑 모",
        //     name: "안은미",
        //     bank: "국민은행",
        //     accountNumber : "294-21-0446-056",
        //     isKakao : false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "최지현",
            bank: "기업은행",
            accountNumber : "606-056763-01-017",
            isKakao : true,
            kakaoUrl: "https://qr.kakaopay.com/Fc20HqIQX"
        }
        ,{
            role: "신부 조부모",
            name: "최재선·김연순",
            bank: "신협",
            accountNumber : "132-015-263470",
            isKakao : false
        }
        // {
        //     role: "신부 모",
        //     name: "김영자",
        //     bank: "농협은행",
        //     accountNumber : "356-0555-5913-13",
        //     isKakao : false
        // }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "최지현",
            call: "010-3916-8214"
        }
        // ,{
        //     role: "신부 부",
        //     name: "고제근",
        //     call: "010-5486-2180"
        // },
        // {
        //     role: "신부 모",
        //     name: "김영자",
        //     call: "010-4211-7796"
        // }
    ]


    const groomCallInfoList = [
        {
            role: "신랑",
            name: "박범진",
            call: "010-6261-8292"
        }
        // ,{
        //     role: "신랑 부",
        //     name: "박홍숙",
        //     call: "010-6361-4000"
        // },
        // {
        //     role: "신랑 모",
        //     name: "안은미",
        //     call: "010-6456-4307"
        // }
    ]

    const parentInfo = {
        groom: {
            dad: { name: "박삼용", isDeceased: false },
            // mom: { name: "", isDeceased: false },
            role: "장남",
        },
        bride: {
            dad: { name: "최명성", isDeceased: false },
            // mom: { name: "", isDeceased: false },
            role: "장녀",
        }
    };

    const msg1 = "벚꽃잎 날리는 봄날, \n 저희 두 사람이 \n 서로의 마음에 깊은 사랑의 언약을 하려 합니다.";
    const msg2 = "기쁨이든 슬픔이든 \n 평생을 함께하며 나눌 소중한 사랑 \n 축복과 격려주시면 더 없는 기쁨이 되겠습니다.";

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // Park Seo Young -> Park Seo young
    const toLowerCaseString = (str) => {
        const parts = str.split(" ");
        if (parts.length < 2) return str; // 성과 이름이 없으면 그대로 반환

        // 첫 단어(성)는 그대로 유지
        const firstPart = parts[0];

        // 두 번째 단어 이후로 처리
        const restParts = parts.slice(1).map((part, index) =>
            index === 0
                ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() // 첫 단어만 첫 글자 대문자
                : part.toLowerCase() // 나머지는 소문자로 변환
        );

        return `${firstPart} ${restParts.join(" ")}`;
    };
    

    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#000000"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept} musicTitle={musicFile}/>
                    <Main_Fall brideNameEn={getFirstNameEn(weddingData.groomNameEn)} groomNameEn={getFirstNameEn(weddingData.brideNameEn)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <Save_Date_Fall weddingData={weddingData} color={conceptStyle.titleColor} />
                    <Info_Fall weddingData={weddingData} conceptStyle={conceptStyle} />
                    <Letter title={"우리 결혼합니다."} msg1={msg1} msg2={msg2} conceptStyle={conceptStyle} />
                    <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper>
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring isAttendFromClose={true} groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} hotoHeight={"235px"} gap={"4px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    <MotionWrapper initialY={50}>
                        <Location_Spring weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                        <Location_Desc forCar={forCar} forSubway={forSubway} forBus={forBus} conceptStyle={conceptStyle}/>
                        <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                        <Footer />
                </>
            )}
        </div>
    );
    
};

export default ChoiJiHyeon;