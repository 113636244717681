import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import CallInfo from '../../component/CallInfo';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import AccountList from '../../component/Account/AccountList';
import Information from '../../component/Information';
import Main_Spring from '../../component/Spring/Main_Spring';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Info_Spring2 from '../../component/Spring/Info_Spring2';
import ParentInfo from '../../component/Info/ParentInfo';


const LeeNaHyeon = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "윤병익🖤이나현 결혼합니다.";
    const openDesc = "4월 20일 오후 1시";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/124/thumb.jpeg";

    const placeTelNum = "02-2276-3000";

    const lng = 127.008743829151;
    const lat = 37.5704485321397;

    const parents = [
        {groom : "윤명구 · 박용신의 장남"},
        {bride : "이규호 · 문정선의 장녀"}
    ]

    // const parentInfo = {
    //     groom: {
    //         dad: { name: "명남식", isDeceased: false },
    //         mom: { name: "안인순", isDeceased: true },
    //         role: "아들",
    //     },
    //     bride: {
    //         dad: { name: "이금하", isDeceased: false },
    //         mom: { name: "구은숙", isDeceased: false },
    //         role: "딸",
    //     }
    // };


    const forBus = "종로 6가 정류장 하차 후 도보 10분 거리\n\n · 초록 7212 \n· 빨강 9301\n· 파랑 101, 103, 152, 201, 260, 262, 270, 271, 370, \n720, 721\n"
    const forSubway = "· 1, 4호선 동대문역 9번 출구 (호텔 지하층과 연결) \n· 2, 4, 5호선 동대문역사문화공원역 14번 출구 \n도보 10분 거리 "
    const forParking = "호텔 주차장 이용"

    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // spring 핑크B
        concept: "SPRING",
        titleColor: "#ff8faf",
        pointColor: "#ff8faf",
        btnBgColor: "#ffcfe1",
        fontColor: "#313131",
        btnFontColor: "#313131",
        chatBgColor: "#fff5f9",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
    }


    const infoTitle = "우리 결혼합니다.";

    // const msg1 = "윤: 윤택한 사랑으로 가득 채운 오늘,\n병: 병풍처럼 둘러싼 축복 속에,\n익: 익어가는 두 사람의 행복을 축하해주세요.";
    // const msg2 = "이: 이제부터 둘이 함께 걸어갈 길,\n나: 나누는 사랑이 더욱 깊어지고,\n현: 현실보다 더 아름다운 미래를 만들어가겠습니다."
    const msg3 = "@윤@택한 사랑으로 가득 채운 오늘,\n@병@풍처럼 둘러싼 축복 속에,\n@익@어가는 두 사람의 행복을 축하해주세요.";
    const msg4 = "@이@제부터 둘이 함께 걸어갈 길,\n@나@누는 사랑이 더욱 깊어지고,\n@현@실보다 더 아름다운 미래를 만들어가겠습니다."

    const groomAccountList = [
        {
            role: "신랑",
            name: "윤병익",
            bank: "국민은행",
            accountNumber : "406601-04-466940",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej7oGJ5W5"
        }
        ,{
            role: "신랑 부",
            name: "윤명구",
            bank: "농협은행",
            accountNumber : "356-0408-5041-33",
            isKakao :  false
        }
        // ,{
        //     role: "신랑 모",
        //     name: "박경자",
        //     bank: "농협은행",
        //     accountNumber : "211028-52-399630",
        //     isKakao :false
        // }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "이나현",
            bank: "국민은행",
            accountNumber : "016702-04-589933",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FeVi1m7E6"
        }
        ,{
            role: "신부 부",
            name: "이규호",
            bank: "국민은행",
            accountNumber : "072-21-0803-197",
            isKakao :false
        }
        ,{
            role: "신부 모",
            name: "문정선",
            bank: "국민은행",
            accountNumber : "824002-04-366755",
            isKakao : false
        }
    ]


    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "윤병익",
            call: " 010-7199-3936"
        }
        ,{
            role: "신랑 부",
            name: "윤명구",
            call: "010-3218-3936"
        }
        ,{
            role: "신랑 모",
            name: "박용신",
            call: "010-7235-3936"
        }
    ]

    const brideCallInfoList = [
        {
            role: "신부",
            name: "이나현",
            call: "010-7597-0815"
        }
        ,{
            role: "신부 부",
            name: "이규호",
            call: "010-4254-4815"
        },
        {
            role: "신부 모",
            name: "문정선",
            call: "010-3333-4708"
        }
    ]

    const interviewContent = [
        {
            question : "서로를 처음 본 순간 든 생각은?",
            groomAnswer: "너무예뻤어",
            brideAnswer: "찾았다내짝"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설레입니다",
            brideAnswer: "두근두근해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "사랑합니다",
            brideAnswer: "나도사랑해"
        },
        {
            question : "설레는 결혼생활 각오 한마디!",
            groomAnswer: "행복합시다",
            brideAnswer: "늘함께하자"
        },
        {
            question : "청첩장을 보고 계신 분들에게 전하고 싶은 이야기는?",
            groomAnswer: "축하해줘요",
            brideAnswer: "감사합니다"
        }
    ]


    const informationList =[
        {
            title : "신부측 피로연 안내",
            content : "25년 3월 28일 오전 11:00 ~ 오후 2:00 \n 의성중부농협 2층"
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  // Call once on mount
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    const toUpperCaseString = (str) => {
        const nameParts = str.split(' '); 
        return nameParts.slice(1).join(' ').toUpperCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };


    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#ff8faf"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Spring groomNameEn={toUpperCaseString(weddingData.brideNameEn)} brideNameEn={toUpperCaseString(weddingData.groomNameEn)} weddingData={weddingData} color={conceptStyle.pointColor}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={false} marginTop={"100px"} marginBottom={"0px"} fontSize={"16px"}/>
                    <Info_Spring2 parents={parents} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>     
                    <Letter title={infoTitle} msg1={msg3} msg2={msg4} conceptStyle={conceptStyle}/>
                    {/* <MotionWrapper initialY={70}>
                        <ParentInfo conceptStyle={conceptStyle} parentInfo={parentInfo} weddingData={weddingData}/>
                    </MotionWrapper> */}
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring groomName={getFirstKoName(weddingData.groomName)} brideName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={6} photoWidth={"153px"} photoHeight={"235px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>    
                    <MotionWrapper initialY={70}>
                        <Message_Spring weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>    
                    <MotionWrapper initialY={50}>
                            {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                            <Location_Spring level={2} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>    
                    <Location_Desc forSubway={forSubway} forBus={forBus} forParking={forParking} conceptStyle={conceptStyle}/>
                    {/* <Information pointFontColor={"#9b111e"} conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default LeeNaHyeon;