import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MotionWrapper from '../../component/MotionWrapper';
import axios from 'axios';
import Letter from '../../component/Spring/Letter';
import Dday_Spring from '../../component/Spring/Dday_Spring';
import Message_Spring from '../../component/Spring/Message_Spring';
import Location_Spring from '../../component/Spring/Location_Spring';
import Footer from '../../component/Footer';
import Loading from '../../component/Loading';
import Location_Desc from '../../component/Location/Location_Desc';
import Music from '../../component/Music';
import AccountList from '../../component/Account/AccountList';
import Sharing from '../../component/Sharing';
import GalleryList from '../../component/Gallery/GalleryList';
import Main_Summer from '../../component/Summer/Main_Summer';
import Save_Date from '../../component/Save_Date';
import Info_Spring from '../../component/Spring/Info_Spring';
import Interview_Spring from '../../component/Spring/Interview_Spring';
import CallInfo from '../../component/CallInfo';
import Information from '../../component/Information';
import AccountList2 from '../../component/Account/AccountList2';

const KimYeonA = () => {
    const navigate = useNavigate(); 
    const { mainPage } = useParams();

    const API_URL = process.env.REACT_APP_API_URL;

    const [loading, setLoading] = useState(true);
    const [fontsLoaded, setFontsLoaded] = useState(false);

    const openTitle = "유찬우 ♥ 김연아 결혼합니다.";
    const openDesc = "2025년 5월 23일 금요일 18시 30분 더라움 그라스가든 2층";
    const openThumb = "https://moodsean.s3.ap-northeast-2.amazonaws.com/186/thumb.jpg";

    const lng = 127.039775356327;
    const lat = 37.5083912736997;

    const placeTelNum = "025383366";

    const forBus = " · 3412, 6411 아크로힐스 논현 하차 \n · 141, 242, 361, 6411 라움아트센터, \n시티프라디움더강남 하차";
    const forSubway = " · 분당선•9호선 선정릉역 4번 출구 - 도보 5분 거리 \n · 9호선 언주역 5번 출구 - 도보 9분 거리";
    const forParking = "2시간반 무료, 이후 10분당 2000원"
    const forShuttleBus = " · [선릉역 5번 출구 ▶ 라움] \n예식 시작 1시간 전부터 운행\n\n · [라움 ▶ 선릉역 5번 출구] \n예식 종료 1시간 후까지 운행"
    
    const [weddingData, setWeddingData] = useState({
        id:'',
        concept: '',
        weddingDate: '',
        invitationInfoId: '',
        brideName: '',
        groomName: '',
        brideNameEn: '',
        groomNameEn: '',
        year:'',
        month:'',
        date:'',
        dayOfWeek:'',
        time:'',
        place:'',
        address:'',
        mainImg:'',
        subImg:'',
        pointColor: '',
        btnFontColor: '',
        btnBgColor: '',
        isGuestBookOpen: ''
    });


    const conceptStyle = {
        // summer 브라운
        concept: "SUMMER",
        titleColor: "#693818",
        pointColor: "#693818",
        btnBgColor: "#693818",
        fontColor: "#ffffff",
        btnFontColor: "#ffffff",
        chatBgColor: "#F5F8FF",
        fontFamilyKo: "SCDream3",
        fontFamilyEn: "Edensor"
}

    const title = "우리 결혼합니다.";

    const msg1 = "다른 공간, 다른 시간을 걷던 두 사람이\n서로를 마주한 이후 같은 공간, \n같은 시간을 꿈꾸며 걷게 되었습니다."
    const msg2 = "소박하지만 단단하고 따뜻한 믿음의 가정을 이뤄가겠습니다."
    const msg3 = "오셔서 첫날의 기쁨과 설렘을 함께 해 주시고 축복해 주세요.";
    
    const groomAccountList = [
        {
            role: "신랑",
            name: "유찬우",
            bank: "신한은행",
            accountNumber : "110-419-603716",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FPjBGt54C"
        }
        ,{
            role: "신랑 부",
            name: "유 연",
            bank: "카카오뱅크",
            accountNumber : "3333-02-1322446",
            isKakao : false
        }
        ,{
            role: "신랑 모",
            name: "정연아",
            bank: "농협은행",
            accountNumber : "302-0858-9880-71",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/Ej8Gkru2d"
        }
    ]

    const brideAccountList = [
        {
            role: "신부",
            name: "김연아",
            bank: "우리은행",
            accountNumber : "1002-547-208408",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FKOLJ3rVy"
        }
        ,{
            role: "신부 부",
            name: "김영민",
            bank: "우리은행",
            accountNumber : "1002-835-298137",
            isKakao : true,
            kakaoUrl : "https://qr.kakaopay.com/FM6posn2Q"
        }
        // ,{
        //     role: "신부 모",
        //     name: "신서연",
        //     bank: "국민은행",
        //     accountNumber : "499425-94-110662",
        //     isKakao : false
        // }
    ]

    const account_explain = "따로 계좌번호를 여쭤보시는 수고를 덜기 위해 기입합니다. \n부담 안가지셨으면 좋겠습니다."

    const groomCallInfoList = [
        {
            role: "신랑",
            name: "유찬우",
            call: "010-9669-0041"
        }
        ,{
            role: "신랑 부",
            name: "유 연",
            call: "010-5000-6477"
        }
        ,{
            role: "신랑 모",
            name: "정연아",
            call: "010-2281-0041"
        }
    ]


    const brideCallInfoList = [
        {
            role: "신부",
            name: "김연아",
            call: "010-3632-3450"
        }
        ,{
            role: "신부 부",
            name: "김영민",
            call: "010-9159-3450"
        }
        ,{
            role: "신부 모",
            name: "유준경",
            call: "010-9015-3450"
        }
    ]


    const interviewContent = [
        {
            question : "우리의 설레였던 첫 만남 기억해?",
            groomAnswer: "그날못잊어",
            brideAnswer: "첫눈에반함"
        },
        {
            question : "결혼을 앞둔 소감이 어때?",
            groomAnswer: "설렘과기대",
            brideAnswer: "너무행복해"
        },
        {
            question : "서로에게 하고 싶은 말은?",
            groomAnswer: "믿고의지해",
            brideAnswer: "나만바라봐"
        },
        {
            question : "유부남/녀 기념 각오 한마디!",
            groomAnswer: "최고의남편",
            brideAnswer: "내조의여왕"
        },
        {
            question : "마지막으로 전하고 싶은 이야기는?",
            groomAnswer: "평생내꺼해",
            brideAnswer: "평생사랑해"
        },
    ]


    const informationList =[
        {
            title : "ATM 기기 안내",
            content : "주변엔 ATM기기가 없습니다. \n이점 양해 부탁드립니다."
        }
    ]

    const fetchInvitationInfoData = async () => {
        try {
            const response = await axios.get(`${API_URL}/_api/v1/invitation_info/${mainPage}`);
            if(response.status === 200){
                const [y, m, d] = response.data.data.weddingDate.split('-');

                setWeddingData(prevData => {
                    const newData = {
                        id: response.data.data.id,
                        concept: response.data.data.concept,
                        weddingDate: response.data.data.weddingDate,
                        invitationInfoId: response.data.data.id,
                        brideName: response.data.data.brideNameKo,
                        groomName: response.data.data.groomNameKo,
                        brideNameEn: response.data.data.brideNameEn,
                        groomNameEn: response.data.data.groomNameEn,
                        year: y,
                        month: m,
                        date: d,
                        dayOfWeek: response.data.data.dayOfWeek,
                        time: response.data.data.time,
                        place: response.data.data.place,
                        address: response.data.data.address,
                        mainImg: response.data.data.mainImg,
                        subImg: response.data.data.subImg,
                        pointColor: response.data.data.pointColor,
                        btnBgColor: response.data.data.btnBgColor,
                        btnFontColor: response.data.data.btnFontColor,
                        isGuestBookOpen: response.data.data.isGuestBookOpen
                    };
                    return newData;
                });
            } 
        } catch (error) {
            console.error("API 요청 실패:", error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const fetchDataSequentially = async () => {
            if (!weddingData.weddingDate) {  
                await fetchInvitationInfoData();
            }
        };
        fetchDataSequentially();  
    
        document.body.style.overflow = 'hidden';
    
        const timer = setTimeout(() => {
            document.body.style.overflow = '';
        }, 3000);
    
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = '';
        };
    }, []);

    useEffect(() => {
        const fontLoadObserver = async () => {
            await document.fonts.ready; 
            setFontsLoaded(true); 
            setLoading(false);
        };
        if(!weddingData.weddingDate){
            fontLoadObserver();
        }
    }, []);

    const goToGuestBook = () => {
        navigate(`/${mainPage}/guest`); 
    };

    // 성을 제외하고 이름만 추출 ex. 가영
    const getFirstKoName = (fullName) => {
        const nameParts = fullName.split(''); 
        return nameParts.slice(1).join(''); 
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstNameEn = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };

    // ex. Kim Gayeong
    const getRemoveSpaceName = (fullName) => {
        const nameParts = fullName.split(' '); // 문자열을 띄어쓰기로 분리
        const firstName = nameParts[0]; // 첫 번째 단어 유지
        const restName = nameParts.slice(1).join(''); // 나머지 단어를 소문자로 변경하고 결합
        return firstName + ' ' + restName.charAt(0).toUpperCase() + restName.slice(1).toLowerCase();
    };

    // 성을 제외하고 이름만 추출 ex.Gayeong
    const getFirstEnName = (fullName) => {
        const nameParts = fullName.split(' '); 
        const firstName = nameParts.slice(1).join(''); 
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
    };
        
        // 브라운 #5b0100
        // #582F13
        // #423866
    return (
        <div>
            {loading || !fontsLoaded ? (
                <Loading color={"#693818"} loading={loading} />
            ) : (
                <>
                    <Music concept={conceptStyle.concept}/>
                    <Main_Summer weddingData={weddingData} groomNameEn={getFirstEnName(weddingData.brideNameEn)} brideNameEn={getFirstEnName(weddingData.groomNameEn)} color={"#ffffff"} letteringFontColor={"#ffffff"} dateColor={"#ffffff"} bottom={"25%"} bottom2={"15.5%"} dateBottom={"2%"} top={"58%"}/>
                    <Save_Date color={conceptStyle.pointColor} isBorder={true} marginTop={"100px"} marginBottom={"10px"} fontSize={"13px"} />
                    <Info_Spring fontSize={"14px"} weddingData={weddingData} conceptStyle={conceptStyle} brideNameEn={getFirstEnName(weddingData.brideNameEn)} groomNameEn={getFirstEnName(weddingData.groomNameEn)}/>
                    <Letter fontSize={"14px"} titleFontSize={"20px"} title={title} msg1={msg1} msg2={msg2} msg3={msg3} conceptStyle={conceptStyle} />
                    <CallInfo conceptStyle={conceptStyle} groomCallInfoList={groomCallInfoList} brideCallInfoList={brideCallInfoList}/>
                    <Dday_Spring brideName={getFirstKoName(weddingData.groomName)} groomName={getFirstKoName(weddingData.brideName)} weddingData={weddingData} conceptStyle={conceptStyle}/>
                    <MotionWrapper initialY={50}>
                        <GalleryList fontSize={"15px"} fontFamilyKo={conceptStyle.fontFamilyKo} fontFamilyEn={conceptStyle.fontFamilyEn} color={conceptStyle.titleColor} weddingData={weddingData} initCount={9} photoWidth={"110px"} photoHeight={"150px"} gap={"3px"}/>
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <AccountList fontSize={"13px"} titleFontSize={"14px"} conceptStyle={conceptStyle} groomAccountList={groomAccountList} brideAccountList={brideAccountList} account_explain={account_explain} />
                    </MotionWrapper>
                    <MotionWrapper initialY={70}>
                        <Message_Spring fontSize={"14px"} weddingData={weddingData} goToGuestBook={goToGuestBook} weddingDate={weddingData.weddingDate} 
                            conceptStyle={conceptStyle} 
                            btnBgColor={weddingData.btnBgColor} btnFontColor={weddingData.btnFontColor} titleColor={weddingData.titleColor}
                            />
                    </MotionWrapper>
                    {/* <Information conceptStyle={conceptStyle} informationList={informationList} /> */}
                    <MotionWrapper initialY={50}>
                        {/* <Interview_Spring interviewContent={interviewContent} weddingData={weddingData} conceptStyle={conceptStyle} /> */}
                        <Location_Spring fontSize={"13px"} titleFontSize={"15px"} weddingData={weddingData} conceptStyle={conceptStyle} lng={lng} lat={lat} placeTelNum={placeTelNum}/>
                    </MotionWrapper>
                    <Location_Desc fontSize={"13px"} titleFontSize={"15px"} forSubway={forSubway} forShuttleBus={forShuttleBus} forBus={forBus} forParking={forParking} conceptStyle={conceptStyle}/>
                    <Sharing conceptStyle={conceptStyle} title={openTitle} description={openDesc} thumbImg={openThumb}/>
                    <Footer />
                </>
            )}
        </div>
    );
    
};

export default KimYeonA;