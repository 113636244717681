import React, { useState, useEffect } from "react";
import axios from "axios";
import moreBtn from "../../css/moreBtn.png"
import foldBtn from "../../css/foldBtn.png"
import xImg from '../../css/x.png';
import "../../css/gallery_cards.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const GalleryItem = ({ weddingData, galleryImgTitle, initCount, photoWidth, photoHeight, gap, speed }) => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [galleryList, setGalleryList] = useState([]);
  const [visibleCount, setVisibleCount] = useState(initCount);
  const [initialVisibleCount, setInitialVisibleCount]  = useState(initCount);

  useEffect(() => {
    // 핀치 줌 방지 이벤트 등록
    const preventPinchZoom = (e) => {
      e.preventDefault();
    };
    document.addEventListener("gesturestart", preventPinchZoom);
  }, []);

  const fetchGallery = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/_api/v1/invitation_info/gallery/${weddingData.id}`
      );
      if (response.status === 200) {
        setGalleryList(response.data.data);
      }
    } catch (error) {
      console.error(
        "API 요청 실패:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const openSlide = (index) => {
    setIndex(index);
    setOpen(true);
    document.body.style.overflow = "hidden"; // 스크롤 막기
    disableZoom(); // 화면 확대 비활성화
  }

  const closeSlide = () => {
    setOpen(false);
    document.body.style.overflow = "auto"; // 스크롤 복원
  };

  const disableZoom = () => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
    metaTag.id = "disable-zoom";
    document.head.appendChild(metaTag);
  };

  useEffect(() => {
    if (weddingData && galleryList.length === 0) {
      fetchGallery();
    }
  }, [weddingData]); 

  const loadMoreImages = () => {
    setVisibleCount((prevCount) => {
      return prevCount + initCount;
    });
  };

  const resetImages = () => {
    setVisibleCount(initCount); 
    setInitialVisibleCount(initCount);
  };

  const handleSlideChange = (swiper) => {
    const newIndex = swiper.activeIndex; 
    setIndex(newIndex); 
  };

  return (
    <div className="gallery_card_container" style={{gap: gap}}>
      {galleryList.slice(0, visibleCount).map((image, idx) => (
        <img
          key={idx}
          className="card_photo"
          style={{width: photoWidth, height: photoHeight, maxWidth: "329px", cursor: "pointer"}}
          src={image}
          alt={`photo${idx + 1}`}
          onClick={() => openSlide(idx)}
        />
      ))}

      <div style={{ textAlign: "center", marginTop: "20px", width: "300px", height: "50px" }}>
      {visibleCount < galleryList.length && (
        <span
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={loadMoreImages}
        >
          <img style={{ width: "15px", height: "10px", cursor: "pointer" }} src={moreBtn} />
        </span>
    )}

    {visibleCount !== initialVisibleCount && visibleCount >= galleryList.length && (
      <span
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={resetImages}
      >
        <img style={{ width: "15px", height: "10px", cursor: "pointer"  }} src={foldBtn} />
      </span>
    )}

      </div>

      {open && (
        <div style={{width: "100vw", height: "100vh", position: "fixed", top: 0, left: 0,backgroundColor: "rgba(255, 255, 255, .9)", textAlign: "center", zIndex: "2000"}}>
          <img className='x_img' src={xImg} alt='Close' onClick={closeSlide} style={{position: "absolute",
              top: "6%",
              right: "20px",
              width: "20px",
              height: "20px",
              cursor: "pointer",
              zIndex: "2000"
              }} />

          <div style={{display: "flex", alignItems: "center", justifyContent: "center",  height: "100%", }}>
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={{ // 페이지네이션 활성화
                  dynamicBullets: true,
                }}
                mousewheel={true}
                keyboard={true}
                loop={true} // Enable looping
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className='mySwiper'
                speed={speed?speed:300}
                initialSlide={index}
                onSlideChange={handleSlideChange}
              >
                {galleryList.map((image, idx) => (
                  <SwiperSlide key={idx}> 
                    <img
                      src={image}
                      alt={`Gallery Image ${idx + 1}`}
                      style={{ width: "82%", maxWidth: "360px"}}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>


          </div>
        </div>
      )}
    </div>
  );
};


export default GalleryItem;